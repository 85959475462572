import { Nullable } from '../../types/index.d';
import { isNewbornProhibitedByLeg } from '../../utils/registrationUtils';

export default {
  scanButton: {
    frontSide: 'Nasnímať prednú stranu',
    backSide: 'Nasnímať zadnú stranu',
    success: 'Nasnímať znovu',
    error: 'Nasnímať znovu',
    status: {
      success: 'Automatické dopĺňanie <br/> prebehlo úspešne',
      error: 'Automatické dopĺňanie zlyhalo. <br/>Skúste to, prosím, znovu.',
    },
  },
  divider: {
    section: {
      personal: 'Osobné údaje',
      contact: 'Adresa trvalého bydliska',
      address: 'Adresa <strong>trvalého</strong> pobytu',
    },
  },
  modal: {
    cancel: {
      title: 'Naozaj chcete zrušiť prihlášku?',
      content: 'Prídete o všetky vypísané údaje, nebude možné sa k nim vrátiť.',
      submitBtn: 'Áno, zrušiť prihlášku',
      dismissBtn: 'Nie, späť na prihlášku',
    },
    device: {
      title: 'Dôležité',
      content:
        'Na vyplnenie prihlášky vám odporúčame mať <strong>aspoň jedno z týchto zariadení</strong>:',
      contentContract:
        'Na vyplnenie dohody vám odporúčame mať <strong>aspoň jedno z týchto zariadení</strong>:',
      devices: ['Dotykové pero', 'Zariadenie s dotykovou obrazovkou'],
      submitBtn: 'Ok, beriem na vedomie',
    },
    ocrConfirm: {
      title: 'Naozaj chcete nahradiť vami zadané údaje?',
      content: {
        adult:
          'V prihláške sú už vami vyplnené údaje. Je možnosť ich nahradiť údajmi získanými z preukazu totožnosti <strong>dospelej osoby</strong>. Zoznam získaných údajov je nižšie:',
        child:
          'V prihláške sú už vami vyplnené údaje. Je možnosť ich nahradiť údajmi získanými z preukazu totožnosti <strong>dieťaťa</strong>. Zoznam získaných údajov je nižšie:',
      },
      submitBtn: 'Nahradiť',
      dismissBtn: 'Zrušiť',
    },
  },
  step0: {
    header: 'Občiansky preukaz',
    summary: {
      skip: 'Skenovanie občianského preukazu ste nevyužili.',
      failure:
        'Skenovanie údajov zlyhalo ale nemusíte sa báť, fotku preukazu totožnosti sme si uložili a budete môcť plnohodnotne podať prihlášku.',
      success: 'Občiansky preukaz je naskenovaný.',
      inProgress: 'Údaje z občianského preukazu sa práve získavajú.',
      copied: 'Údaje boli prebraté z predošlej prihlášky.',
      photoTaken: 'Občiansky preukaz ste úspešne pridali k prihláške.',
    },
    content: {
      info:
        'Na naskenovanie údajov potrebujete <strong>občiansky preukaz</strong>. Údaje z preukazu zoskenujeme a automaticky nahráme do prihlášky. Vy ich iba skontrolujete.',
      infoChild:
        'Ak chcete vypĺňanie prihlášky urýchliť, <strong>môžete nasnímať občiansky preukaz</strong> dieťaťa. Údaje automaticky vpíšeme do prihlášky.',
      infoChildParent:
        'Ak chcete vypĺňanie prihlášky urýchliť, môžete nahrať fotografiu občianskeho preukazu rodiča. Údaje automaticky vpíšeme do prihlášky.',
      ocr: {
        capture: 'Odfotiť občiansky preukaz',
        skip: 'Pokračovať bez skenovania',
        failure:
          'Získanie údajov z občianskeho preukazu momentálne nie je dostupné. Prosím, vyplňte údaje manuálne.',
        retry: 'Nasnímať znovu',
        success: 'Získanie údajov z občianskeho preukazu prebehlo úspešne.',
        photoTaken: 'Občiansky preukaz ste odfotili úspešne.',
        inProgress:
          'Proces získavania údajov z preukazu práve prebieha na pozadí.<br/>Bez obmedzení možete pokračovať vo vypĺňaní prihlášky.',
        inProgressContract:
          '"Prebieha proces získavania údajov z preukazu. Čakajte prosím',
        copiedData:
          'Údaje zákonného zastupcu boli prebraté z predošlej prihlášky.',
        progress: {
          empty: 'Nepodarilo sa získať žiadne dáta.',
          done: 'Pokračujte, dáta sa úspešne načítali',
          inProgress: {
            adult: 'Údaje z dokladu sa práve získavajú',
            child: 'Údaje z dokladu dieťaťa sa práve získavajú.',
          },
          failure: 'Nepodarilo sa získať dáta.',
        },
      },
    },
  },
  step1: {
    header: 'Poistenec',
    summary: {
      weInsure: 'Poisťujeme',
      cause: 'pretože',
      person: {
        adult: 'dospelého',
        child: 'dieťa',
        newBorn: 'novorodenca',
      },
      purpose: {
        abroad: 'je späť zo zahraničia',
        insurance: 'mení zdravotnú poisťovňu',
        foreigner: 'je cudzinec',
      },
      final: (person: string, country: string, company: string) =>
        `Poisťujeme <strong>${person} občana ${country}</strong>,<br/>je poistencom v poisťovni <strong>${company}</strong>.`,
      finalNB: 'Poisťujeme <strong>novorodenca</strong>',
    },
    content: {
      person: {
        adult: 'Dospelý',
        child: `${
          isNewbornProhibitedByLeg() ? 'Dieťa alebo novorodenec' : 'Dieťa'
        }`,
        newBorn: 'Novorodenec',
      },
      // purposes: {
      //   cause: 'Pretože:',
      //   purpose1: 'mení zdravotnú poisťovňu',
      //   purpose2: 'som sa vrátil zo zahraničia',
      //   purpose3: 'som cudzinec',
      // },
      insuranceCompaniesLabel: (isChild: boolean) =>
        isChild
          ? 'V ktorej poisťovni je dieťa poistené?'
          : 'V ktorej poisťovni ste poistený?',
      insuranceCompanies: {
        union: 'Union, a. s.',
        vszp: 'Všeobecná zdravotná poisťovňa, a. s.',
        dovera: 'Dôvera',
      },
      verificationButton: 'Overenie poistného vzťahu poistenca',
      statnaPrislusnost: 'Štátna príslušnosť',
      newBornInfo:
        'Na vyplnenie elektrickej prihlášky <strong>pre novorodenca potrebujete tieto dokumenty:</strong>',
      newBornCertificate: 'Rodný list novorodenca',
      newbornAsChild: `<strong>Upozornenie:</strong> Pri poistení novorodenca je súčasná poisťovňa novorodenca rovnaká ako poisťovňa matky. Máte možnosť urobiť zmenu zdravotnej poisťovne.`,
    },
  },
  step2: {
    header: 'Údaje dospelého',
    headerChild: 'Údaje rodiča',
    summary: {
      headlines: {
        permanent: 'Trvalý pobyt',
        temporary: 'Prechodná adresa',
        postal: 'Korešpondenčná adresa',
        child: 'Dieťa',
        newBorn: 'Novorodenec',
        parent: 'Rodič',
        additional: 'Doplňujúce info',
        parentContact: 'Kontakt rodiča',
        mother: 'Matka',
        card: 'Sprievodný list s preukazom doručiť',
      },
      final: (insuree: string, insuranceCompany: string) =>
        `<strong>${insuree}</strong> je poistencom<br/> v poisťovni <strong>${insuranceCompany}</strong>.<br>Prepoistenie do poisťovne Dôvera je možné.`,
    },
    content: {
      description:
        'Ak chcete vypĺňanie prihlášky urýchliť, môžete nahrať fotografiu svojho občianskeho preukazu. Vaše údaje automaticky vpíšeme do prihlášky.',
      subHeading_front: 'Občiansky preukaz – predná strana',
      subHeading_rear: 'Občiansky preukaz – zadná strana',
      subHeadingChild_front: 'Občiansky preukaz dieťaťa – predná strana',
      subHeadingChild_rear: 'Občiansky preukaz dieťaťa – zadná strana',
      subHeadingZZ_front:
        'Občiansky preukaz zákonného zástupcu – predná strana',
      subHeadingZZ_rear: 'Občiansky preukaz zákonného zástupcu – zadná strana',
      form: {
        birthNumber: 'Rodné číslo',
        birthNumberForeigner: 'Rodné číslo / BIČ',
        firstName: 'Meno',
        lastName: 'Priezvisko',
        dateOfBirth: 'Dátum narodenia',
        idNumber: 'Číslo občianskeho preukazu',
        idNumberForeigner: 'Číslo dokladu totožnosti',
        idNumberChild: 'Číslo občianskeho preukazu (ak ho má vydaný)',
        foreigner: 'Pobyt na Slovensku',
        stayFrom: 'Od',
        stayTo: 'Do',
        sex: 'Pohlavie',
        ulicaNazov: 'Ulica',
        ulicaNazovHelp: 'Uveďte názov ulice, ktorý máte na občianskom preukaze',
        cislo: 'Popisné číslo',
        obecNazov: 'Mesto',
        statNazov: 'Štát',
        psc: 'PSČ',
        birthName: 'Rodné priezvisko',
        academicDegreeBeforeName: 'Titul pred menom',
        academicDegreeAfterName: 'Titul za menom',
        nationality: 'Štátna príslušnosť',
      },
      bic:
        'BIČ – Ak nie je známe rodné číslo, uveďte <strong>10-miestne bezvýznamové identifikačné číslo</strong> (v tvare XX<strong>7</strong>XXXXXXX), ktoré poistencovi pridelil ÚDZP. Údaj môže byť uvedený v Európskom preukaze zdravotného poistenia v položke „Osobné identifikačné číslo.”',
      ocrNotif:
        'Údaje podfarbené modrou boli doplnené automaticky. Uistite sa, či skenovanie prebehlo správne.',
      foreignerStayNotif:
        'Informáciu nájdete na svojom <strong>Povolení na pobyt</strong>.',
      sexTypes: {
        female: 'Žena',
        male: 'Muž',
      },
    },
  },
  step3: {
    header: 'Aktuálne poistenie',
    headerByRole: {
      adult: 'Aktuálne poistenie',
      child: 'Údaje dieťaťa',
      newBorn: 'Údaje novorodenca',
    },
    summary: {
      headlines: {
        permanent: 'Trvalý pobyt',
        temporary: 'Prechodná adresa',
        postal: 'Korešpondenčná adresa',
        child: 'Dieťa',
        newBorn: 'Novorodenec',
        parent: 'Rodič',
        additional: 'Doplňujúce info',
        parentContact: 'Kontakt rodiča',
        mother: 'Matka',
        card: 'Preukaz poistenca so sprievodným listom',
      },
      employerPayer: (employerCompany: string) =>
        `Za poistenca platí <strong>${employerCompany}</strong>.`,
      selfPayer: 'Poistenec je samoplatca zdravotného poistenia.',
      selfEmployedPayer: (selfEmployer: string) =>
        `Poistenec je <strong>${selfEmployer}</strong> a platí si poistenie sám`,
      statePayer: (payer: string, insureeStatus: string) =>
        `Za poistenca platí <strong>${payer}</strong>, <br> pretože je <strong>${insureeStatus}</strong>.`,
    },
    content: {
      subHeading: 'Občiansky preukaz – zadná strana',
      subHeadingZZ: 'Občiansky preukaz zákonného zástupcu – zadná strana',
      sameAddress: {
        childHeading: 'Adresa dieťaťa',
        childAddress: 'Kontaktná adresa dieťaťa je rovnaká',
        otherAddress: (address: string) =>
          `Mám inú <strong>${address} adresu</strong>`,
        temporaryAddress: 'prechodnú',
        postalAddress: 'korešpondenčnú',
        otherAddressNotif: (address: string) =>
          `Korešpondenciu vám budeme zasielať <strong>na ${address} adresu</strong>.`,
      },
      payerLabel: 'Kto za vás platí poistenie?',
      payerTypes: {
        employer: 'Zamestnávateľ',
        selfEmployed: 'SZČO',
        selfPayer: 'Samoplatca',
        state: 'Štát',
      },
      form: {
        employerResidence: 'Sídlo zamestnávateľa',
        employerName: 'Názov zamestnávateľa',
        employerICO: 'IČO',
        employerStreet: 'Ulica',
        employerHouseNumber: 'Číslo',
        employerCity: 'Mesto',
        employerPostCode: 'PSČ',
        selfEmployedOptions: {
          zivnostnik: 'Živnostník',
          samHospRoln: 'Samotnatne hospodáriaci roľník',
          sportovec: 'Športovec',
          umelec: 'Umelec',
          lekar: 'Lekár',
          pravnik: 'Právnik',
          obchodnyZastupca: 'Obchodný zástupca',
          financnyPoradca: 'Finančný poradca',
          szcoIne: 'iné',
        },
        selfEmployedZivnostnik: {
          title: 'Údaje živnostníka',
          name: {
            label: 'Názov',
            placeholder: '',
          },
          number: {
            label: 'IČO',
            placeholder: '',
          },
        },
        stateOptions: {
          student: 'študent',
          homeWithChild: 'doma s dieťaťom',
          retiree: 'dôchodca',
          unemployed: 'nezamestnaný',
          helping: 'poberateľ dávky v hmotnej núdzi',
          other: 'iné',
        },
        selectLabel: 'Pretože som',
        retiree: {
          label: 'Aký typ dôchodku poberáte?',
          options: {
            dochodokStarobny: 'Starobný',
            dochodokPredcasny: 'Predčasný',
            dochodokInvalidny: 'Invalidný',
            dochodokVysluhovy: 'Výsluhový',
            dochodokVysluhovyInvalidny: 'Výsluhový invalidný',
            dochodokZCudziny: 'Z cudziny',
          },
        },
        student: {
          label: 'O akú formu štúdia ide?',
          options: {
            studentDenny: 'denné štúdium',
            studentDoktorant: 'doktorandské štúdium',
            studentExterny: 'štúdium v zahraničí',
            studentZahranicny: 'externé štúdium',
          },
        },
        homeWithChild: {
          label: 'O akú formu starostlivosti ide?',
          options: {
            domaDietaMaterska: 'materská dovolenka',
            domaDietaRodicovska: 'rodičovská dovolenka',
            domaDieta6rokov: 'starostlivosť o dieťa do 6 rokov',
          },
        },
        stateOther: {
          label: 'Štát za vás platí, pretože',
          options: {
            B21: 'sa starám o nevládnu alebo viac ako 80 ročnú osobu',
            B23: 'som vo väzení',
            B24: 'na dôchodku bez nároku na dôchodok',
            B25: 'som azylant',
            B26: 'vykonávam činnosť pre charitatívne spoločenstvo',
            B27: 'som v zariadení sociálnych služieb celoročne',
            B28: 'invalid bez nároku na invalidný dôchodok',
            B29: 'poberám peňažný príspevok za opatrovanie',
            B30: 'som manželka alebo manžel štátneho zamestnanca v cudzine',
            B31: 'som osobný asistent zdravotne postihnutého občana',
            B32: 'som cudzinec zaistený na území Slovenska',
            B38: 'mám náhradu príjmu služobného platu policajta alebo vojaka',
            B39:
              'sa starám o blízku osobu, nemám nárok na dôchodok a nezarábam',
          },
        },
      },
    },
  },
  step4: {
    header: 'Doplňujúce informácie',
    summary: {
      delivery: (address: string) =>
        `Doručenie <strong>${address.toLowerCase()}</strong>`,
    },
    content: {
      form: {
        email: 'E-mailová adresa',
        telephoneNumber: 'Telefónne číslo',
        notification:
          'Možné je zadať iba číslo slovenského mobilného operátora. Hoci tieto údaje nie sú povinné, ak uvediete e-mail, žiadosť vieme vybaviť <strong>rýchlejšie</strong>.',
        relationship: {
          label: 'Vzťah k dieťaťu',
          mother: 'Matka',
          father: 'Otec',
          other: 'Iné',
          motherInsuranceCompany: 'Poisťovňa matky',
          motherBirthNumber: 'Rodné číslo matky',
          notification:
            '<strong>Prihlášku pre iný typ zákonného zástupcu nie je možné dokončiť.</strong><br/>Prosím, kontaktujte svojho account manažéra. Informujte sa o typoch dokumentov, ktoré sú potrebné doložiť k prihláške alebo zvoľte inú možnosť.',
        },
        deliveryAddress: {
          label: 'Kam pošleme preukaz poistenca so sprievodným listom?',
          label_ZZ:
            'Kam pošleme rodičovi preukaz poistenca so sprievodným listom?',
          permanentAddress: {
            child: 'Na adresu trvalého pobytu dieťaťa',
            adult: 'Na adresu trvalého pobytu',
          },
          temporaryAddress: {
            child: 'Na adresu prechodného pobytu dieťaťa',
            adult: 'Na adresu prechodného pobytu',
          },
          postalAddress: {
            child: 'Na korešpondenčnú adresu dieťaťa',
            adult: 'Na korešpondenčnú adresu',
          },
          branchOffice: 'Na pobočku',
          legalAddress: 'Na adresu zákonného zástupcu',
          deliveryTitle: 'Preukaz poistenca so sprievodným listom',
          summary: {
            branchOffice: {
              parent: 'pošleme na pobočku',
              child: 'pošleme na pobočku',
            },
            permanentAddress: {
              parent: 'pošleme na adresu trvalého pobytu',
              child: 'pošleme na adresu trvalého pobytu dieťaťa',
            },
            temporaryAddress: {
              parent: 'pošleme na adresu prechodného pobytu',
              child: 'pošleme na adresu prechodného pobytu dieťaťa',
            },
            postalAddress: {
              parent: 'pošleme na korešpondenčnú adresu',
              child: 'pošleme na korešpondenčnú adresu dieťaťa',
            },
            legalAddress: {
              parent: 'pošleme na adresu pobytu',
              child: 'pošleme na adresu pobytu zákonného zástupcu',
            },
          },
        },
        addressForm: {
          street: 'Ulica',
          houseNumber: 'Číslo domu',
          companyName: 'Názov firmy',
          city: 'Mesto',
          postcode: 'PSČ',
        },
        branchOfficeAddressForm: {
          city: 'Mesto',
          branch: 'Pobočka',
        },
      },
      signIn: {
        heading:
          'Ak ste zamestnancom Dôvery, použite vaše údaje alebo zamestnanecké číslo',
        employeeNumberSection: {
          tabName: 'Zadajte zamestnanecké číslo',
          heading: 'Referencia',
          employeeNumberLabel: 'Číslo zamestnanca',
          employeeNumberPlaceholder: '10-miestne číslo',
          confirm: 'Potvrdiť číslo',
          confirmSuccess: 'Zadanie čísla zamestnanca prebehlo úspešne!',
        },
        loginForm: {
          tabName: 'Prihláste sa',
          heading: 'Prihlásenie',
          usernameLabel: 'Prihlasovacie meno',
          passwordLabel: 'Heslo',
          confirm: 'Prihlásiť sa',
          restorePassword: 'Nepamätám si údaje',
          confirmSuccess: 'Prihlásenie prebehlo úspešne!',
        },
        notEmployee: 'Nie som zamestnancom Dôvery',
      },
      consentWeak:
        'Začiarknutím políčka vyjadrujem súhlas s používaním mojich kontaktných údajov (mobilného čísla a e-mailovej adresy) na účely priameho marketingu vlastných podobných produktov alebo služieb spoločnosti DÔVERA zdravotná poisťovňa, a. s.',
    },
  },
  step5OP: {
    header: 'Preukaz totožnosti',
    summary: {
      headlines: {
        identity: 'Doklad totožnosti',
        front: 'Občiansky preukaz - predná strana',
        back: 'Občiansky preukaz - zadná strana',
        attachment: 'Príloha',
      },
    },
    content: {},
  },
  step5: {
    header: 'Podpis',
    summary: '',
    content: {
      digitalSignature: 'Podpísať digitálne',
      print: 'Vytlačiť',
      courier: 'Doručiť kuriérom',
      sms: 'Poslať SMS',
    },
    consent: {
      title: 'Súhlas so spracovaním osobných údajov',
      content: `<p>Svojím podpisom udeľujem súhlas spoločnosti DÔVERA zdravotná poisťovňa, a.s. (ďalej len „zdravotná poisťovňa“) dobrovoľný súhlas so spracovaním mojich osobných údajov uvedených v prihláške v rozsahu titul pred menom, titul za menom, meno, priezvisko, dátum narodenia, adresa trvalého pobytu alebo adresa prechodného pobytu alebo kontaktná adresa (ak sa adresa prechodného pobytu alebo kontaktná adresa líšia od adresy trvalého pobytu), telefón, e-mail za účelom zasielania informácií o produktoch a službách, ktoré zdravotná poisťovńa dohodla pre poistencov u svojich zmluvných partnerov, vrátane zasielania prostredníctvom elektronickej pošty, sms/mms správ, automatických volacích a komunikačných systémov bez ľudského zásahu a sociálnych sietí, ktorých som používateľom.</p>
              <p>Súhlas udeľujem na dobu určitú, a to na dobu trvania poistného vzťahu v zdravotnej poisťovni.</p>
              <p>Beriem na vedomie, že súhlas môžem kedykoľvek odvolať zaslaním písomného odvolania súhlasu na adresu sídla zdravotnej poisťovne, odovzdaním písomného odvolania na ktorejkoľvek pobočke zdravotnej poisťovne, elektronicky na osobneudaje@dovera alebo prostredníctvom Elektronickej pobočky zdravotnej poisťovne.</p>
              <p>Beriem na vedomie, že informácie ohľadom spracúvania osobných údajov, predovšetkým kontaktné údaje na zdravotnú poisťovňu a zodpovednú ososbu, ako aj moje práva, ako dotknutej osoby sú prístupné na <a href="https://www.dovera.sk" target="_blank">www.dovera.sk</a>.</p>`,
      submitBtn: 'Súhlasím',
      dismissBtn: 'Nesúhlasím',
    },
    sms: {
      title: 'Poslať prihlášku na podpis cez SMS',
      content: (validThru: any = 48) =>
        `<p>Zadajte telefónne číslo, na ktoré bude odoslaná SMS s odkazom. Odkaz na digitálny podpis prihlášky je platný ${validThru} hodín po odoslaní SMS.</p>`,
      submitBtn: 'Odoslať',
      dismissBtn: 'Zmeniť spôsob podpisu',
    },
    personalisedSMS: {
      title: 'Poslať personalizovaný link na prihlášku cez SMS',
      content: `Zadajte telefónne číslo, na ktoré bude odoslaná SMS s personalizovaným linkom. V prípade, že klient urobí prihlášku cez uvedený link, do online prihlášky sa zapíše automaticky váš zamestnanecký kód.`,
      submitBtn: 'Odoslať',
    },
  },
  terminationStep: {
    content: {
      digitalSignature: {
        heading: 'Prihláška bola úspešne odoslaná!',
        list: (email: Nullable<string>, year: number, isAdult: boolean) => [
          email &&
            `Potvrdenie o evidovaní prihlášky pošleme <strong>e-mailom</strong> na <strong>${email}</strong>`,
          'Spracovanie trvá maximálne <strong>5 dní</strong>',
          `Naším poistencom sa stanete <strong>1. januára ${year + 1}</strong>`,
          `<strong>Preukaz poistenca so sprievodným listom</strong> očakávajte <strong>v decembri ${year}</strong>`,
          isAdult
            ? `Zmenu zdravotnej poisťovne je potrebné nahlásiť zamestnávateľovi do <strong>8. januára ${year +
                1}</strong>`
            : '',
          isAdult
            ? `Živnostníci si musia zmeniť svoj trvalý platobný príkaz do <strong>8. februára ${year +
                1}</strong>`
            : '',
        ],
        listNewBorn: (
          email: Nullable<string>,
          name: string,
          isMumInDZP: boolean,
        ) => [
          email &&
            `Prihlášku pošleme <strong>e-mailom</strong> na <strong>${email}</strong>`,
          'Spracovanie trvá maximálne <strong>3 dní</strong>',
          'Následne vám doručíme <strong>kupón k BabyBoxu</strong> v hodnote <strong>50€</strong> na váš <strong>e-mail</strong>',
          `${name} sa stáva naším <strong>poistencom dňom ${
            isMumInDZP ? 'narodenia' : 'podania prihlášky'
          }</strong>`,
          `<strong>Preukaz poistenca</strong> so sprievodným listom očakávajte na vami zvolenej adrese <strong>do 7 dní</strong>`,
        ],
        listNavrat: (
          email: Nullable<string>,
          name: string,
          isAdult: boolean,
        ) => [
          'Spracovanie trvá maximálne <strong>5 dní</strong>',
          email &&
            `Potvrdenie o evidovaní prihlášky pošleme <strong>e-mailom</strong> na ${email}`,
          isAdult
            ? `Naším poistencom sa stávate <strong>dňom potvrdenia prihlášky</strong>`
            : `${name} sa stáva <strong>poistencom dňom potvrdenia prihlášky</strong>`,
          `Preukaz poistenca so sprievodným listom očakávajte na vami zvolenej adrese <strong>do 7 dní</strong>`,
        ],
      },
      sms: {
        heading: 'Prihláška je pripravená na podpis.',
        list: (validThru: any = 48) => [
          `SMS na podpis prihlášky bola úspešne odoslaná. Odkaz je možné použiť <strong>${validThru} hodín.</strong>`,
        ],
      },
      courier: {
        heading: 'Prihlášku podpíšete s kuriérom',
        data: (
          address: Nullable<string>,
          phone: Nullable<string>,
          deliveryDays: number = 5,
        ) =>
          `Do ${deliveryDays} pracovných dní vás navštívi kuriér na adrese <strong>${address}.</strong> ${
            phone
              ? `Bude vás kontaktovať na čísle <strong>${phone}</strong>.`
              : ''
          }`,
        list: (email: string, year: number, isAdult: boolean) => [
          email &&
            `Potvrdenie o evidovaní prihlášky pošleme <strong>e-mailom</strong> na <strong>${email}</strong>`,
          `Spracovanie trvá maximálne <strong>5 dní</strong>`,
          `Naším poistencom sa stanete <strong>1. januára ${year + 1}</strong>`,
          `<strong>Preukaz poistenca so sprievodným listom </strong> očakávajte <strong>v decembri ${year}</strong>`,
          isAdult
            ? `Zmenu zdravotnej poisťovne je potrebné nahlásiť zamestnávateľovi do <strong>8. januára ${year +
                1}</strong>`
            : '',
          isAdult
            ? `Živnostníci si musia zmeniť svoj trvalý platobný príkaz do <strong>8. februára ${year +
                1}</strong>`
            : '',
        ],
        listNewBorn: (
          email: Nullable<string>,
          name: string,
          isMumInDZP: boolean,
        ) => [
          email &&
            `Potvrdenie o evidovaní prihlášky pošleme <strong>e-mailom</strong> na <strong>${email}</strong>`,
          'Spracovanie trvá maximálne <strong>5 dní</strong>',
          `${name} sa stáva naším <strong>poistencom dňom ${
            isMumInDZP ? 'narodenia' : 'podania prihlášky'
          }</strong>`,
          `<strong>Preukaz poistenca so sprievodným listom</strong> očakávajte <strong>do 7 dní</strong>`,
        ],
      },
      download: {
        heading: 'PDF pre tlač prihlášky bolo vygenerované!',
        data:
          'Vygenerovanú prihlášku si stiahnite do svojho zariadenia a následne vytlačte.',
        btn: 'Stiahnuť prihlášku',
      },
      print: {
        heading: 'Prihláška bola úspešne vytlačená!',
        list: (year: number) => [
          `Vytlačte si prihlášku a podpíšte ju`,
          `Prihlášku doručte 
          <ol type="a">
            <li>osobne na ktorúkoľvek z našich pobočiek</li>
            <li>poštou alebo kuriérom na adresu: <br/><br/><p><strong>Dôvera zdravotná poisťovňa, a.s.,<br/>Cintorínska 5, 949 01 Nitra</strong></p></li>
          </ol>
          `,
          `Poistencom sa stanete <Strong>1. januára ${year + 1}</strong>`,
        ],
        listNewBorn: (
          email: Nullable<string>,
          name: string,
          isMumInDZP: boolean,
        ) => [
          email &&
            `Prihlášku pošleme <strong>e-mailom</strong> na <strong>${email}</strong>`,
          'Spracovanie trvá maximálne <strong>3 dní</strong>',
          'Následne vám doručíme <strong>kupón k BabyBoxu</strong> v hodnote <strong>50€</strong> na váš <strong>e-mail</strong>',
          `${name} sa stáva naším <strong>poistencom dňom ${
            isMumInDZP ? 'narodenia' : 'podania prihlášky'
          }</strong>`,
          `<strong>Preukaz poistenca</strong> so sprievodným listom očakávajte na vami zvolenej adrese <strong>do 7 dní</strong>`,
        ],
      },
      errorDigitalSignature: {
        heading: 'Chyba: Prihlášku nebolo možné vytvoriť na podpis',
        data: [
          'Prihláška sa uložila ale nastala chyba pri príprave prihlášky na elektronický podpis.',
          'Nebojte sa, vaše údaje sa zachovali. Skúste podpísať prihlášku ešte raz.',
        ],
        printApplication: 'Vytlačiť vyplnenú prihlášku',
        sendApplication: 'Pokúsiť sa znova podpísať prihlášku',
        cancelApplication: 'Zrušiť prihlášku',
      },
      error: {
        heading: 'Chyba: Prihlášku sa nepodarilo uložiť',
        data: [
          'Došlo k problému s pripojením na server.',
          'Nebojte sa, vaše údaje sa zachovali.',
        ],
        printApplication: 'Vytlačiť vyplnenú prihlášku',
        sendApplication: 'Pripojiť sa znovu',
        cancelApplication: 'Zrušiť prihlášku',
      },
      nextRegistration: 'Vyplniť ďalšiu prihlášku',
      fastReqistration: 'Rýchle poistenie dieťaťa',
      backToBenefits: 'Späť na benefity',
      benefitsForInsurees: 'Benefity pre poistencov',
    },
  },
  courier: {
    title: 'Doručovacie údaje',
    form: {
      firstName: 'Meno',
      lastName: 'Priezvisko',
      companyName: 'Názov firmy',
      street: 'Ulica',
      number: 'Číslo domu',
      town: 'Mesto',
      zip: 'PSČ',
      phone: 'Telefónne číslo',
    },
    alert: 'Prosím, zadajte adresu na Slovensku',
  },
  shouldReviewData:
    'Pred podpisom prihlášky skontrolujte, či sú zadané údaje správne.',
  signatureUnavailable:
    'Podpísanie prihlášky digitálnym podpisom je momentálne nedostupné. Využite, prosím, iný spôsob podpisu.',
  indentityCardMissing:
    'Podpísanie prihlášky digitálnym podpisom nie je možné bez nahratého dokladu totožnosti.',
  attachmentsMissing: 'Potrebujete priložiť ďalšie dokumenty?',
  signatureByMakler: phoneNumber =>
    `Pred podpisom skontrolujte údaje v prihláške. Pokiaľ ste našli chybu, prosím, kontaktujte agenta na tel. ${phoneNumber}, ktorý vypĺňal údaje, aby prihlášku opravil.`,
  signatureByInsuree:
    'Pred podpisom skontrolujte údaje v prihláške. Pokiaľ ste našli chybu, prosím, opätovne vyplňte prihlášku ',
};

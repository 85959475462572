import * as actionSets from './actionSets';
import { createUrl, createBodyLog, callApiRequest } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import { ConfirmContractAdminOutput } from '../types/extranet.d';
import { TerminateContractDownloadFromList } from '../types/contract';

const controller = 'Admin';

const contractsManagement = {
  getContracts: contractsFilter => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetContracts`),
      body: createBodyLog(contractsFilter),
      method: 'POST',
      actions: actionSets.GET_CONTRACTS_MANAGEMENT_LIST,
      authenticated: true,
    },
  }),
  getContractDetailData: token => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetContractDetailData`),
      body: createBodyLog({ token }),
      method: 'POST',
      actions: actionSets.GET_CONTRACTS_MANAGEMENT_DETAIL,
      authenticated: true,
    },
  }),
  getContractDetailDocument: contractToken => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetContractDocument`),
      body: createBodyLog({ contractToken }),
      method: 'POST',
      actions: actionSets.GET_CONTRACTS_MANAGEMENT_DETAIL_DOCUMENTS,
      authenticated: true,
    },
  }),
  getContractDetailButton: contractToken => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetContractDetailButton`),
      body: createBodyLog({ contractToken }),
      method: 'POST',
      actions: actionSets.GET_CONTRACTS_MANAGEMENT_DETAIL_BUTTONS,
      authenticated: true,
    },
  }),
  updateRetirement: token => ({
    [API_CALL]: {
      url: createUrl(`${controller}/UpdateContractRetirement`),
      body: createBodyLog(token),
      method: 'POST',
      actions: actionSets.UPDATE_RETIREMENT,
      authenticated: true,
    },
  }),
  updateSuperiors: superiors => ({
    [API_CALL]: {
      url: createUrl(`${controller}/UpdateContractSuperior`),
      body: createBodyLog(superiors),
      method: 'POST',
      actions: actionSets.UPDATE_SUPERIORS,
      authenticated: true,
    },
  }),
  cancelContract: (contractToken, email = '', stornoReason = '') => ({
    [API_CALL]: {
      url: createUrl(`${controller}/StornoContract`),
      body: createBodyLog({ contractToken, email, stornoReason }),
      method: 'POST',
      actions: actionSets.CANCEL_CONTRACT,
      authenticated: true,
    },
  }),
  deleteContractDocument: (contractToken, documentToken, documentId) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/DeleteContractDocument`),
      body: createBodyLog({ contractToken, documentToken, documentId }),
      method: 'POST',
      actions: actionSets.DELETE_CONTRACT_DOCUMENT,
      authenticated: true,
      meta: { documentToken },
    },
  }),
  signContract: (contractToken, startDateContract) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SignContract`),
      body: createBodyLog({ contractToken, startDateContract }),
      method: 'POST',
      actions: actionSets.SIGN_CONTRACT,
      authenticated: true,
    },
  }),
  confirmSignedContract: (
    contractToken: string,
    bearerToken: string,
  ): Promise<ConfirmContractAdminOutput> =>
    callApiRequest(
      `${controller}/ConfirmSignedContract`,
      {
        contractToken,
      },
      {
        Authorization: `Bearer ${bearerToken}`,
      },
    )
      .then((response: any) => response)
      .catch(error => {
        console.log(error);
        return { documentBioSignKey: null, success: false };
      }),
  cancelSignedContract: contractToken => ({
    [API_CALL]: {
      url: createUrl(`${controller}/CancelSignedContract`),
      body: createBodyLog(contractToken),
      method: 'POST',
      actions: actionSets.EMPTY_ACTION,
      authenticated: true,
    },
  }),
  downloadContractDocument: (contractToken, documentToken, documentId) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/DownloadContractDocument`),
      body: createBodyLog({ contractToken, documentToken, documentId }),
      method: 'POST',
      actions: actionSets.DOWNLOAD_CONTRACT_DOCUMENT,
      authenticated: true,
      meta: { documentToken },
    },
  }),
  uploadContractDocument: (contractToken, contractDocument) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/UploadContractDocument`),
      body: createBodyLog({ contractToken, ...contractDocument }),
      method: 'POST',
      actions: actionSets.UPLOAD_CONTRACT_DOCUMENT,
      authenticated: true,
    },
  }),
  sendContractToTXS: contractToken => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SendContractToTXS`),
      body: createBodyLog({ contractToken }),
      method: 'POST',
      actions: actionSets.SEND_CONTRACT_TO_TXS,
      authenticated: true,
    },
  }),
  getListTerminatedContract: listData => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetListTerminatedContract`),
      body: createBodyLog(listData),
      method: 'POST',
      actions: actionSets.GET_LIST_TERMINATED_CONTRACT,
      authenticated: true,
    },
  }),
  terminateContractDocumentAndDownloadFromList: (
    data: TerminateContractDownloadFromList,
  ) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/TerminateContract`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT,
      authenticated: true,
    },
  }),
};

export default contractsManagement;

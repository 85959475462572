import { RegistrationReducer } from '../types/registration.d';
import { WizardReducer } from '../types/wizard.d';
import { ContractWizardReducer } from '../types/contractWizard.d';
import { ContractReducer } from '../types/contract.d';
import { MessengerReducer } from '../types/messenger.d';
import { LoginControlReducer } from '../types/loginControl.d';
import {
  VersionReducer,
  ValidationReducer,
  LogReducer,
  AuthReducer,
  OcrReducer,
  ExtranetReducer,
  CMSReducer,
  ParametersReducer,
  BenefitsReducer,
  CodeListsReducer,
} from '../types/reducers.d';
import { combineReducers } from 'redux';

import authReducer from './auth';
import cmsReducer from './cms';
import verziaReducer from './version';
import wizardReducer from './wizard';
import contractWizardReducer from './contractWizard';
import registrationReducer from './registration';
import validationReducer from './validation';
import logReducer from './log';
import metricReducer from './metric';
import ocrReducer from './ocr';
import extranetReducer from './extranet';
import contractReducer from './contract';
import messengerReducer from './messenger';
import parametersReducer from './parameters';
import loginControlReducer from './loginControl';
import benefitsReducer from './benefits';
import codeLists from './codeLists';

export interface AppReducers {
  auth: AuthReducer;
  cms: CMSReducer;
  version: VersionReducer;
  wizard: WizardReducer;
  contractWizard: ContractWizardReducer;
  registration: RegistrationReducer;
  validation: ValidationReducer;
  log: LogReducer;
  ocr: OcrReducer;
  metric: {};
  extranet: ExtranetReducer;
  contract: ContractReducer;
  messenger: MessengerReducer;
  appControl: ParametersReducer;
  loginControl: LoginControlReducer;
  benefits: BenefitsReducer;
  codeLists: CodeListsReducer;
}

export default combineReducers<AppReducers>({
  auth: authReducer,
  cms: cmsReducer,
  version: verziaReducer,
  wizard: wizardReducer,
  contractWizard: contractWizardReducer,
  registration: registrationReducer,
  validation: validationReducer,
  log: logReducer,
  metric: metricReducer,
  ocr: ocrReducer,
  extranet: extranetReducer,
  contract: contractReducer,
  messenger: messengerReducer,
  appControl: parametersReducer,
  loginControl: loginControlReducer,
  benefits: benefitsReducer,
  codeLists,
});

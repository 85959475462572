import * as actionSets from './actionSets';
import { createUrl, createProcessLog } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import { ProcessLogRequestType } from '../types/index.d';

const APIcontroller = 'Log';
const log = {
  getNewThread: () => ({
    [API_CALL]: {
      url: createUrl(`${APIcontroller}/GetThread`),
      method: 'GET',
      actions: actionSets.GET_NEW_THREAD,
      authenticated: false,
    },
  }),
  setProcessLog: ({ controller, method, section }: ProcessLogRequestType) => ({
    [API_CALL]: {
      url: createUrl(`${APIcontroller}/SetProcessLog`),
      body: createProcessLog({ controller, method, section }),
      method: 'POST',
      actions: actionSets.SET_PROCESS_LOG,
      authenticated: false,
    },
  }),
  fetchThread: (): Promise<{ threadID: string }> =>
    fetch(createUrl(`${APIcontroller}/GetThread`, {}), {
      headers: {
        'Content-Type': 'application/json-patch+json',
        accept: 'text/plain',
      },
      method: 'GET',
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(threadJson => threadJson)
      .catch(error => []),
};

export default log;

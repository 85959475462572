import { InsureeType, OcrStatus, PersonType } from '../types/common.d';
import { WizardReducer } from '../types/wizard.d';

import {
  SET_STEP,
  SET_COURIER_ADDRESS,
  INCOMPLETE_STEP,
  COMPLETE_STEP,
  SET_PERSON_DATA,
  SET_INSURANCE_COMPANY,
  SET_OCR_STATUS,
  SET_CONSENT,
} from '../actions/wizard-action';

import {
  RESET_REGISTRATION,
  RESET_WITH_ZZ,
  BACK_TO_WIZARD,
} from '../actions/registration-action';

import { SET_PERSON_FROM_OCR_DATA } from '../actions/ocr-actions';

import mockDefault from './mocks/wizard/default';
import mockAdult from './mocks/wizard/adult';
import mockChild from './mocks/wizard/child';
import mockReal from './mocks/wizard/real';
import { GET_PERSON_DATA, OCR_CAPTURED_IMAGE } from '../api/actionSets';

const mockChildState: WizardReducer = mockChild;
const mockAdultState: WizardReducer = mockAdult;
const defaultState: WizardReducer = mockDefault;
const realState: WizardReducer = mockReal;

export default (state: WizardReducer = defaultState, action) => {
  switch (action.type) {
    case 'SET_MOCK_CHILD':
      return mockChildState;
    case 'SET_MOCK_ADULT':
      return mockAdultState;
    case 'SET_MOCK_REAL':
      return realState;
    case RESET_REGISTRATION:
      return defaultState;
    case SET_STEP:
      return {
        ...state,
        [`step${action.step}`]: {
          ...state[`step${action.step}`],
          ...action.data,
          isCompleted: true,
        },
      };
    case SET_COURIER_ADDRESS:
      return {
        ...state,
        step4: {
          ...state.step4,
          courierAddress: action.address,
          isCompleted: true,
        },
      };
    case INCOMPLETE_STEP:
      return {
        ...state,
        [`step${action.step}`]: {
          ...state[`step${action.step}`],
          isCompleted: false,
        },
      };
    case COMPLETE_STEP:
      return {
        ...state,
        [`step${action.step}`]: {
          ...state[`step${action.step}`],
          isCompleted: true,
        },
      };
    case SET_OCR_STATUS:
      return {
        ...state,
        step0: {
          ...state.step0,
          [action.insureeType]: {
            ...state.step0[action.insureeType],
            ocr: action.status,
          },
        },
      };
    case OCR_CAPTURED_IMAGE.ERROR:
      return {
        ...state,
        step0: {
          ...state.step0,
          [action.insureeType]: {
            ...state.step0[action.insureeType],
            ocr: OcrStatus.failure,
          },
        },
      };
    case SET_PERSON_DATA:
      return {
        ...state,
        step2: {
          ...state.step2,
          [action.personType]: {
            ...state.step2[action.personType],
            ...action.data,
          },
        },
      };
    case SET_INSURANCE_COMPANY:
      return {
        ...state,
        step1: {
          ...state.step1,
          insuranceCompany: action.company,
        },
      };
    case RESET_WITH_ZZ: {
      const cloneState = JSON.parse(JSON.stringify(state));
      //  check if previous insuree type is adult,than copy insuree as ZZ else copy legalRepresentative as ZZ
      const zz =
        cloneState.step2[
          cloneState.step1.person === PersonType.adult
            ? InsureeType.insuree
            : InsureeType.legalRepresentative
        ];

      const resetState = JSON.parse(JSON.stringify(defaultState));
      resetState.step2.legalRepresentative = zz;

      return {
        ...resetState,
        step1: {
          ...resetState.step1,
          person: PersonType.child,
        },
      };
    }
    case GET_PERSON_DATA.SUCCESS: {
      let pt = PersonType.adult;
      if (action.response.promoterPerson.birthNumber) {
        /**
         *
         * legislativna zmena, ze novorodenec je uz zmenova prihlaska
         *
         * newborn === child
         *
         */
        // if (personIsNewBornByBirthNumber(action.response.person.birthNumber)) {
        //   pt = PersonType.newBorn;
        // } else {
        //   pt = PersonType.child;
        // }
        pt = PersonType.child;
      }
      return {
        ...state,
        step1: {
          ...state.step1,
          person: pt,
        },
        step2: {
          ...state.step2,
          insuree: action.response.person,
          legalRepresentative: action.response.promoterPerson,
        },
        step4: {
          ...state.step4,
          email: action.response.email,
        },
      };
    }
    case BACK_TO_WIZARD: {
      return {
        ...state,
        step5: {
          ...state.step5,
          isCompleted: false,
        },
      };
    }
    case SET_PERSON_FROM_OCR_DATA:
      return {
        ...state,
        step2: {
          ...state.step2,
          [action.insureeType]: {
            ...state.step2[action.insureeType],
            ...action.data,
          },
        },
      };
    case SET_CONSENT:
      return {
        ...state,
        step4: {
          ...state.step4,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
};

export default {
  title: 'Pobočky poisťovne Dôvera',
  description: 'Zistite, či má Dôvera pobočku vo vašom okolí.',
  inputLabel: 'Mesto',
  inputPlaceholder: 'Zadajte mesto',
  addonButton: 'Preveriť',
  detailSearch: 'Detailné vyhľadávanie pobočiek',
  notification:
    'Kvôli chybe alebo preťaženiu sa nám nepodarilo zobraziť požadované údaje. Skúste to, prosím, neskôr.',
};

import _common from './_common';

export default {
  title: 'Profil',
  password: {
    label: 'Nové heslo',
    help:
      'Heslo musí obsahovať minimálne 8 znakov, veľké a malé pismená a aspoň jedno číslo. Dlhšie heslá sú bezpečnejšie.',
  },
  passwordRepeat: {
    label: 'Zopakujte heslo',
  },
  passwordOld: {
    label: 'Pôvodné heslo',
  },
  ucetVIA: {
    header: 'Účet VIA',
    spravaUctu: {
      header: 'Správa účtu',
      headers: {
        login: 'Prihlasovacie údaje',
        contact: 'Kontaktné údaje',
        bank: 'Bankové údaje',
      },
      zmenaHesla: {
        header: 'Zmena hesla',
        password: {
          label: 'Nové heslo',
          help:
            'Heslo musí obsahovať minimálne 8 znakov, veľké a malé pismená a aspoň jedno číslo. Dlhšie heslá sú bezpečnejšie.',
        },
        passwordRepeat: {
          label: 'Zopakujte heslo',
        },
        cta: _common.ulozit,
        zrusit: 'Zrušiť',
      },
      zmenaIban: {
        header: 'Zmena čísla účtu',
        cta: _common.ulozit,
        zrusit: 'Zrušiť',
        iban: {
          label: 'Nové číslo účtu',
          help: 'Zadajte Vaše číslo účtu vo forme IBAN',
        },
      },
      ticket: 'Vyžiadať ID kód',
    },
  },
};

import { StringMap } from '../types/index.d';
import uuidv4 from 'uuid/v4';
import { RESET_GA_EVENT, SET_GA_EVENT } from '../actions/metric-actions';
import { MetrikaGA, EventGA } from '../types/common.d';

//  ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject])

const generateGAevent = (
  uid: any,
  { category, action, label, value }: EventGA,
): MetrikaGA => ({
  id: uid,
  eventCategory: category,
  eventAction: action,
  eventLabel: label,
  eventValue: value,
});

const initialeMetrics: StringMap<MetrikaGA> = {};

export default (state = initialeMetrics, action) => {
  switch (action.type) {
    case RESET_GA_EVENT: {
      const list = { ...state };
      delete list[action.id];
      return list;
    }
    case SET_GA_EVENT: {
      const uid = uuidv4();
      return {
        ...state,
        [uid]: {
          ...state[uid],
          ...generateGAevent(uid, action),
        },
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import '@dovera/design-system/build/components/style.css';
import '@dovera/design-system/build/components/polyfills';
import 'core-js/es';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

import { BenefitsReducer } from '../types/reducers';
import { GET_BENEFITS_DATA } from '../api/actionSets';

const defaultState: BenefitsReducer = {
  isLoading: false,
  isLoaded: false,
};

export default (state: BenefitsReducer = defaultState, action) => {
  switch (action.type) {
    case GET_BENEFITS_DATA.LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case GET_BENEFITS_DATA.ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        loadingError: action.error,
      };
    case GET_BENEFITS_DATA.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        loadingError: null,
        ...action.response,
      };

    default:
      return state;
  }
};

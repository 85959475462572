import * as actionSets from './actionSets';
import { createUrl, createBodyLog, callApiRequest } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import {
  //   GetThreadMessagesRequest,
  GetThreadsRequest,
  AddMessageRequest,
  GetAttachmentRequest,
  DefaultThreadRequest,
  UnreadMessagesCountOutput,
} from '../types/messenger.d';
import { MOCK_API_CALL } from '../middleware/mockApiMiddleware';
import { Nullable } from '../types/index.d';

const controller = 'Message';

const messenger = {
  fetchThreads: (data: GetThreadsRequest) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetThreadList`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.FETCH_THREADS,
      authenticated: true,
    },
  }),
  mockFetchThreads: (data: GetThreadsRequest) => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/GetThreadList`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.MOCK_FETCH_THREADS,
      responseTime: 2000,
      mockResponse: {
        result: true,
        statusMessage: 'test',
        limit: 20,
        offset: 0,
        threadsCount: 3,
        threads: [
          {
            id: 5379646,
            subject: 'Notifikacia o spracovani ziadosti na zmenu udajov',
            bodyOfLastMessage:
              'Dobry den,<br />zasielame Vam spravu o spracovani Vasej poziadavky na zmenu udajov.Poziadavka sa tykala pripojenia rodinného príslušníka.Vasu poziadavku sme vyriesili.<br />V pripade podrobnejsich informacii nas, prosim, kontaktujte na Zakaznickej linke 0850 850 850.',
            threadType: 'Sprava',
            received: true,
            dateOfLastMessage: '2015-10-20T15:39:00',
            threadNotReaded: true,
            threadWithAttachment: false,
          },
          {
            id: 5224641,
            subject: 'Re: Informácia z EP [ID 11265903]',
            dateOfLastMessage: '2015-08-25T10:07:00',
            threadNotReaded: false,
            bodyOfLastMessage:
              'Dobrý deň pán Juck, <BR /><BR />dovoľujeme si Vás informovať, že Vami doručený e-mail z Elektronickej pobočky uzatvárame.<BR /><BR />V prípade doplňujúcich otázok sme Vám k dispozícii na Zákazníckej linke 0850 850 850, na čísle + 421 37 77 222 78 pre volania zo zahraničia alebo e-mailom na info@dovera.sk.<BR /><BR />S pozdravom<BR /><BR /> Marta LIGAČOVÁ<BR />operátor písomného kontaktu<BR /><BR />DÔVERA zdravotná poisťovňa, a. s.<BR />Cintorínska 5<BR />949 01 Nitra<BR /><BR />Zákaznícka linka:',
            received: true,
            threadWithAttachment: false,
            threadType: 'Sprava',
          },
          {
            id: 6811882,
            subject: 'Všeobecná',
            dateOfLastMessage: '2009-07-20T07:48:00',
            threadNotReaded: false,
            bodyOfLastMessage: 'test',
            received: false,
            threadWithAttachment: false,
            threadType: 'Sprava',
          },
        ],
      },
    },
  }),

  fetchThreadMessages: (threadId: number) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetMessageListThread`),
      body: createBodyLog({ threadId }),
      method: 'POST',
      actions: actionSets.FETCH_THREAD_MESSAGES,
      authenticated: true,
      meta: { threadId },
    },
  }),
  mockFetchThreadMessages: (threadId: number) => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/GetMessageListThread`),
      body: createBodyLog({ threadId }),
      method: 'POST',
      actions: actionSets.MOCK_FETCH_THREAD_MESSAGES,
      responseTime: 2000,
      mockResponse: {
        result: true,
        statusMessage: 'string',
        theme: 'Notifikacia o spracovani ziadosti na zmenu udajov',
        messages: [
          {
            id: 9597538,
            received: true,
            created: '2015-10-20T15:39:00',
            source: 'test source',
            subject: 'test subject',
            body:
              'Dobry den,<br />zasielame Vam spravu o spracovani Vasej poziadavky na zmenu udajov.Poziadavka sa tykala pripojenia rodinného príslušníka.Vasu poziadavku sme vyriesili.<br />V pripade podrobnejsich informacii nas, prosim, kontaktujte na Zakaznickej linke 0850 850 850.',
            canAnswer: false,
            attachments: [],
          },
        ],
      },
      meta: { threadId },
    },
  }),

  sendMessage: (data: AddMessageRequest) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/NewMessage`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.SEND_MESSAGE,
      authenticated: true,
    },
  }),

  downloadAttachment: (data: GetAttachmentRequest) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetAttachment`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.DOWNLOAD_ATTACHMENT,
      authenticated: true,
    },
  }),

  deleteThread: (data: DefaultThreadRequest) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/DeleteThread`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.DELETE_THREAD,
      authenticated: true,
    },
  }),

  setThreadAsRead: (data: DefaultThreadRequest) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ReadThread`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.READ_THREAD,
      authenticated: true,
    },
  }),

  getUnreadMessagesCount: (
    userId: Nullable<string>,
    token: Nullable<string>,
  ): Promise<UnreadMessagesCountOutput> =>
    callApiRequest(
      `${controller}/UnreadMessagesCount`,
      { userId },
      { Authorization: `Bearer ${token}` },
    )
      .then((response: any) => response)
      .catch(() => ({
        count: 0,
        success: false,
      })),
};

export default messenger;

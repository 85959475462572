import links from './links';

export default [
  {
    key: 'home',
    route: 'home',
    icon: 'IconMenuHome',
    title: 'Domov',
  },
  {
    key: 'benefits',
    route: 'benefity',
    icon: 'IconMenuGift',
    title: 'Benefity Dôvery',
  },
  {
    key: 'doctors',
    route: links.doveraOveritLekara,
    icon: 'IconMenuUser',
    title: 'Lekári',
  },
  {
    key: 'branches',
    route: links.doveraPobocky,
    icon: 'IconMenuBranch',
    title: 'Pobočky Dôvery',
  },
  {
    key: 'comparision',
    route: 'porovnanie',
    icon: 'IconMenuCompass',
    title: 'Porovnanie s inými poisťovňami',
  },
  {
    key: 'check insurance',
    route: links.overeniePoistnychVztahov,
    icon: 'IconMenuTarget',
    title: 'Overiť poisťovňu poistenca',
  },
  {
    key: 'private',
    route: 'prihlaska',
    icon: 'IconMenuFileText',
    title: 'Prihláška',
  },
];

export const SET_STEP_CONTRACT = 'SET_STEP_CONTRACT';
export const INCOMPLETE_STEP_CONTRACT = 'INCOMPLETE_STEP_CONTRACT';
export const COMPLETE_STEP_CONTRACT = 'COMPLETE_STEP_CONTRACT';
export const SET_VERIFYCODE_DATA = 'SET_VERIFYCODE_DATA';
export const SET_CONTRACTDATA_DATA = 'SET_CONTRACTDATA_DATA';
export const SET_IDENTITY_DATA = 'SET_IDENTITY_DATA';
export const SET_PERSONAL_DATA = 'SET_PERSONAL_DATA';
export const SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA';
export const SET_REWARD_DATA = 'SET_REWARD_DATA';

export const setStep = (step: number = 1, data: any) => ({
  type: `SET_STEP_CONTRACT`,
  step,
  data,
});

export const incompleteStep = index => ({
  type: INCOMPLETE_STEP_CONTRACT,
  step: parseInt(index, 10) + 1,
});

export const completeStep = index => ({
  type: COMPLETE_STEP_CONTRACT,
  step: parseInt(index, 10) + 1,
});

// step1
export const setVerifyCodeData = verifyCode => ({
  type: SET_VERIFYCODE_DATA,
  verifyCode,
});

// step2
export const setContractDataData = (birthNumber, nationality) => ({
  type: SET_CONTRACTDATA_DATA,
  birthNumber,
  nationality,
});

// step3
export const setIdentityData = (frontSideOP, backSideOP) => ({
  type: SET_IDENTITY_DATA,
  frontSideOP,
  backSideOP,
});

// step4
export const setPersonalInformation = personalData => ({
  type: SET_PERSONAL_DATA,
  personalData,
});

// step5
export const setPersonalAdditional = additionalData => ({
  type: SET_ADDITIONAL_DATA,
  additionalData,
});

// step6
export const setRewarding = (
  supervisor,
  entrustedSupervisor,
  rewardType,
  employeeCode,
) => ({
  type: SET_REWARD_DATA,
  supervisor,
  entrustedSupervisor,
  rewardType,
  employeeCode,
});

import moment from 'moment';
import { positions } from 'react-alert';

import links from './strings/links';

const TIME_PICKER_PATTERN = '([01]?[0-9]|2[0-3]):[0-5][0-9]';

const VALID_DATE = ['DD.MM.YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', moment.ISO_8601];

const TOAST_CONTAINER_OPTIONS = {
  position: positions.TOP_CENTER,
  containerStyle: {
    top: '3rem',
    zIndex: 1000,
    width: 'calc(100% - 2rem)',
  },
};

const HEADER_HEIGHT = 56;
const TABBAR_HEIGHT = 55;
const PAGE_ITEMS_SIZE = 5;
const SYSTEM_TIMESTAMP = '23:57';

const TYPE_WEIGHT = ['hmotnost', 'hmotnost_prichod'];
const TYPE_LENGTH = 'vyska';

const emojiSet = {
  cookie: '&#127850;',
  sleeping: '&#128564;',
  prohibited: '&#128683;',
  error: '&#128584;',
  success: '&#128076;',
  microscope: '&#128300;',
};

const emojiSetFaces = {
  error: '🙈',
  success: '👌',
  microscope: '🔬',
  cookie: '🍪',
  sleeping: '😴',
  prohibited: '🚫',
};

const CONTACTS_URL = links.doveraKontakty;
const SIGNATUS_VIEW = links.digitalnyPodpis;

const COOKIE_GLOBAL_TYPE = 'cookieconsent_dismissed';

const REGISTRATION_GRID_COL = { m: 12, l: 6 };

export {
  TIME_PICKER_PATTERN,
  VALID_DATE,
  TOAST_CONTAINER_OPTIONS,
  HEADER_HEIGHT,
  TABBAR_HEIGHT,
  PAGE_ITEMS_SIZE,
  SYSTEM_TIMESTAMP,
  TYPE_WEIGHT,
  TYPE_LENGTH,
  emojiSet,
  emojiSetFaces,
  SIGNATUS_VIEW,
  CONTACTS_URL,
  COOKIE_GLOBAL_TYPE,
  REGISTRATION_GRID_COL,
};

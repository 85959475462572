import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { apiMiddleware } from '@mediworx/mdxutils';
import { mockApiMiddleware } from './mockApiMiddleware';
import { ocrMiddleware } from './ocrMiddleware';

const apiMiddlewareSettings = {
  getAuthToken: store => `Bearer ${store.getState().auth.token}`,
  defaultHeaders: {
    'Collections-Format': 'map',
  },
};

let middleware = [
  thunk,
  apiMiddleware(apiMiddlewareSettings),
  mockApiMiddleware,
  ocrMiddleware,
];

const createMiddleware = () => {
  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
      collapsed: true,
    });
    middleware = [...middleware, logger];
  }

  return middleware;
};

export default createMiddleware;

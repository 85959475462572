import { createBrowserHistory as createHistory } from 'history';

export const history = createHistory();

history.listen((location, action) => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (action === 'POP') {
      return;
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0);
  });
});

const prefix = `${
  window.env && window.env.config ? window.env.config.baseURI : ''
}`;

const routes = {
  offline: `${prefix}/offline`,
  loggedOut: `${prefix}/odhlaseny`,
  inkognito: `${prefix}/inkognito-rezim`,
  benefity: `${prefix}/benefity`,
  zlavoveBenefity: `${prefix}/benefity/zlava`,
  callback: `${prefix}/callback`,
  cookies: `${prefix}/cookies`,
  contacts: `${prefix}/kontakty`,
  demo: `${prefix}/demo`,
  camera: `${prefix}/camera`,
  extranet: {
    podriadeni: `${prefix}/extranet/podriadeni`,
    podriadeniDetail: `${prefix}/extranet/podriadeni/:id`,
    zobrazDetailPodriadeni: id => `${prefix}/extranet/podriadeni/${id}`,
    prehladProdukcie: `${prefix}/extranet/prehlad-produkcie`,
    prihlasky: `${prefix}/extranet/prihlasky`,
    vyplatnePasky: `${prefix}/extranet/vyplatne-pasky`,
    dohody: `${prefix}/extranet/dohody`,
    spravaDohod: `${prefix}/extranet/sprava-dohod`,
    spravaDohodDetail: `${prefix}/extranet/sprava-dohod/detail/:token`,
    zobrazDetailDohody: token =>
      `${prefix}/extranet/sprava-dohod/detail/${token}`,
    ukoncenieDohod: `${prefix}/extranet/ukoncenie-dohod`,
  },
  dohoda: {
    formular: `${prefix}/dohoda/formular`,
    newEmployee: `${prefix}/novy-zamestnanec`,
  },
  spravy: {
    root: `${prefix}/spravy`,
    detail: `${prefix}/spravy/detail`,
  },
  figma: `${prefix}/figma`,
  home: `${prefix}/`,
  infoVerzie: `${prefix}/version-sw`,
  pobocky: `${prefix}/pobocky`,
  ponukaNaEmail: `${prefix}/ponuka-na-email`,
  porovnanie: `${prefix}/porovnanie`,
  prihlasenie: `${prefix}/prihlasenie`,
  prihlaska: `${prefix}/prihlaska`,
  profil: `${prefix}/profil`,
  rekapitulacia: `${prefix}/rekapitulacia-prihlasky`,
  signatureCallback: `${prefix}/signature/callback`,
  contractCallback: `${prefix}/signature/callbackContract`,
  contractByEmployeeCallback: `${prefix}/signature/callbackContractByE`,
  vzdelavanie: `${prefix}/vzdelavanie`,
  vzdelavanieNext: {
    vzdelavanie: `${prefix}/vzdelavanie-next/`,
    precoZdravotnePoistenie: `${prefix}/vzdelavanie-next/preco-zdravotne-poistenie`,
    akoSNamiSpolupracovat: `${prefix}/vzdelavanie-next/ako-s-nami-spolupracovat`,
    vyplneniePrihlasky: `${prefix}/vzdelavanie-next/vyplnenie-prihlasky`,
    vyplneniePrihlaskyOnline: `${prefix}/vzdelavanie-next/vyplnenie-prihlasky/online`,
    povinnostiPoistenca: `${prefix}/vzdelavanie-next/povinnosti-poistenca`,
    akoZvladatOtazkyKlientov: `${prefix}/vzdelavanie-next/ako-zvladat-otazky-klientov`,
  },
  zmluvniLekari: `${prefix}/zmluvni-lekari`,
  zabudnuteUdaje: `${prefix}/zabudnute-udaje`,
  zabudnuteUdajeVysledok: `${prefix}/zabudnute-udaje/vysledok`,
  campaing: {
    ovb: `${prefix}/vzdelavanie/kampan/ovb`,
    zac: `${prefix}/vzdelavanie/kampan/zac`,
    star1: `${prefix}/vzdelavanie/kampan/star1`,
    star2: `${prefix}/vzdelavanie/kampan/star2`,
    extPob: `${prefix}/vzdelavanie/kampan/ext-pob`,
  },
  viralnyMarketing: `${prefix}/viralny-marketing`,
  webPrihlaskaRedirect: `${prefix}/podpis-web-prihlasky`,
};

export default routes;

import { slovakStateCode } from '../constants/index';
import { callApiRequest } from '../utils/apiUtils';
import { Nullable } from '../types/index.d';

const controller = 'AutoComplete';

const address = {
  getAllCountries: () => {
    return callApiRequest(`${controller}/GetCountries`, { search: '' })
      .then((countryList: any) =>
        countryList.result.map(
          (c: { code: string; name: string; isEU: boolean }) => ({
            value: c.code,
            title: c.name,
            isEU: c.isEU,
          }),
        ),
      )
      .catch(error => []);
  },
  getCountries: (
    query: string,
    mergedTitle?: boolean,
    type: string = 'prihlaska',
  ): Promise<
    Array<{
      title: string;
      value: string;
    }>
  > => {
    if (query && query.trim() !== '') {
      return callApiRequest(`${controller}/GetCountries`, { search: query })
        .then((countryList: any) =>
          countryList.result
            .filter(c => c.code !== 'XX' && c.code !== '##' && c.code !== 'G')
            .map((c: { code: string; name: string; isEU: boolean }) => ({
              value: c.code,
              title: mergedTitle ? `${c.code} - ${c.name}` : c.name,
              isEU: c.isEU,
            })),
        )
        .catch(error => []);
    }
    return new Promise((resolve: any) => {
      let countryList;
      if (type === 'prihlaska') {
        countryList = [
          {
            value: 'SK',
            title: 'SLOVENSKÁ REPUBLIKA',
            isEU: true,
          },
          {
            value: 'CZ',
            title: 'ČESKÁ REPUBLIKA',
            isEU: true,
          },
          {
            value: 'HU',
            title: 'MAĎARSKÁ REPUBLIKA',
            isEU: true,
          },
          {
            value: 'UA',
            title: 'UKRAJINA',
            isEU: false,
          },
          {
            value: 'PL',
            title: 'POĽSKÁ REPUBLIKA',
            isEU: true,
          },
          {
            value: 'AT',
            title: 'RAKÚSKA REPUBLIKA',
            isEU: true,
          },
        ];
      }
      if (type === 'dohoda') {
        countryList = [
          {
            value: 'SK',
            title: 'SLOVENSKÁ REPUBLIKA',
          },
          {
            value: 'UA',
            title: 'UKRAJINA',
          },
          {
            value: 'SRB',
            title: 'SRBSKO',
          },
        ];
      }
      resolve(countryList);
      return countryList;
    });
  },
  getBirthPlaceCities: (
    query: string,
    countryCode?: string,
  ): Promise<
    Array<{
      title: string;
      value: string;
      town: string;
      region: string;
      locality: string;
    }>
  > =>
    callApiRequest(`${controller}/GetCities`, {
      search: query,
      country: countryCode || null,
    })
      .then((myJson: any) =>
        myJson.result
          .filter(
            (c: { localityName: string; localityCode: string }) =>
              c.localityCode == null && c.localityName == null,
          )
          .map(
            (c: {
              code: string;
              name: string;
              countryCode: string;
              region: string;
            }) => ({
              value: c.code,
              title: `${c.name}${
                c.countryCode === 'SK' && c.region ? ` - okres ${c.region}` : ''
              }${c.countryCode !== 'SK' ? ` (${c.countryCode})` : ''}`,
              town: c.name,
            }),
          ),
      )
      .catch(error => {
        console.log(error);
        return [];
      }),
  getCities: (
    query: string,
    countryCode?: string,
  ): Promise<
    Array<{
      title: string;
      value: string;
      town: string;
      region: string;
      locality: string;
    }>
  > =>
    callApiRequest(`${controller}/GetCities`, {
      search: query,
      country: countryCode || slovakStateCode,
    })
      .then((myJson: any) =>
        myJson.result.map(
          (c: {
            code: string;
            name: string;
            countryCode: string;
            localityName: string;
            localityCode: string;
            region: string;
          }) => ({
            value: c.code,
            title: `${c.name}${c.localityName ? ` - ${c.localityName}` : ''}${
              c.countryCode === 'SK' && c.region ? ` - okres ${c.region}` : ''
            }${c.countryCode !== 'SK' ? ` (${c.countryCode})` : ''}`,
            town: c.name,
            region: c.region,
            localityCode: c.localityCode,
            localityName: c.localityName,
          }),
        ),
      )
      .catch(error => {
        console.log(error);
        return [];
      }),
  getStreets: (
    query: string,
    cityCode: string,
    countryCode?: string,
    localityCode?: Nullable<string>,
  ): Promise<
    Array<{
      title: string;
      value: string;
      zip: Nullable<string>;
    }>
  > =>
    callApiRequest(`${controller}/GetStreets`, {
      search: query,
      country: countryCode || slovakStateCode,
      cityCode,
      localityCode,
    })
      .then((myJson: any) =>
        myJson.result.map(
          (c: {
            code: string;
            name: string;
            cityCode: string;
            zipCode: Nullable<string>;
          }) => ({
            value: c.code,
            title: c.name,
            zip: c.zipCode,
          }),
        ),
      )
      .catch(error => {
        console.log(error);
        return [];
      }),
  getPostCodes: (
    cityCode: string,
    street: Nullable<string>,
    countryCode?: string,
    localityCode?: Nullable<string>,
  ): Promise<
    Array<{
      title: string;
      value: string;
    }>
  > =>
    callApiRequest(`${controller}/GetPostCodes`, {
      country: countryCode || slovakStateCode,
      cityCode,
      street,
      localityCode,
    })
      .then((myJson: any) =>
        myJson.result.map(
          (c: {
            code: string;
            cityCode: string;
            cityName: string;
            streetCode: string;
            streetName: string;
            countryCode: string;
          }) => ({
            value: c.code,
            title: c.code,
            ...c,
          }),
        ),
      )
      .catch(error => {
        console.log(error);
        return [];
      }),
};

export default address;

export default {
  camera_error: 'Žiaden prístup k fotoaparátu',
  camera_disabled: 'Fotoaparát je zablokovaný nastaveniami',
  camera_low_res:
    'Automatické nasnímanie obrázka nie je možné. Rozlišenie fotoaparátu je nedostačujúce',
  camera_low_res_ios:
    'Automatické nasnímanie obrázka nie je možné. Aktualizujte svoje zariadenie iOS na verziu 12.2 alebo novšiu',
  camera_alert_closer: 'Premiestnite fotoaparát bližšie k dokumentu',
  camera_alert_searching: 'Hľadám dokument',
  app_loading: 'Načítava sa modul snímania',
  wasm_error: 'Chyba snímania',
  image_processing: 'Spracováva sa obrázok…',
  use_autocapture_title: 'Použite automatické zachytenie',
  use_autocapture_text: 'Obrázok sa zachytí a oreže automaticky',
  low_quality_title: 'Nízka kvalita obrazu',
  low_quality_text: 'Znovu zachytenie obrázku s lepšou kvalitou',
  crop_error_title: 'Nemožno zistiť hranice dokumentu',
  crop_error_text: 'Orezať obrázok ručne',
  continue: 'Ďalej',
  browser_edge_error:
    'Automatické snímanie obrázkov nie je podporované. Použite prehliadač Safari alebo Google Chrome',
  ios_non_safari_error:
    'Automatické snímanie obrázkov nie je podporované. Použite Safari vo verzii 12.2 alebo novšej',
  add_page: 'Zadná strana',
  done: 'Pokračovať',
  page: 'Strana',
  of: 'z',
  delete: 'Vymazať',
  retake: 'Znovu zachytenie',
  crop: 'Orezať',
  rotate: 'Otočiť',
  cancel: 'Ukončiť',
  apply: 'Použiť',
  abbyy: {
    OcrQualityFront:
      'Predná strana dokladu je nasnímana v zlej kvalite. Pre lepšiu kvalitu nasnímajte doklad v dostatočne osvetlenom prostredi.',
    OcrQualityBack:
      'Zadná strana dokladu je nasnímana v zlej kvalite. Pre lepšiu kvalitu nasnímajte doklad v dostatočne osvetlenom prostredi.',
    IncorrectRationFront:
      'Predná strana dokladu má nesprávny pomer strán. Pre správny pomer strán vycentrujte snímaný doklad do pomocného obdĺžnika aby ho hrany dokladu kopírovali.',
    IncorrectRationBack:
      'Zadná strana dokladu má nesprávny pomer strán. Pre správny pomer strán vycentrujte snímaný doklad do pomocného obdĺžnika aby ho hrany dokladu kopírovali.',
    MissingPictureFront: `Nebola nasnímaná predná strana dokladu. Na získanie údajov je potrebné mať nasnímanú prednú aj zadnú stranu dokladu.`,
    MissingPictureBack: `Nebola nasnímaná zadná strana dokladu. Aby bolo možné nasnímať aj ďalšiu stranu dokladu, je potrebné stlačiť tlačidlo "Zadná strana".`,
  },
};

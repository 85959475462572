export const LOG_SET_SECTION = 'LOG_SET_SECTION';
export const LOG_SET_IP = 'LOG_SET_IP';
export const LOG_USED_TREAD = 'LOG_USED_TREAD';
export const STORE_THREAD = 'LOG_USED_TREAD';

export const logSetSection = section => ({
  type: LOG_SET_SECTION,
  section,
});

export const logSetIP = ip => ({
  type: LOG_SET_IP,
  ip,
});
export const logUsedThread = {
  type: LOG_USED_TREAD,
};
export const storeThread = threadId => ({
  type: STORE_THREAD,
  threadId,
});

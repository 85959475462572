import { logUsedThread } from '../actions/log-actions';
import defaultStore from '../store';
import { StringMap, ProcessLogRequestType, LogType } from '../types/index.d';
import { iterateObject } from './objectUtils';

export const callApiRequest = (
  route: string,
  query: any,
  headers?: any,
): Promise<Response> =>
  fetch(createUrl(route, {}), {
    headers: {
      'Content-Type': 'application/json-patch+json',
      ...headers,
    },
    method: 'POST',
    body: JSON.stringify(createBodyLog(query)),
  }).then(handleErrors);

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};

export const createUrl = (
  url: string,
  queryParams?: StringMap<string>,
): string =>
  // @ts-ignore
  `${window.env.config.apiPath}/${url}${createQueryParams(queryParams)}`;

export const createQueryParams = (
  queryParams: StringMap<string> = {},
): string => {
  let result: string = '';
  iterateObject(queryParams, (value, key, index) => {
    if (index === 0) {
      result += '?';
    } else {
      result += '&';
    }
    result += `${key}=${value}`;
  });
  return result;
};

export const createBodyLog = (body: any) => {
  const uId = defaultStore.getState().auth.pouzivatelId;
  const bId = defaultStore.getState().auth.vztahId;
  const logData: LogType = {
    userID: uId ? parseInt(uId, 10) : 0,
    brokerID: bId || 0,
    threadID: parseInt(defaultStore.getState().log.thread.toString(), 10),
    ip: defaultStore.getState().log.ip || '',
    userAgent: window.navigator.userAgent,
  };

  return { logData, ...body };
};

export const createProcessLog = (process: ProcessLogRequestType) => {
  defaultStore.dispatch(logUsedThread);
  const wizard =
    process.controller === 'dohoda'
      ? JSON.stringify(defaultStore.getState().contractWizard)
      : JSON.stringify(defaultStore.getState().wizard);
  const logData: LogType = createBodyLog({});
  const processData = {
    ...process,
    request: wizard,
  };
  return { logData, ...{ ...processData, ...logData } };
};

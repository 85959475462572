import * as React from 'react';
import { OcrService } from '../services/OcrService';
import { OcrResponse } from '../types/common.d';

interface OcrContextType {
  isAlive: () => boolean;
  init: () => void;
  capture: (forceSkipProcessing?: boolean, localConfig?: any) => Promise<any>;
  ocr: (data: any[]) => Promise<OcrResponse | ErrorConstructor>;
}

const defaultOcrContext: OcrContextType = {
  // @ts-ignore
  isAlive: () => null,
  // @ts-ignore
  init: () => null,
  // @ts-ignore
  capture: () => null,
  // @ts-ignore
  ocr: () => null,
};
export const OcrContext = React.createContext(defaultOcrContext);

export function OcrProvider({ children }) {
  const ocrService = React.useMemo(() => new OcrService(), []);

  return (
    <OcrContext.Provider value={ocrService}>{children}</OcrContext.Provider>
  );
}

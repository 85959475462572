import * as actionSets from './actionSets';
import { createUrl, createBodyLog } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import { MOCK_API_CALL } from '../middleware/mockApiMiddleware';
import { WizardOcrStep, InsureeType } from '../types/common.d';

const controller = 'OCR';
const ocr = {
  ocrMock: (
    data,
    step: WizardOcrStep,
    insureeType?: InsureeType | undefined,
  ) => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/RecognitionData`),
      method: 'POST',
      actions: actionSets.OCR_CAPTURED_IMAGE,
      responseTime: 2000,
      meta: { step, insureeType },
      mockResponse: {
        result: JSON.stringify({
          givenNames: 'Specimen',
          surname: 'Vzorka',
          nationality: 'SVK',
          sex: 'F',
          number: 'EAOOOOOO',
          issuedBy: 'Bratislava',
          dateOfBirth: step === WizardOcrStep.step0 ? '15.07.1986' : '14.03.94',
          personalNumber:
            step === WizardOcrStep.step0 ? '865715/9335' : '140309/4407',
          dateOfExpiry: '01.03.2025',
          dateOfIssue: '01.03.2015',
          // address: ['Košická Belá 200'],
          address: ['Košice-Dargovských hrdinov', 'Benadová 780/15'],
          surnameAtBirth: 'Pôvodná',
          placeOfBirth: '',
          specialRemarks: ['Mgr.'],
          machineReadableZone: [''],
        }),
      },
    },
  }),
  ocr: (data, step: WizardOcrStep, insureeType?: InsureeType | undefined) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/RecognitionData `),
      method: 'POST',
      body: createBodyLog(data),
      actions: actionSets.OCR_CAPTURED_IMAGE,
      meta: { step, insureeType },
    },
  }),
  nfinity_createTransaction: () => ({
    [API_CALL]: {
      url: createUrl(`${controller}/create-transaction`),
      method: 'POST',
      body: createBodyLog({}),
      actions: actionSets.NFINITY_OCR_CREATE_TRANSACTION,
    },
  }),
  nfinity_getData: (transactionGuid: string) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/get-data`),
      method: 'POST',
      body: createBodyLog({ transactionGuid }),
      actions: actionSets.NFINITY_OCR_GET_DATA,
    },
  }),
};

export default ocr;

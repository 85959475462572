export const STORE_REGISTRATION = 'STORE_REGISTRATION';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const SET_SIGN_TYPE = 'SET_SIGN_TYPE';
export const BACK_TO_WIZARD = 'BACK_TO_WIZARD';
export const RESET_WITH_ZZ = 'RESET_WITH_ZZ';
export const DOWNLOAD_REGISTRATION = 'DOWNLOAD_REGISTRATION';
export const SIMULATE_SUCCESSFUL_REGISTRATION =
  'SIMULATE_SUCCESSFUL_REGISTRATION';
export const RESET_VERIFY_INSUREE = 'RESET_VERIFY_INSUREE';
export const RESET_SMS_LINK_MODAL = 'RESET_SMS_LINK_MODAL';
export const APPEND_OP_DOCUMENTS = 'APPEND_OP_DOCUMENTS';
export const ADD_OP_DOCUMENTS_TO_REGISTRATION =
  'ADD_OP_DOCUMENTS_TO_REGISTRATION';

export const setSignType = signType => ({
  type: SET_SIGN_TYPE,
  signType,
});
export const appendOPDocuments = documents => ({
  type: APPEND_OP_DOCUMENTS,
  documents,
});

export const storeRegData = data => ({
  type: STORE_REGISTRATION,
  data,
});

export const resetRegData = {
  type: RESET_REGISTRATION,
};

export const resetSMSModalState = {
  type: RESET_SMS_LINK_MODAL,
};

export const resetWithZZ = {
  type: RESET_WITH_ZZ,
};

export const backToWizard = {
  type: BACK_TO_WIZARD,
};

export const simulateSuccessfulRegistration = {
  type: SIMULATE_SUCCESSFUL_REGISTRATION,
  response: {
    insureeRegistrationID: 'insureeID123',
    pdfName: 'Test.pdf',
    pdfContent: 'xxx',
    token: 'token',
    toSign: true,
    documentSignatusId: null,
  },
};

export const downloadRegistration = {
  type: DOWNLOAD_REGISTRATION,
};

export const resetInsureeVerificiation = {
  type: RESET_VERIFY_INSUREE,
};
export const addOPDocumentsToRegistration = documents => ({
  type: ADD_OP_DOCUMENTS_TO_REGISTRATION,
  documents,
});

import {
  UserMainRoles,
  UserAdditionalRoles,
  UserCampaignRoles,
} from '../types/userAccessRoles.d';
import {
  limitedAccessRoutes,
  fullAccessRoutes,
  vypPaskyAccessRoutes,
  makGroupAccessRoutes,
  adminAccessRoutes,
} from '../constants/accessRoutes';

export const includeRole = (role: string, allRoles: string[]): boolean =>
  allRoles.findIndex(r => r === role) !== -1;

export const hasLimitedAccess = userRoles =>
  includeRole(UserMainRoles.limited, userRoles);
export const hasFullAccess = userRoles =>
  includeRole(UserMainRoles.full, userRoles);

export const hasGroup = userRoles =>
  includeRole(UserAdditionalRoles.maklerGroup, userRoles) ||
  includeRole(UserAdditionalRoles.maklerGroupNM, userRoles);
export const hasAdmin = userRoles =>
  includeRole(UserAdditionalRoles.maklerAdmin, userRoles) ||
  includeRole(UserAdditionalRoles.olzAdmin, userRoles);
export const hasPayouts = userRoles =>
  includeRole(UserAdditionalRoles.paska, userRoles);
// method used as an exception for change registration
export const hasChangePermission = userRoles =>
  includeRole(UserAdditionalRoles.zmena, userRoles);

// show relevant content by access privileges
export const showByRole = (path: string, roles: string[]) => {
  if (hasLimitedAccess(roles)) {
    if (hasPayouts(roles) && vypPaskyAccessRoutes.includes(path)) {
      return true;
    }
    if (limitedAccessRoutes.includes(path)) {
      return true;
    }
    return false;
  }
  if (hasFullAccess(roles)) {
    if (hasPayouts(roles) && vypPaskyAccessRoutes.includes(path)) {
      return true;
    }
    if (hasGroup(roles) && makGroupAccessRoutes.includes(path)) {
      return true;
    }
    if (hasAdmin(roles) && adminAccessRoutes.includes(path)) {
      return true;
    }
    if (fullAccessRoutes.includes(path)) {
      return true;
    }
    return false;
  }
  return false;
};

// roles for test purposes only
export const debug = true;

export const contractRoles = [
  UserMainRoles.full,
  UserAdditionalRoles.maklerAdmin,
  UserAdditionalRoles.olzAdmin,
  UserAdditionalRoles.maklerGroup,
  UserAdditionalRoles.paska,
  UserAdditionalRoles.zmena,
  UserCampaignRoles.ovb,
];

export const hasPermissionByCode = (code: string): boolean =>
  code ? true : false;

import dotProp from 'dot-prop-immutable';
import { Nullable, StringMap } from '../types/index.d';
import { SetStateAction } from 'react';

/**
 * @param obj @nullable
 * @returns {number} return number of keys within the object
 * or 0, if undefined or null
 */
export function objectLength<T>(obj?: T): number {
  if (!obj) {
    return 0;
  }

  return Object.keys(obj).length;
}

/**
 * Map ObjectMap<string, Object> to object O
 * @param obj key, value pair object
 * @param mapper function that takes a value and maps it to a different type
 * @param toSort boolen param to sort object by key //safari issue
 */
export function mapObject<T, O>(
  obj: StringMap<T>,
  mapper: (val: T, key: string, index: number) => O,
  toSort: boolean = true,
): O[] {
  if (!obj) {
    return [];
  }

  const result: O[] = [];
  const keys = Object.keys(obj);
  if (toSort) {
    keys
      .sort((a: any, b: any) => parseInt(a, 10) - parseInt(b, 10))
      .forEach((key, index) => {
        result.push(mapper(obj[key], key, index));
      });
  } else {
    keys.forEach((key, index) => {
      result.push(mapper(obj[key], key, index));
    });
  }

  return result;
}

/**
 * Iterate over { key, value } map
 * @param obj @nullable object
 * @param forEach function that will be called with (value, key, index) params
 */
export function iterateObject<T>(
  obj: StringMap<T>,
  forEach: (o: T, key: string, index: number) => void,
): void {
  if (!obj || Object.keys(obj).length === 0) {
    return;
  }

  Object.keys(obj)
    // .sort((a: any, b: any) => parseInt(a, 10) - parseInt(b, 10))
    .forEach((key, index) => {
      forEach(obj[key], key, index);
    });
}

/**
 * Run search function over the { key, value } object
 * @param obj
 * @param searchFunc function to run over each value of the map
 * @returns {*} object if searchFunc returns true at any moment or null,
 * which means object hasn't been found
 */
export function searchObject<T>(
  obj: StringMap<T>,
  searchFunc: (input: T) => boolean,
): Nullable<T> {
  let result: Nullable<T> = null;
  iterateObject(obj, currentObject => {
    if (searchFunc(currentObject)) {
      result = currentObject;
    }
  });
  return result;
}

export function reduceObject<T, V>(
  obj: StringMap<T>,
  startValue: V,
  reducer: (accumulator: V, current: T, i: number) => V,
): V {
  let value = startValue;

  iterateObject(obj, (o, key, index) => {
    value = reducer(value, o, index);
  });

  return value;
}

/**
 * Check if @nullable object has keys
 * @param obj
 * @return true if object has keys or 0 if object is null or {}
 */
export function isNotEmptyObject<T>(obj: T) {
  return objectLength(obj) !== 0;
}

export function isEmptyObject<T>(obj: T) {
  return !isNotEmptyObject(obj);
}

export function getValueByKeyIndex<T>(
  obj: StringMap<T>,
  keyIndex: number,
): Nullable<T> {
  const keys = Object.keys(obj);
  if (isEmptyObject(obj) || keys.length < keyIndex + 1) {
    return null;
  }
  return obj[keys[keyIndex]];
}

export function getKeyByKeyIndex<T>(
  obj: StringMap<T>,
  keyIndex: number,
): Nullable<string> {
  const keys = Object.keys(obj);
  if (isEmptyObject(keys) || keys.length < keyIndex + 1) {
    return null;
  }
  return keys[keyIndex];
}

export function getValueFromStateByPathAndKey(
  obj: SetStateAction<any>,
  path: Nullable<string>,
  key?: string,
): Nullable<string> {
  if (!path && !key) return null;
  if (obj && key && !path) return obj[key];
  // @ts-ignore
  const o = dotProp.get(obj, path);
  if (!o) return null;
  if (!key) {
    return o;
  }
  return o[key];
}

export function modifyState(
  obj: SetStateAction<any>,
  eventValue: string,
  path: Nullable<string>,
): SetStateAction<any> {
  return dotProp.set(obj, `${path}`, eventValue);
}

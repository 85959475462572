export default {
  actions: {
    click: 'klik',
  },
  label: {
    applicationForm: 'prihlaska',
    category: 'kategorie',
    benefit: 'benefit',
    moreBenefits: 'ďalšie benefity',
    show: 'zobrazenie',
    termination: 'ukoncenie',
    scan: 'skenovanie',
    checkRelation: 'overenie vzťahu',
    delivery: 'spôsob doručenia',
    signature: 'spôsob podpisu',
    insurance: 'poistenie',
  },
  value: {
    done: 'dokončenie',
    start: 'začatie',
    top: 'top3',
    signature: {
      digital: 'digitálny',
      print: 'tlač',
      courier: 'kurier',
    },
    extranet: {
      production: 'produkcia',
      list: 'prihlášky',
      payout: 'výplatné pásky',
      group: 'moja skupina',
      education: 'vzdelávanie',
      mailto: 'poslať mailom',
    },
  },
};

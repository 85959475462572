import {
  DAJ_UDAJE_POUZIVATELA,
  CHANGE_LOGIN,
  CHANGE_PASSWORD,
  CHANGE_IBAN,
} from '../api/actionSets';
import { INVALID_TOKEN } from '@mediworx/mdxutils';
import {
  INIT_AUTH_STATE,
  LOG_OUT,
  SIGN_IN_REDIRECT,
  SET_DEFAULT_LOGIN_STATE,
  SET_DEFAULT_PASSWORD_STATE,
  SET_DEFAULT_IBAN_STATE,
  SIMULATE_SUCCESSFUL_LOGIN,
  SIMULATE_SUCCESSFUL_PASSWORD,
  SIMULATE_SUCCESSFUL_IBAN,
} from '../actions/auth-actions';
import { AuthReducer } from '../types/reducers.d';

const loginInitialState = {
  success: false,
  message: null,
  isLoaded: false,
  isLoading: false,
  username: null,
};

const passwordInitialState = {
  success: false,
  message: null,
  isLoaded: false,
  isLoading: false,
};

const ibanInitialState = {
  success: false,
  message: null,
  isLoaded: false,
  isLoading: false,
};

export const initialState: AuthReducer = {
  isLoaded: false,
  isLoading: false,
  loadingError: null,
  token: null,
  pouzivatelId: null,
  vztahId: null,
  user: null,
  userRoles: [],
  role: null,
  login: loginInitialState,
  password: passwordInitialState,
  iban: ibanInitialState,
};

function authReducer(state: AuthReducer = initialState, action) {
  switch (action.type) {
    case DAJ_UDAJE_POUZIVATELA.LOADING: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case DAJ_UDAJE_POUZIVATELA.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        user: action.response.userInfoDetail,
        userRoles: action.response.roles,
      };
    }
    case DAJ_UDAJE_POUZIVATELA.ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        loadingError: action.response,
      };
    }
    case SET_DEFAULT_LOGIN_STATE: {
      return {
        ...state,
        login: loginInitialState,
      };
    }
    case CHANGE_LOGIN.LOADING: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: true,
          isLoaded: false,
          username: null,
        },
      };
    }
    case SIMULATE_SUCCESSFUL_LOGIN:
    case CHANGE_LOGIN.SUCCESS: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: false,
          isLoaded: true,
          success: action.response.success,
          message: action.response.message,
          username: action.response.success && action.username,
        },
      };
    }
    case CHANGE_LOGIN.ERROR: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: false,
          isLoaded: true,
          success: false,
          message: 'Nastala chyba pri komunikácii so serverom',
          username: null,
        },
      };
    }
    case SET_DEFAULT_PASSWORD_STATE: {
      return {
        ...state,
        password: passwordInitialState,
      };
    }
    case CHANGE_PASSWORD.LOADING: {
      return {
        ...state,
        password: {
          ...state.password,
          isLoading: true,
          isLoaded: false,
        },
      };
    }
    case SIMULATE_SUCCESSFUL_PASSWORD:
    case CHANGE_PASSWORD.SUCCESS: {
      return {
        ...state,
        password: {
          ...state.password,
          isLoading: false,
          isLoaded: true,
          success: action.response.success,
          message: action.response.message,
        },
      };
    }
    case CHANGE_PASSWORD.ERROR: {
      return {
        ...state,
        password: {
          isLoading: false,
          isLoaded: true,
          success: false,
          message: 'Nastala chyba pri komunikácii so serverom',
        },
      };
    }
    case SET_DEFAULT_IBAN_STATE: {
      return {
        ...state,
        iban: ibanInitialState,
      };
    }
    case CHANGE_IBAN.LOADING: {
      return {
        ...state,
        iban: {
          ...state.iban,
          isLoading: true,
          isLoaded: false,
        },
      };
    }
    case SIMULATE_SUCCESSFUL_IBAN:
    case CHANGE_IBAN.SUCCESS: {
      return {
        ...state,
        iban: {
          ...state.iban,
          isLoading: false,
          isLoaded: true,
          success: action.response.success,
          message: action.response.message,
        },
      };
    }
    case CHANGE_IBAN.ERROR: {
      return {
        ...state,
        iban: {
          isLoading: false,
          isLoaded: true,
          success: false,
          message: 'Nastala chyba pri komunikácii so serverom',
        },
      };
    }
    case LOG_OUT:
      window.localStorage.removeItem('auth');
      return initialState;
    case INVALID_TOKEN:
      localStorage.removeItem('auth');
      return initialState;
    case INIT_AUTH_STATE:
      return {
        ...state,
        ...JSON.parse(localStorage.getItem('auth') || '{}'),
      };
    case SIGN_IN_REDIRECT: {
      const newState = {
        ...state,
        pouzivatelId: action.pouzivatelId,
        vztahId: action.vztahId,
        token: action.token,
        role: action.role,
        isLoaded: false,
        isLoading: false,
      };
      localStorage.setItem('auth', JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
}

export default authReducer;

import { createLoadingAction, createSuccessAction } from '@mediworx/mdxutils';

export const MOCK_API_CALL = 'MOCK_API_CALL';

export const mockApiMiddleware = () => next => action => {
  const apiCall = action[MOCK_API_CALL];

  // Ignore all non api-related actions
  if (typeof apiCall === 'undefined') {
    return next(action);
  }

  const { url, actions, meta, mockResponse, responseTime = 2500 } = apiCall;

  if (typeof url !== 'string') {
    throw new Error('Api call action does not contain url string');
  }

  if (!actions) {
    throw new Error('Api call action does not contain action group');
  }

  if (!actions.SUCCESS || !actions.ERROR || !actions.LOADING) {
    throw new Error('Api call does not contain a correct action group. ');
  }

  if (!mockResponse) {
    throw new Error('Please provide mockResponse');
  }

  // Dispatch loading action
  next(createLoadingAction(actions.LOADING, meta));

  return setTimeout(() => {
    next(createSuccessAction(actions.SUCCESS, mockResponse, meta));
  }, responseTime);
};

import { ParametersReducer } from '../types/reducers.d';
import { SET_PARAMETRIC_MODE } from '../api/actionSets';

const defaultState: ParametersReducer = {
  isLoading: false,
  parameters: null,
};

export default (state: ParametersReducer = defaultState, action) => {
  switch (action.type) {
    case SET_PARAMETRIC_MODE.LOADING:
      return {
        ...state,
        isLoading: true,
        parameters: null,
      };
    case SET_PARAMETRIC_MODE.ERROR:
      return {
        ...state,
        isLoading: false,
        loadingError: action.error,
        parameters: null,
      };
    case SET_PARAMETRIC_MODE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadingError: null,
        ...action.response,
      };
    default:
      return state;
  }
};

import * as actionSets from './actionSets';
import { createUrl, createBodyLog } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';

const controller = 'App';

const app = {
  getParameter: parametersName => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetParameterValue`),
      body: createBodyLog({ parametersName }),
      method: 'POST',
      actions: actionSets.SET_PARAMETRIC_MODE,
      authenticated: true,
    },
  }),
};

export default app;

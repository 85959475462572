import * as actionSets from './actionSets';
// eslint-disable-next-line no-unused-vars
import { createUrl } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';

const controller = 'Version';
const version = {
  getSwVersion: () => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetVersion`, {}),
      method: 'GET',
      actions: actionSets.GET_SW_VERSION,
    },
  }),
  getSystemStatus: () => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SystemStatus`, {}),
      method: 'GET',
      actions: actionSets.GET_SYSTEM_STATUS,
      authenticated: false,
    },
  }),
};

export default version;

import React from 'react';
import { color, space, borderRadius } from '@dovera/design-system';
import withStyles from '../../_common/utils/withStyles';

const styles = {
  root: {
    backgroundColor: color('secondary', 500),
    color: color('white'),
    padding: `${space('xxsmall')}px ${space('small')}px`,
    borderRadius: `${borderRadius}px`,
  },
};

interface ParentProps {
  classes: any;
}

interface OwnProps {
  message: React.ReactNode;
}

type Props = OwnProps & Partial<ParentProps>;

const FloatingAlertTemplate = ({ classes, message }: Props) => (
  <div className={classes.root}>{message}</div>
);

export const PureComponent = FloatingAlertTemplate;
export default withStyles(FloatingAlertTemplate, styles);

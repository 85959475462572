// TODO: add documentation how to import/export a new illustration
import benefityPreDarcovKrvi from '@dovera/design-system/public/illustrations/100x100/benefity-pre-darcov-krvi.svg';
import benefityPreDarcovKrviLarge from '@dovera/design-system/public/illustrations/400x312/benefity-pre-darcov-krvi.svg';
import bojovniciZaZdravie from '@dovera/design-system/public/illustrations/100x100/bojovnici-za-zdravie.svg';
import bojovniciZaZdravieLarge from '@dovera/design-system/public/illustrations/400x312/bojovnici-za-zdravie.svg';
import dialinka from '@dovera/design-system/public/illustrations/100x100/dialinka.svg';
import dialinkaLarge from '@dovera/design-system/public/illustrations/400x312/dialinka.svg';
import dodatocnaZlavaNaPortaloch from '@dovera/design-system/public/illustrations/100x100/dodatocna-zlava-na-portaloch.svg';
import dodatocnaZlavaNaPortalochLarge from '@dovera/design-system/public/illustrations/400x312/dodatocna-zlava-na-portaloch.svg';
import doveraGenio from '@dovera/design-system/public/illustrations/100x100/dovera-genio.svg';
import doveraGenioLarge from '@dovera/design-system/public/illustrations/400x312/dovera-genio.svg';
import doveraLinka24 from '@dovera/design-system/public/illustrations/100x100/dovera-linka-24.svg';
import doveraLinka24Large from '@dovera/design-system/public/illustrations/400x312/dovera-linka-24.svg';
import doveraPomahaDiabetikom from '@dovera/design-system/public/illustrations/100x100/dovera-pomaha-diabetikom.svg';
import doveraPomahaDiabetikomLarge from '@dovera/design-system/public/illustrations/400x312/dovera-pomaha-diabetikom.svg';
import elektronickaPobocka from '@dovera/design-system/public/illustrations/100x100/elektronicka-pobocka.svg';
import hrubeCrevo from '@dovera/design-system/public/illustrations/100x100/hrube-crevo.svg';
import hrubeCrevoLarge from '@dovera/design-system/public/illustrations/400x312/hrube-crevo.svg';
import interakcieLiekov from '@dovera/design-system/public/illustrations/100x100/interakcie-liekov.svg';
import interakcieLiekovLarge from '@dovera/design-system/public/illustrations/400x312/interakcie-liekov.svg';
import klby from '@dovera/design-system/public/illustrations/100x100/klby.svg';
import klbyLarge from '@dovera/design-system/public/illustrations/400x312/klby.svg';
import lekaren from '@dovera/design-system/public/illustrations/100x100/lekaren.svg';
import lekarenLarge from '@dovera/design-system/public/illustrations/400x312/lekaren.svg';
import mamografickeVysetrenie from '@dovera/design-system/public/illustrations/100x100/mamograficke-vysetrenie.svg';
import mamografickeVysetrenieLarge from '@dovera/design-system/public/illustrations/400x312/mamograficke-vysetrenie.svg';
import medzinarodnePoistenie from '@dovera/design-system/public/illustrations/100x100/medzinarodne-poistenie.svg';
import medzinarodnePoistenieLarge from '@dovera/design-system/public/illustrations/400x312/medzinarodne-poistenie.svg';
import nadstandardneIzby from '@dovera/design-system/public/illustrations/100x100/nadstandardne-izby.svg';
import nadstandardneIzbyLarge from '@dovera/design-system/public/illustrations/400x312/nadstandardne-izby.svg';
import nenajdenaStranka from '@dovera/design-system/public/illustrations/100x100/404-stranka.svg';
import nenajdenaStrankaLarge from '@dovera/design-system/public/illustrations/400x312/404-stranka.svg';
import obdarujmeNovorodenca from '@dovera/design-system/public/illustrations/100x100/obdarujme-novorodenca.svg';
import obdarujmeNovorodencaLarge from '@dovera/design-system/public/illustrations/400x312/obdarujme-novorodenca.svg';
import objednajteSaTelefonicky from '@dovera/design-system/public/illustrations/100x100/objednajte-sa-telefonicky.svg';
import objednajteSaTelefonickyLarge from '@dovera/design-system/public/illustrations/400x312/objednajte-sa-telefonicky.svg';
import onkologickaLiecba from '@dovera/design-system/public/illustrations/100x100/onkologicka-liecba.svg';
import onkologickaLiecbaLarge from '@dovera/design-system/public/illustrations/400x312/onkologicka-liecba.svg';
import poistenieGenerali from '@dovera/design-system/public/illustrations/100x100/poistenie-generali.svg';
import poistenieGeneraliLarge from '@dovera/design-system/public/illustrations/400x312/poistenie-generali.svg';
import poistiteBabatko from '@dovera/design-system/public/illustrations/100x100/poistite-babatko.svg';
import poistiteBabatkoLarge from '@dovera/design-system/public/illustrations/400x312/poistite-babatko.svg';
import preplacanie from '@dovera/design-system/public/illustrations/100x100/preplacanie.svg';
import preplacanieLarge from '@dovera/design-system/public/illustrations/400x312/preplacanie.svg';
import priplacanieZaOckovanie from '@dovera/design-system/public/illustrations/100x100/priplacanie-za-ockovanie.svg';
import priplacanieZaOckovanieLarge from '@dovera/design-system/public/illustrations/400x312/priplacanie-za-ockovanie.svg';
import sikovnaAplikacia from '@dovera/design-system/public/illustrations/100x100/sikovna-aplikacia.svg';
import sikovnaAplikaciaLarge from '@dovera/design-system/public/illustrations/400x312/sikovna-aplikacia.svg';
import sluzbaVyhodneLieky from '@dovera/design-system/public/illustrations/100x100/sluzba-vyhodne-lieky.svg';
import sluzbaVyhodneLiekyLarge from '@dovera/design-system/public/illustrations/400x312/sluzba-vyhodne-lieky.svg';
import sosKarta from '@dovera/design-system/public/illustrations/100x100/sos-karta.svg';
import sosKartaLarge from '@dovera/design-system/public/illustrations/400x312/sos-karta.svg';
import sprievodcaRodica from '@dovera/design-system/public/illustrations/100x100/sprievodca-rodica.svg';
import sprievodcaRodicaLarge from '@dovera/design-system/public/illustrations/400x312/sprievodca-rodica.svg';
import starameSa from '@dovera/design-system/public/illustrations/100x100/starame-sa.svg';
import starameSaLarge from '@dovera/design-system/public/illustrations/400x312/starame-sa.svg';
import vratenieDoplatkovZaLieky from '@dovera/design-system/public/illustrations/100x100/vratenie-doplatkov-za-lieky.svg';
import vseobecna from '@dovera/design-system/public/illustrations/100x100/vseobecna.svg';
import vseobecnaLarge from '@dovera/design-system/public/illustrations/400x312/vseobecna.svg';
import zaplatiliSmeZaStarostlivost from '@dovera/design-system/public/illustrations/100x100/zaplatili-sme-za-starostlivost.svg';
import zaplatiliSmeZaStarostlivostLarge from '@dovera/design-system/public/illustrations/400x312/zaplatili-sme-za-starostlivost.svg';
// import zdraveZuby from '@dovera/design-system/public/illustrations/100x100/zdrave-zuby.svg';
// import zdraveZubyLarge from '@dovera/design-system/public/illustrations/400x312/zdrave-zuby.svg';
import zlavaBepon from '@dovera/design-system/public/illustrations/100x100/zlava-bepon.svg';
import zlavaBeponLarge from '@dovera/design-system/public/illustrations/400x312/zlava-bepon.svg';
import zlavaVKupeloch from '@dovera/design-system/public/illustrations/100x100/zlava-v-kupeloch.svg';
import zlavaVKupelochLarge from '@dovera/design-system/public/illustrations/400x312/zlava-v-kupeloch.svg';
import zlavaVKupelochPreDarcov from '@dovera/design-system/public/illustrations/100x100/zlava-v-kupeloch-pre-darcov-krvi.svg';
import zlavaVKupelochPreDarcovLarge from '@dovera/design-system/public/illustrations/400x312/zlava-v-kupeloch-pre-darcov-krvi.svg';
import mobil from '@dovera/design-system/public/illustrations/100x100/mobil.svg';
import mobilLarge from '@dovera/design-system/public/illustrations/400x312/mobil.svg';
import pacient from '@dovera/design-system/public/illustrations/100x100/pacient.svg';
import pacientLarge from '@dovera/design-system/public/illustrations/400x312/pacient.svg';
// import cytologiaLarge from '@dovera/design-system/public/illustrations/400x312/cytologicke-vysetrenie-LBC.svg';
// import cytologia from '@dovera/design-system/public/illustrations/100x100/cytologicke-vysetrenie-LBC.svg';
//
import zmluvySLekarmi from '@dovera/design-system/public/illustrations/100x100/zmluvy-s-lekarmi.svg';
import zmluvySLekarmiLarge from '@dovera/design-system/public/illustrations/400x312/zmluvy-s-lekarmi.svg';

import babatko from '../../assets/illustrations/babatko.svg';
import cardBenefit from '../../assets/illustrations/card-benefit.svg';
import cardRegistration from '../../assets/illustrations/card-registration.svg';
//  @ts-ignore
import cardDohody from '../../assets/illustrations/card-dohody.jpg';
//  @ts-ignore
import cardVyplatnePasky from '../../assets/illustrations/card-vyplatne-pasky.jpg';
import idBackSide from '../../assets/illustrations/id-back-side.svg';
import idError from '../../assets/illustrations/id-error.svg';
import idFrontSide from '../../assets/illustrations/id-front-side.svg';
import idSuccess from '../../assets/illustrations/id-success.svg';
import mlady from '../../assets/illustrations/mlady.svg';
import rodicia from '../../assets/illustrations/rodicia.svg';
import senior from '../../assets/illustrations/senior.svg';
import terminationCourier from '../../assets/illustrations/termination-courier.svg';
import terminationPrinter from '../../assets/illustrations/termination-printer.svg';
import terminationPrinterDone from '../../assets/illustrations/termination-printer-done.svg';
import terminationSignatureError from '../../assets/illustrations/termination-signature-error.svg';
import terminationSignatureSuccess from '../../assets/illustrations/termination-signature-success.svg';
import terminationContractSuccess from '../../assets/illustrations/termination-contract-success.svg';
import zdraveZuby from '../../assets/illustrations/zdrave-zuby-100x100.svg';
import zdraveZubyLarge from '../../assets/illustrations/zdrave-zuby-400x312.svg';
import prekonanyCovid from '../../assets/illustrations/prekonany-covid-100x100.svg';
import prekonanyCovidLarge from '../../assets/illustrations/prekonany-covid-400x312.svg';
import dnaAnalyza from '../../assets/illustrations/dna-analyza-100x100.svg';
import dnaAnalyzaLarge from '../../assets/illustrations/dna-analyza-400x312.svg';
import protilatkyCovid from '../../assets/illustrations/protilatky-covid-19-100x100.svg';
import protilatkyCovidLarge from '../../assets/illustrations/protilatky-covid-19-400x312.svg';
import ocnyBenefit from '../../assets/illustrations/ocny-benefit-100x100.svg';
import ocnyBenefitLarge from '../../assets/illustrations/ocny-benefit-400x312.svg';
import kocikBenefit from '../../assets/illustrations/kocik-benefit-100x100.svg';
import kocikBenefitLarge from '../../assets/illustrations/kocik-benefit-400x312.svg';
import slovenskaKozmetika from '../../assets/illustrations/slovenska-kozmetika-100x100.svg';
import slovenskaKozmetikaLarge from '../../assets/illustrations/slovenska-kozmetika-400x312.svg';
import janyLandl from '../../assets/illustrations/jany-landl-100x100.svg';
import janyLandlLarge from '../../assets/illustrations/jany-landl-400x312.svg';
import pestroazdravsie from '../../assets/illustrations/pestroazdravsie-100x100.svg';
import pestroazdravsieLarge from '../../assets/illustrations/pestroazdravsie-400x312.svg';
import vjem from '../../assets/illustrations/vjem-100x100.svg';
import vjemLarge from '../../assets/illustrations/vjem-400x312.svg';
import vitacelia from '../../assets/illustrations/vitacelia-100x100.svg';
import vitaceliaLarge from '../../assets/illustrations/vitacelia-400x312.svg';
import bioPotraviny from '../../assets/illustrations/slovenske-bobule-100x100.svg';
import bioPotravinyLarge from '../../assets/illustrations/slovenske-bobule-400x312.svg';
import drMaxZlavy from '../../assets/illustrations/drmax-zlavy-100x100.svg';
import drMaxZlavyLarge from '../../assets/illustrations/drmax-zlavy-400x312.svg';
import genetickyTest from '../../assets/illustrations/geneticky-test-100x100.svg';
import genetickyTestLarge from '../../assets/illustrations/geneticky-test-400x312.svg';
import laboratornyTest from '../../assets/illustrations/laboratorny-test-100x100.svg';
import laboratornyTestLarge from '../../assets/illustrations/laboratorny-test-400x312.svg';
import kupeleTurcianskeTeplice from '../../assets/illustrations/kupele-turcianske-teplice-100x100.svg';
import kupeleTurcianskeTepliceLarge from '../../assets/illustrations/kupele-turcianske-teplice-400x312.svg';
import CRPVysetrenie from '../../assets/illustrations/benefit-crp-vysetrenie-256x256.svg';

import emailOfferResultError from '../../assets/illustrations/email-offer-result-error.svg';
import emailOfferResultSuccess from '../../assets/illustrations/email-offer-result-success.svg';

import ocrKid from '../../assets/illustrations/op-dieta.svg';
import ocrKidSuccess from '../../assets/illustrations/op-dieta-uspech.svg';
import ocrKidFailure from '../../assets/illustrations/op-dieta-chyba.svg';
import ocrKidInProgress from '../../assets/illustrations/op-dieta-process.svg';
import ocrKidCopied from '../../assets/illustrations/op-dieta-copied.svg';

import ocrAdult from '../../assets/illustrations/op-dospely.svg';
import ocrAdultSuccess from '../../assets/illustrations/op-dospely-uspech.svg';
import ocrAdultFailure from '../../assets/illustrations/op-dospely-chyba.svg';
import ocrAdultInProgress from '../../assets/illustrations/op-dospely-process.svg';
import ocrAdultCopied from '../../assets/illustrations/op-dospely-copied.svg';

import ocrSkip from '../../assets/illustrations/ocr-preskocene.svg';
import ocrSuccess from '../../assets/illustrations/ocr-uspech.svg';
import ocrFailure from '../../assets/illustrations/ocr-zlyhanie.svg';
import ocrInProcess from '../../assets/illustrations/ocr-process.svg';
import ocrCopied from '../../assets/illustrations/ocr-copied.svg';

import preplacanieProdukcia from '../../assets/illustrations/preplacanie.svg';

import leaf from '../../assets/illustrations/leaf.svg';
import bee from '../../assets/illustrations/bee.svg';
import behanieLarge from '../../assets/illustrations/behanie-400.svg';
import behanie from '../../assets/illustrations/behanie.svg';
import covidImunitaLarge from '../../assets/illustrations/covid-imunita-400.svg';
import covidImunita from '../../assets/illustrations/covid-imunita.svg';
import drmax25Large from '../../assets/illustrations/drmax25-400.svg';
import drmax25 from '../../assets/illustrations/drmax25.svg';
import drmaxPampersLarge from '../../assets/illustrations/drmax-pampers-bepanthen-400x312.svg';
import drmaxPampers from '../../assets/illustrations/drmax-pampers-bepanthen-100x100.svg';
import drmaxBebeloLarge from '../../assets/illustrations/drmax-bebelo-400x312.svg';
import drmaxBebelo from '../../assets/illustrations/drmax-bebelo-100x100.svg';
import planeatLarge from '../../assets/illustrations/planeat-400x312.svg';
import planeat from '../../assets/illustrations/planeat-100x100.svg';

import scanOPFront from '../../assets/illustrations/scan-op-front.svg';
import scanOPBack from '../../assets/illustrations/scan-op-back.svg';
import scanOPAdd from '../../assets/illustrations/scan-op-add.svg';
import scanOPDone from '../../assets/illustrations/scan-op-done.svg';

import ocrPhotoTaken from '../../assets/illustrations/ocr-contract-success.svg';

// feedo.sk
import babatkoVybavickaLarge from '../../assets/illustrations/nakup-prvej-vybavicky-400x312.svg';
import babatkoVybavicka from '../../assets/illustrations/nakup-prvej-vybavicky-100x100.svg';
import vyzivaPlasmonLarge from '../../assets/illustrations/oblecenie-vyziva-400x312.svg';
import vyzivaPlasmon from '../../assets/illustrations/oblecenie-vyziva-100x100.svg';
import kocikAutosedackyLarge from '../../assets/illustrations/nakup-sportoveho-kocika-400x312.svg';
import kocikAutosedacky from '../../assets/illustrations/nakup-sportoveho-kocika-100x100.svg';

// agel
import bezplatnaPritomnostBlizkehoLarge from '../../assets/illustrations/bezplatna-pritomnost-blizkeho-400x312.svg';
import bezplatnaPritomnostBlizkeho from '../../assets/illustrations/bezplatna-pritomnost-blizkeho-100x100.svg';
// Zľavy v optikách Fokus
import fokusOptika from '../../assets/illustrations/benefit-optika-fokus-256x256.svg';
// tv balance
import tvBalanceLarge from '../../assets/illustrations/tv-balance-400x312.svg';
import tvBalance from '../../assets/illustrations/tv-balance-100x100.svg';
// zlava raabe
import raabeLarge from '../../assets/illustrations/zlava-kniha-400.svg';
import raabe from '../../assets/illustrations/zlava-kniha.svg';
// zlava psycholog
import psychologLarge from '../../assets/illustrations/zlava-psycholog-400x312.svg';
import psycholog from '../../assets/illustrations/zlava-psycholog-100x100.svg';
// kozne vysetrenie
import kozneVysetrenie from '../../assets/illustrations/benefit-kozne-vysetrenie-256x256.svg';
// Nástroj na samodiagnostiku
import samodiagnostiku from '../../assets/illustrations/benefit-samodiagnostika-256x256.svg';
// Nemocnica novej generácie Bory
import nemocnicaNovejGeneracieBory from '../../assets/illustrations/benefit-nemocnica-bory-256x256.svg';
// Cytologicke vysetrenie
import cytologickeVysetrenieLBC from '../../assets/illustrations/benefit-cytologicke-vysetrenie-LBC-256x256.svg';
// Skrining SMA u novorodencov
import skriningSMALarge from '../../assets/illustrations/skrining-SMA-u-novorodencov-400x312.svg';
import skriningSMA from '../../assets/illustrations/skrining-SMA-u-novorodencov-100x100.svg';
// Bory doprava
import boryDoprava from '../../assets/illustrations/benefit-doprava-bory-256x256.svg';
// Zľavy až do 50 % u našich partnerov
import zlavaDo50 from '../../assets/illustrations/benefit-zlava-do-50-256x256.svg';
// 300 € za lieky
import zaLieky from '../../assets/illustrations/benefit-300-eur-lieky-256x256.svg';
// Príspevok na dobrovoľné očkovania
import prispevokOckovanie from '../../assets/illustrations/benefit-priplacanie-na-ockovanie-256x256.svg';
// Mobilná aplikácia
import mobilnaApp from '../../assets/illustrations/benefit-mobilna-aplikacia-256x256.svg';
// Elektronická pobočka
import elektronickaPobockaBenefit from '../../assets/illustrations/benefit-elektronicka-pobocka-256x256.svg';
// Linka pre zdravie
import linkaPreZdravie from '../../assets/illustrations/benefit-linka-pre-zdravie-256x256.svg';
// Linka pre zdravie
import VIPIzba from '../../assets/illustrations/benefit-vip-izba-256x256.svg';
import pritomnostBlizkeho from '../../assets/illustrations/benefit-bezplatna-pritomnost-blizkeho-256x256.svg';
import objednanieKLekarovi from '../../assets/illustrations/benefit-objednanie-k-lekarovi-256x256.svg';
import pomahameCukrovka from '../../assets/illustrations/benefit-pomahame-s-cukrovkou-256x256.svg';
import cvikyPreDiabetikov from '../../assets/illustrations/benefit-cviky-pre-diabetikov-256x256.svg';

import pomahameSNadvahou from '../../assets/illustrations/benefit-pomahame-s-nadvahou-256x256.svg';
import pomahameSoSrdcom from '../../assets/illustrations/benefit-pomahame-so-srdcom-256x256.svg';
import zmluvaLekari from '../../assets/illustrations/benefit-zmluvy-90-lekarov-256x256.svg';
import bojovniciZdravie from '../../assets/illustrations/benefit-bojovnici_za_zdravie-256x256.svg';
import darcaKrvi from '../../assets/illustrations/benefit-darca-krvy-256x256.svg';

import vymenaKlbu from '../../assets/illustrations/benefit-vymena-klbu-256x256.svg';
import drMax from '../../assets/illustrations/benefit-drmax-256-256.svg';
import mamografia from '../../assets/illustrations/benefit-mamograficke-256x256.svg';
import dostupnostLiekov from '../../assets/illustrations/benefit-dostupnost-liekov-256x256.svg';
import festivalZdravia from '../../assets/illustrations/benefit-festival-zdravia-256x256.svg';
import hrubeCrevoBenefit from '../../assets/illustrations/benefit-25-hrube-crevo-256x256.svg';
import usetriteNaLiekoch from '../../assets/illustrations/benefit-usetrite-na-liekoch-256x256.svg';
import interakciaLiekov from '../../assets/illustrations/benefit-interakcie-liekov-256x256.svg';
import psychologickePoradenstvo from '../../assets/illustrations/zlava-psychologicke-poradenstvo-256x256.svg';
import mobilnaAppVOS from '../../assets/illustrations/benefit-mobilna-app-vos-256x256.svg';

// ZLAVY
import doplnkyVoxberg from '../../assets/illustrations/zlava-20-sportova-vyziva-voxberg-256x256.svg';
import testyUnilabs from '../../assets/illustrations/zlava-20-testy-unilab-256x256.svg';
import knihaOStravovani from '../../assets/illustrations/zlava-15-kniha-o-stravovani-256x256.svg';
import doplnkyDNA4FIT from '../../assets/illustrations/zlava-25-doplnky-dna4fit-256x256.svg';
import DNATesty from '../../assets/illustrations/zlava-800-dna-testy-256x256.svg';
import cestovnePoistenie from '../../assets/illustrations/zlava-30-cestovne-poistenie-generali-256x256.svg';
import dovolenka from '../../assets/illustrations/zlava-dovolenka-256x256.svg';
import jedalnicek from '../../assets/illustrations/zlava-40-na-jedalnicek-256x256.svg';
import treningovyProgram from '../../assets/illustrations/zlava-30-treningovy-program-256x256.svg';
import vitaceliaBezlepkovePotraviny from '../../assets/illustrations/zlava-25-vitacelia-256x256.svg';
import zlavaDNA from '../../assets/illustrations/zlava-5-zlava-dna-256x256.svg';
import biopotraviny from '../../assets/illustrations/zlava-30-biopotraviny-256x256.svg';
import facedermaKozmetika from '../../assets/illustrations/zlava-30-facederma-256x256.svg';
import lacnejsieKupele from '../../assets/illustrations/zlava-20-kupele-256x256.svg';
import kupelePobyt from '../../assets/illustrations/zlava-20-pobyt-kupele-256x256.svg';
import proCarePoliklinika from '../../assets/illustrations/zlava-procare-poliklinika-256x256.svg';
import kucharskaKniha from '../../assets/illustrations/zlava-30-kucharska-kniha-256x256.svg';
import nezmluvnyLekar from '../../assets/illustrations/zlava-80-nezmluvny-lekar-256x256.svg';
import laktacnePoradenstvo from '../../assets/illustrations/zlava-laktacne-poradenstvo-256x256.svg';
import vybavaBabatko from '../../assets/illustrations/zlava-15-vybava-babatko-256x256.svg';
import detskeOblecenie from '../../assets/illustrations/zlava-15-detske-oblecenie-256x256.svg';
import filterNaKocik from '../../assets/illustrations/zlava-filter-na-kocik-256x256.svg';
import nakupKocik from '../../assets/illustrations/zlava-15-nakup-kocika-256x256.svg';
import babyBox from '../../assets/illustrations/zlava-70-baby-box-256x256.svg';

export default {
  doplnkyVoxberg,
  testyUnilabs,
  knihaOStravovani,
  doplnkyDNA4FIT,
  DNATesty,
  cestovnePoistenie,
  dovolenka,
  jedalnicek,
  treningovyProgram,
  vitaceliaBezlepkovePotraviny,
  zlavaDNA,
  biopotraviny,
  facedermaKozmetika,
  lacnejsieKupele,
  kupelePobyt,
  proCarePoliklinika,
  kucharskaKniha,
  nezmluvnyLekar,
  laktacnePoradenstvo,
  vybavaBabatko,
  detskeOblecenie,
  filterNaKocik,
  nakupKocik,
  babyBox,
  vymenaKlbu,
  drMax,
  mamografia,
  dostupnostLiekov,
  festivalZdravia,
  hrubeCrevoBenefit,
  usetriteNaLiekoch,
  interakciaLiekov,
  psychologickePoradenstvo,
  mobilnaAppVOS,
  fokusOptika,
  babatko,
  benefityPreDarcovKrvi,
  benefityPreDarcovKrviLarge,
  bezplatnaPritomnostBlizkeho,
  bezplatnaPritomnostBlizkehoLarge,
  bioPotraviny,
  bioPotravinyLarge,
  bojovniciZaZdravie,
  bojovniciZaZdravieLarge,
  cardBenefit,
  cardRegistration,
  cardDohody,
  cardVyplatnePasky,
  dialinka,
  dialinkaLarge,
  dodatocnaZlavaNaPortaloch,
  dodatocnaZlavaNaPortalochLarge,
  doveraGenio,
  doveraGenioLarge,
  doveraLinka24,
  doveraLinka24Large,
  doveraPomahaDiabetikom,
  doveraPomahaDiabetikomLarge,
  elektronickaPobocka,
  emailOfferResultError,
  emailOfferResultSuccess,
  hrubeCrevo,
  hrubeCrevoLarge,
  idBackSide,
  idError,
  idFrontSide,
  idSuccess,
  interakcieLiekov,
  interakcieLiekovLarge,
  janyLandl,
  janyLandlLarge,
  klby,
  klbyLarge,
  kocikBenefit,
  kocikBenefitLarge,
  kupeleTurcianskeTeplice,
  kupeleTurcianskeTepliceLarge,
  genetickyTest,
  genetickyTestLarge,
  laboratornyTest,
  laboratornyTestLarge,
  drMaxZlavy,
  drMaxZlavyLarge,
  lekaren,
  lekarenLarge,
  mamografickeVysetrenie,
  mamografickeVysetrenieLarge,
  medzinarodnePoistenie,
  medzinarodnePoistenieLarge,
  mlady,
  nadstandardneIzby,
  nadstandardneIzbyLarge,
  nenajdenaStranka,
  nenajdenaStrankaLarge,
  obdarujmeNovorodenca,
  obdarujmeNovorodencaLarge,
  objednajteSaTelefonicky,
  objednajteSaTelefonickyLarge,
  ocnyBenefit,
  ocnyBenefitLarge,
  ocrAdult,
  ocrAdultFailure,
  ocrAdultSuccess,
  ocrAdultInProgress,
  ocrAdultCopied,
  ocrFailure,
  ocrInProcess,
  ocrSuccess,
  ocrSkip,
  ocrCopied,
  ocrKid,
  ocrKidFailure,
  ocrKidSuccess,
  ocrKidInProgress,
  ocrKidCopied,
  ocrPhotoTaken,
  onkologickaLiecba,
  onkologickaLiecbaLarge,
  planeat,
  planeatLarge,
  poistenieGenerali,
  poistenieGeneraliLarge,
  poistiteBabatko,
  poistiteBabatkoLarge,
  prekonanyCovid,
  prekonanyCovidLarge,
  preplacanie,
  preplacanieLarge,
  priplacanieZaOckovanie,
  priplacanieZaOckovanieLarge,
  rodicia,
  senior,
  sikovnaAplikacia,
  sikovnaAplikaciaLarge,
  slovenskaKozmetika,
  slovenskaKozmetikaLarge,
  sluzbaVyhodneLieky,
  sluzbaVyhodneLiekyLarge,
  sosKarta,
  sosKartaLarge,
  sprievodcaRodica,
  sprievodcaRodicaLarge,
  starameSa,
  starameSaLarge,
  terminationCourier,
  terminationPrinter,
  terminationPrinterDone,
  terminationSignatureError,
  terminationSignatureSuccess,
  terminationContractSuccess,
  vjem,
  vjemLarge,
  vitacelia,
  vitaceliaLarge,
  vratenieDoplatkovZaLieky,
  vseobecna,
  vseobecnaLarge,
  zaplatiliSmeZaStarostlivost,
  zaplatiliSmeZaStarostlivostLarge,
  zdraveZuby,
  zdraveZubyLarge,
  zlavaBepon,
  zlavaBeponLarge,
  zlavaVKupeloch,
  zlavaVKupelochLarge,
  zmluvySLekarmi,
  zmluvySLekarmiLarge,
  preplacanieProdukcia,
  bee,
  leaf,
  behanie,
  behanieLarge,
  covidImunita,
  covidImunitaLarge,
  dnaAnalyza,
  dnaAnalyzaLarge,
  pestroazdravsie,
  pestroazdravsieLarge,
  protilatkyCovid,
  protilatkyCovidLarge,
  drmax25,
  drmax25Large,
  drmaxBebelo,
  drmaxBebeloLarge,
  drmaxPampers,
  drmaxPampersLarge,
  scanOPFront,
  scanOPBack,
  scanOPAdd,
  scanOPDone,
  babatkoVybavickaLarge,
  babatkoVybavicka,
  vyzivaPlasmonLarge,
  vyzivaPlasmon,
  kocikAutosedackyLarge,
  kocikAutosedacky,
  CRPVysetrenie,
  tvBalanceLarge,
  tvBalance,
  raabeLarge,
  raabe,
  zlavaVKupelochPreDarcov,
  zlavaVKupelochPreDarcovLarge,
  mobil,
  mobilLarge,
  pacient,
  pacientLarge,
  psycholog,
  psychologLarge,
  kozneVysetrenie,
  samodiagnostiku,
  nemocnicaNovejGeneracieBory,
  cytologickeVysetrenieLBC,
  skriningSMA,
  skriningSMALarge,
  boryDoprava,
  zlavaDo50,
  zaLieky,
  prispevokOckovanie,
  mobilnaApp,
  linkaPreZdravie,
  elektronickaPobockaBenefit,
  VIPIzba,
  pritomnostBlizkeho,
  objednanieKLekarovi,
  pomahameCukrovka,
  cvikyPreDiabetikov,
  pomahameSNadvahou,
  pomahameSoSrdcom,
  zmluvaLekari,
  bojovniciZdravie,
  darcaKrvi,
};

export default {
  title: 'Zmluvní lekári Dôvery',
  description:
    'Zmluvu s Dôverou má až 90% lekárov na Slovensku. Preverte si, či je medzi nimi aj ten váš.',
  inputLabel: 'Meno lekára',
  addonButton: 'Preveriť',
  detailSearch: 'Vyhľadávanie zmluvných poskytovateľov',
  preplacanie: {
    title: 'Vrátime vám 80% za ošetrenie u nezmluvného lekára',
    description:
      'Potrebujete navštíviť lekára, ktorý s nami nemá zmluvu? Netrápte sa,vrátime vám až 80% z obvyklej ceny za príslušnú zdravotnú starostlivosť u nezmluvného lekára.',
  },
};

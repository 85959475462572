import * as actionSets from './actionSets';
import { createUrl, createBodyLog } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';

const controller = 'Validation';
const validation = {
  validateBirthNumber: (insureeType, birthNumber, registrationFor?) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/BirthNumber`),
      body: createBodyLog({ birthNumber, registrationFor }),
      method: 'POST',
      actions: actionSets.VALIDATE_BIRTH_NUMBER,
      meta: { insureeType },
    },
  }),
  validateContractBirthNumber: birthNumber => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ContractBirthNumber`),
      body: createBodyLog(birthNumber),
      method: 'POST',
      actions: actionSets.VALIDATE_CONTRACT_BIRTH_NUMBER,
    },
  }),
  validateEmployeeNumber: employeeNumber => ({
    [API_CALL]: {
      url: createUrl(`${controller}/EmployeerInternalNumber`),
      body: createBodyLog({ employeeNumber }),
      method: 'POST',
      actions: actionSets.VALIDATE_EMPLOYEE_NUMBER,
    },
  }),
  validInsureeRelation: birthNumber => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ValidInsureeRelation`),
      body: createBodyLog({ birthNumber }),
      method: 'POST',
      actions: actionSets.VALIDATE_INSUREE_RELATION,
    },
  }),
};

export default validation;

export default {
  required: 'Povinná položka.',
  seletedIssue: 'Prosím, vyberte jednu z ponúkaných možností.',
  rc: 'Rodné číslo nie je správne, zadajte ho znovu.',
  rcFormat: 'Rodné číslo je potrebné vyplniť bez lomítka.',
  rcOnlyNumbers: 'Rodné číslo može obsahovať len čísla.',
  bicOnlyNumbers: 'BIČ može obsahovať len čísla.',
  bic: 'BIČ nie je správne, zadajte ho znovu.',
  personIsNotChild: 'Poistenec nie je dieťa.',
  personIsNotNewBorn: 'Prosím, zadajte rodné číslo novorodenec.',
  personIsNewBorn: 'Prosím, zadajte rodné číslo osoby staršej ako 60 dní.',
  personIsNotAdult: 'Prosím, zadajte rodné číslo dospelej osoby.',
  forbiddenChars: 'Nepovolené znaky',
  datesNotSame: 'Rodné číslo a dátum narodenia nie je rovnaký dátum',
  phone: 'Nesprávny formát telefónneho čísla',
  email: 'Nesprávny formát emailu',
  ico: 'Nesprávny formát IČO',
  ibanFormat: 'Nesprávny formát. Zadajte platný slovenský IBAN.',
  invalidFormatVerifyCode: 'Nesprávny formát identifikačného kódu',
  invalidLengthVerifyCode: 'Zadajte 6 miestny identifikačný kód',
  invalidVerifyCode: 'Zadali ste nesprávny kód',
  invalidContact: 'Tento kontakt nie je možné použiť.',
  requiredPhone:
    'Na to, aby k vám mohol prísť kuriér s prihláškou potrebujeme vaše telefónne číslo',
  idNumber: 'Nesprávne číslo dokladu',
  file: {
    invalidType: 'Nesprávny formát súboru. Povolené formáty:',
    tooLarge: 'Súbor je príliš veľký. Maximálna veľkost súboru:',
    tooSmall: 'Súbor je príliš malý. Mninimálna veľkost súboru:',
    manyFiles: 'Príliš veľa súbor.',
  },
  login:
    'Zadané prihlasovacie meno je v nesprávnom formáte. Prihlasovacie meno musí obsahovať minimálne 6 a maximálne 15 alfanumerických znakov bez diakritiky. Môže obsahovať bodku, podtržník, pomlčku a @.',
  capsLock: 'Ak chcete použiť malé písmená, vypnite si Caps Lock.',
  passwordsNotSame: 'Hesla nie sú rovnaké',
  passwordBadFormat: 'Nesprávny formát hesla.',
  passwordSameAsOld:
    'Nové heslo je rovnaké ako pôvodné heslo. Prosím, zvoľte iné heslo.',
  wrongImageFormat: 'Súbor musí byť vo formáte .jpg, .jpeg alebo .png',
  wrongAttachmentFormat: value => `Súbor musí byť vo formáte ${value}`,
  wrongAttachmentSize: value => `Súbor musí byť menší ako ${value}`,
  wrongFormat: (value?: string) =>
    value
      ? `Zadali ste ${value} v nepodporovanom formáte`
      : 'Zadali ste nesprávny formát',
  unknown: 'Nastala neočakávaná chyba',
  ocr: {
    academicDegree: 'Prosím, vyberte jednu z ponúkaných možností.',
  },
};

export default {
  title: 'Nepamätám si prihlasovacie údaje',
  description:
    'Zmenili ste mail alebo číslo? Aby ste sa mohli bezpečne prihlásiť do účtu, kontaktujte, prosím, svojho nadriadeného.',
  button: 'Ok, rozumiem',
  employeeNumber: {
    label: 'Kód zamestnanca',
  },
  email: {
    label: 'Email uvedený v Dohode o vykonaní práce',
  },
  success: {
    title: 'Obnova prihlasovacích údajov bola úspešná',
    description:
      'Teraz sa prihláste novými prihlasovacími údajmi. Následne si ich zmeňte v sekcii Profil. V prípade problémov kontaktujte svojho account managera.',
    button: 'Pokračovať na prihlásenie',
  },
  error: {
    title: 'Obnova prihlasovacích údajov sa nepodarila',
    description: 'Prosím kontaktujte svojho account managera.',
    button: 'Späť na prihlásenie',
  },
  repeat: {
    title:
      'Ľutujeme, prekročili ste maximálny počet pokusov o generovanie prihlasovacích údajov',
    description: 'Prosím kontaktujte svojho account managera.',
    button: 'Späť na prihlásenie',
  },
};

import { VersionReducer } from '../types/reducers.d';
import { GET_SW_VERSION, GET_SYSTEM_STATUS } from '../api/actionSets';

const defaultState: VersionReducer = {
  isLoading: false,
  swBack: { version: null },
  isLoadingStatus: false,
  isOnline: true,
};

export default (state: VersionReducer = defaultState, action) => {
  switch (action.type) {
    case GET_SW_VERSION.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SW_VERSION.ERROR:
      return {
        ...state,
        isLoading: false,
        loadingError: action.error,
      };
    case GET_SW_VERSION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadingError: null,
        swBack: {
          ...action.response,
        },
      };
    case GET_SYSTEM_STATUS.LOADING:
      return {
        ...state,
        isLoadingStatus: true,
      };
    case GET_SYSTEM_STATUS.ERROR:
      return {
        ...state,
        isLoadingStatus: false,
      };
    case GET_SYSTEM_STATUS.SUCCESS:
      return {
        ...state,
        isLoadingStatus: false,
        ...action.response,
      };

    default:
      return state;
  }
};

import { contentSpacing, borderRadius, space } from '@dovera/design-system';
import { colors, color } from './colors';
import {
  TIME_PICKER_PATTERN,
  VALID_DATE,
  TOAST_CONTAINER_OPTIONS,
  HEADER_HEIGHT,
  TABBAR_HEIGHT,
  PAGE_ITEMS_SIZE,
  SYSTEM_TIMESTAMP,
  TYPE_WEIGHT,
  TYPE_LENGTH,
  emojiSet,
  emojiSetFaces,
  SIGNATUS_VIEW,
  CONTACTS_URL,
  COOKIE_GLOBAL_TYPE,
} from './misc';

export {
  colors,
  color,
  contentSpacing,
  borderRadius,
  space,
  TIME_PICKER_PATTERN,
  VALID_DATE,
  TOAST_CONTAINER_OPTIONS,
  HEADER_HEIGHT,
  TABBAR_HEIGHT,
  PAGE_ITEMS_SIZE,
  SYSTEM_TIMESTAMP,
  TYPE_WEIGHT,
  TYPE_LENGTH,
  emojiSet,
  emojiSetFaces,
  SIGNATUS_VIEW,
  CONTACTS_URL,
  COOKIE_GLOBAL_TYPE,
};

export const slovakStateCode = 'SK';
export const slovakStateName = 'SLOVENSKÁ REPUBLIKA';

export const POVINNY_ZAMESTNAVATEL = 16;

export const MAINTANANCE_NOTIFICATION_BEFORE = {
  from: '2020-09-24T00:00:00.000Z',
  to: '2020-09-30T04:00:00.000Z',
};
export const MAINTANANCE_NOTIFICATION = {
  from: '2020-09-30T04:00:00.000Z',
  to: '2020-10-01T00:00:00.000Z',
};
export const MAINTANANCE = {
  from: '2020-09-30T04:00:00.000Z',
  to: '2020-10-01T00:00:00.000Z',
};

export const THANKS_FOR_CAMPAIGN = {
  from: '2020-10-01T00:00:00.000Z',
  to: '2020-10-16T23:59:59.000Z',
};

export const PROHIBITED_NEWBORN = '2022-08-01T00:00:00.000Z';
export const PROHIBITED_REGISTRATION = {
  from: '2022-10-01T00:00:00.000Z',
  to: '2023-01-16T23:59:59.000Z',
};

export default {
  title: 'Vitajte!',
  description: 'Aby ste mohli pracovať s aplikáciou VIA, musíte sa prihlásiť',
  buttonLoginIn: 'Prihlásiť sa',
  buttonForgotPassword: 'Nepamätám si prihlasovacie údaje',
  registrationInfoBox: {
    description: 'Ste tu prvýkrát a chcete sa zaregistrovať?',
    button: 'Zaregistrovať sa',
  },
};

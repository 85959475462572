export const SET_PERSON = 'SET_PERSON';
export const SET_PURPOSE = 'SET_PURPOSE';
export const SET_STEP = 'SET_STEP';
export const SET_COURIER_ADDRESS = 'SET_COURIER_ADDRESS';
export const INCOMPLETE_STEP = 'INCOMPLETE_STEP';
export const COMPLETE_STEP = 'COMPLETE_STEP';
export const SET_PERSON_DATA = 'SET_PERSON_DATA';
export const SET_INSURANCE_COMPANY = 'SET_INSURANCE_COMPANY';
export const SET_OCR_STATUS = 'SET_OCR_STATUS';
export const SET_CONSENT = 'SET_CONSENT';

export const setPerson = person => ({
  type: SET_PERSON,
  person,
});

export const setPurpose = purpose => ({
  type: SET_PURPOSE,
  purpose,
});

export const setStep = (step: number = 1, data: any) => ({
  type: `SET_STEP`,
  step,
  data,
});

export const setPersonData = (personType: string = 'insuree', data: any) => ({
  type: `SET_PERSON_DATA`,
  personType,
  data,
});

export const setCourierAddress = address => ({
  type: SET_COURIER_ADDRESS,
  address,
});

export const incompleteStep = index => ({
  type: INCOMPLETE_STEP,
  step: parseInt(index, 10) + 1,
});

export const completeStep = index => ({
  type: COMPLETE_STEP,
  step: parseInt(index, 10) + 1,
});

export const setInsuranceCompany = company => ({
  type: SET_INSURANCE_COMPANY,
  company,
});

export const setOCRStatus = (insureeType, status) => ({
  type: SET_OCR_STATUS,
  status,
  insureeType,
});

export const setConsent = (key, value) => ({
  type: SET_CONSENT,
  key,
  value,
});

import { applyMiddleware, createStore } from 'redux';
import rootReducer from '../reducers/root-reducer';
import createMiddleware from '../middleware/createMiddleware';

export const configureStore = () => {
  const middleware = createMiddleware();
  return createStore(rootReducer, applyMiddleware(...middleware));
};

export const defaultStore = configureStore();

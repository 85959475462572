/* eslint-disable prefer-promise-reject-errors */
import { Nullable } from '../types/index.d';
/* eslint-disable no-console */
import strings from '../constants/strings';
import {
  OcrConfig,
  OcrResponse,
  OcrError,
  CaptureMode,
} from '../types/common.d';
import { isAllowedDevice } from '../utils/deviceUtils';

export class OcrService {
  private isABBYYInitialized: boolean;

  private url: string;

  private env: string;

  private mwcConfig: OcrConfig;

  private mwcLicencePath: {};

  private mockData: OcrResponse;

  private confidenceTreshold: number;

  constructor() {
    this.confidenceTreshold = 0.6;

    this.mwcConfig = {
      startPage: 0,
      images: [],
      maxImagesCount: 2,
      enableCamera: true,
      enableAutoCapture: true,
      enableAutoCrop: true,
      enableQualityAssessmentForOCR: true,
      documentSize: 'ID-2',
      aspectRatioMin: 1.3,
      aspectRatioMax: 1.7,
      localizedStrings: strings.ocr,
      mode: CaptureMode.BaseCapture,
    };
    this.mwcLicencePath = {
      licenseFilePath:
        window.env.BUILD_VARIANT === 'production'
          ? '/license/prod/MWC.ABBYY.License'
          : '/license/dev/MWC.ABBYY.License',
      wasmFilesPath: '../../libs/js',
    };
    this.isABBYYInitialized = false;
    this.env = window.env;
    this.url = 'capture/id-card';
    this.mockData = {
      name: 'Specimen',
      giveNames: 'Vzorka',
      nationality: 'SVK',
      sex: 'F',
      number: 'EAOOOOOO',
      issuedBy: 'Bratislava',
      dateOfBirth: '11.11.1911',
      personalNumber: '111111/1111',
      dateOfExpiry: '01.03.2025',
      dateOfIssue: '01.03.2015',
      address: ['Vymyslená 34', 'Kriváň'],
      surnameAtBirth: 'Pôvodná',
      placeOfBirth: 'Banská Bystrica',
      specialRemarks: ['Mgr.'],
      machineReadableZone: [''],
    };
    this.init();
  }

  public isAlive() {
    return this.isABBYYInitialized;
  }

  public getEnv() {
    return this.env;
  }

  public ocr(e: any[]): Promise<OcrResponse | ErrorConstructor> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open('POST', this.url, true);
      request.responseType = 'json';
      request.setRequestHeader(
        'Content-type',
        'application/x-www-form-urlencoded',
      );
      request.onload = () =>
        request.status === 200
          ? resolve(request.response)
          : reject(Error(request.statusText));

      request.onerror = () => reject(Error('Network Error'));

      console.info(
        `Quality[${e[0].ocrQuality.confidence},${e[1].ocrQuality.confidence}]`,
      );
      request.send(
        `frontSide=${encodeURIComponent(
          e[0].capturedImage,
        )}&backSide=${encodeURIComponent(e[1].capturedImage)}`,
      );
    });
  }

  public init(): Promise<Nullable<string>> {
    return new Promise((resolve, reject) => {
      //  @ts-ignore
      if (window && window.ABBYY) {
        //  @ts-ignore
        window.ABBYY.WebCapture.init(this.mwcLicencePath)
          .then(() => {
            console.info('ABBYY MobileWebCapture initialized');
            this.isABBYYInitialized = true;
            return resolve('ABBYY');
          })
          .catch(e => {
            console.error(
              `ABBYY MobileWebCapture initialization error: ${e.toString()}`,
            );
            return reject(e);
          });
      }
    });
  }

  public capture(
    forceSkipProcessing?: boolean,
    localConfig?: any,
  ): Promise<OcrResponse | ErrorConstructor> {
    return new Promise((resolve, reject) => {
      //  @ts-ignore
      if (window && window.ABBYY) {
        //  @ts-ignore
        window.ABBYY.WebCapture.capture(
          localConfig && !!localConfig.localizedStrings
            ? localConfig
            : this.mwcConfig,
        )
          .then(idcScans =>
            this.checkNumberOfPhotos(idcScans, {
              localConfig,
              forceSkipProcessing,
            }),
          )
          .then(idcScans => {
            if (!isAllowedDevice || forceSkipProcessing) {
              return resolve(idcScans);
            }
            return this.save(idcScans);
          })
          .then(idcScans => this.validateSize(idcScans))
          .then(idcScans => this.validateRatio(idcScans))
          .then(idcScans => this.validateConfidence(idcScans))
          // .then(idcScans => this.ocr(idcScans))
          .then(data => resolve(data))
          .catch(({ error, idcScans }) => {
            // bad pattern, but need save scans images
            resolve(idcScans ?? []);
            // if (error?.code !== undefined) {
            //   console.log(error.code);
            //   throw error;
            // } else throw error;
          });
      }
    });
  }

  private computeRatio(scan): string | null {
    const width = Math.max(
      scan.documentBoundary[2].x - scan.documentBoundary[1].x,
      scan.documentBoundary[3].x - scan.documentBoundary[0].x,
    );
    const height = Math.max(
      scan.documentBoundary[0].y - scan.documentBoundary[1].y,
      scan.documentBoundary[3].y - scan.documentBoundary[2].y,
    );
    const ratio = width / height;
    const reverseRatio = height / width;

    // Korektne
    if (
      ratio >= this.mwcConfig.aspectRatioMin &&
      ratio <= this.mwcConfig.aspectRatioMax
    )
      return null;

    // Pravdepodobne nafotene po vyske
    if (
      reverseRatio >= this.mwcConfig.aspectRatioMin &&
      reverseRatio >= this.mwcConfig.aspectRatioMax
    )
      return 'IncorrectOrientation';

    // Pravdepodobne nafotene po vyske
    return 'IncorrectRatio'; // Nespravny format, pravdepodobne zle orezane
  }

  private checkNumberOfPhotos(
    idcScans: any[],
    ...other: any
  ): Promise<OcrError | any[]> {
    const _self = this;
    const { forceSkipProcessing } = other;
    return new Promise(resolve => {
      if (idcScans.length === 1) {
        const cnf = {
          ...other,
          ...this.mwcConfig,
          images: idcScans,
          startPage: 1,
          forceSkipProcessing,
        };
        console.log('new config');
        console.log(cnf);

        resolve(
          // @ts-ignore
          _self.capture(forceSkipProcessing, cnf),
        );

        // no reject - need continue to capture more photos
        // reject({ error: "Missing pages" });
      }
      resolve(idcScans);
    });
  }

  private validateSize(idcScans: any[]): Promise<OcrError | any[]> {
    return new Promise((resolve, reject) => {
      const error = {
        code: 'MissingPictureError',
        aux: {
          front: idcScans.length === 0 ? 'Missing' : null,
          back: idcScans.length === 1 ? 'Missing' : null,
        },
      };
      if (idcScans.length === 2) resolve(idcScans);
      else reject({ error, idcScans });
    });
  }

  private validateRatio(idcScans: any[]): Promise<OcrError | any[]> {
    return new Promise((resolve, reject) => {
      const error = {
        code: 'IncorrectRatioError',
        aux: {
          front: this.computeRatio(idcScans[0]),
          back: this.computeRatio(idcScans[1]),
        },
      };
      if (error.aux.front == null && error.aux.back == null) resolve(idcScans);
      else reject({ error, idcScans });
    });
  }

  private validateConfidence(idcScans: any[]): Promise<OcrError | any[]> {
    return new Promise((resolve, reject) => {
      if (!idcScans[0].ocrQuality) resolve(idcScans);
      const error = {
        code: 'OcrQualityError',
        aux: {
          front:
            idcScans[0].ocrQuality.confidence < this.confidenceTreshold
              ? 'LowOcrQuality'
              : null,
          back:
            idcScans[1].ocrQuality.confidence < this.confidenceTreshold
              ? 'LowOcrQuality'
              : null,
        },
      };
      if (error.aux.front == null && error.aux.back == null) resolve(idcScans);
      else reject({ error, idcScans });
    });
  }

  private save(idcScans: any[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.mwcConfig.images = idcScans;
      resolve(idcScans);
    });
  }
}

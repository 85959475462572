import { SET_COUNTRIES } from '../actions/codeLists-action';
import { CodeListsReducer } from '../types/reducers.d';

const defaultState: CodeListsReducer = {
  countries: [],
};

export default (state: CodeListsReducer = defaultState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        countries: action.countries,
      };

    default:
      return state;
  }
};

export default {
  title: 'Cookies',
  description:
    'Súbor cookie je malý blok údajov (textový súbor), ktoré sa do vášho prehliadača ukladajú na pokyn internetovej lokality, keď ju používateľ navštívi, s cieľom zapamätať si informácie o vás, ako napríklad preferencie jazyka alebo prihlasovacie údaje. Tieto súbory cookie nastavujeme my a nazývajú sa súbory cookie prvej strany. Používame aj súbory cookie tretích strán. Ide o súbory cookie z inej domény, ako je doména internetovej lokality, ktorú práve navštevujete, a slúžia na účely našich reklamných a marketingových činností. Konkrétnejšie používame súbory cookie a iné sledovacie technológie na tieto účely:',
  necessaryCookies: {
    title: 'Nevyhnutne potrebné súbory cookie',
    description:
      'Tieto súbory cookie sú potrebné na zabezpečenie funkčnosti internetovej stránky a nemožno ich v našich systémoch vypnúť. Zvyčajne sa nastavujú len ako reakcia na vami vykonané činnosti, ktoré predstavujú žiadosť súvisiacu so službami, ako je napríklad nastavenie preferencií ochrany osobných údajov, prihlasovanie alebo vypĺňanie formulárov. Svoj prehliadač môžete nastaviť tak, aby blokoval alebo vás upozorňoval na takéto súbory cookie, v takom prípade však nemusia niektoré časti stránky fungovať.',
    tableTitle: 'Súbory cookies prvých strán',
  },
  functionCookies: {
    title: 'Súbory cookie súvisiace s funkčnosťou',
    description:
      'Tieto súbory cookie zabezpečujú lepšiu funkčnosť a prispôsobenie obsahu, ako sú videá a živý chat. Môžeme ich nastaviť my alebo externí poskytovatelia, ktorých služby sme pridali na naše stránky. Ak tieto súbory cookie nepovolíte, niektoré alebo všetky funkcie nemusia fungovať správne.',
    tableTitleFirstParty: 'Súbory cookies prvých strán',
    tableTitleThirdParty: 'Súbory cookies tretích strán',
  },
  powerCookies: {
    title: 'Súbory cookie súvisiace s výkonom',
    description:
      'Tieto súbory cookie nám umožňujú určiť počet návštev a zdroje návštevnosti, aby sme mohli merať a vylepšovať výkon našej stránky. Pomáhajú nám zistiť, ktoré stránky sú najviac a najmenej populárne, a vidieť, koľko návštevníkov sa na stránke pohybuje. Všetky informácie, ktoré tieto súbory cookie zbierajú, sú súhrnné, a teda anonymné. Ak tieto súbory cookie nepovolíte, nebudeme vedieť, kedy ste našu stránku navštívili.',
    tableTitle: 'Súbory cookies prvých strán',
  },
  targetCookies: {
    title: 'Súbory cookie pre cieľové zameranie',
    description:
      'Tieto súbory cookie nastavujú prostredníctvom našej stránky naši reklamní partneri. Tieto spoločnosti ich môžu používať na zostavenie profilu vašich záujmov a zobrazenie relevantných reklám na iných stránkach. Fungujú tak, že jedinečným spôsobom identifikujú váš prehliadač a zariadenie. Ak tieto súbory cookie nepovolíte, nebudete na iných internetových stránkach dostávať náš cieľový reklamný obsah.',
    tableTitleFirstParty: 'Súbory cookies prvých strán',
    tableTitleThirdParty: 'Súbory cookies tretích strán',
  },
  validityCookies: {
    title: '*Životnosť',
    description:
      'Dočasné cookies (automaticky sú zmazané po zatvorení webového prehliadača).<br />Trvalé cookies (ostanú na disku uložené aj po zatvorení webového prehliadača).',
  },
  settingsButton: 'Nastavenia cookies',
};

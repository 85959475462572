import {
  //   READ_THREADS,
  RESET_ERROR_MESSAGE,
  RESET_THREADS,
  SELECT_THREAD,
  SET_THREAD,
} from '../actions/messenger-actions';
import {
  FETCH_THREADS,
  MOCK_FETCH_THREADS,
  FETCH_THREAD_MESSAGES,
  MOCK_FETCH_THREAD_MESSAGES,
  SEND_MESSAGE,
  DOWNLOAD_ATTACHMENT,
  DELETE_THREAD,
  READ_THREAD,
} from '../api/actionSets';
import { LoadingState } from '../types/index.d';
import { MessengerReducer } from '../types/messenger.d';

export const initialState: MessengerReducer = {
  messageLoading: false,
  messageError: null,
  threadLoading: false,
  threadsCount: 0,
  threads: [],
  limit: 0,
  offset: 0,
  themesLoading: false,
  threadMessages: {
    state: LoadingState.none,
    list: [],
    selected: null,
    error: null,
  },
  threadTheme: null,
  themes: [],
  isLoading: false,
};

function messengerReducer(state: MessengerReducer = initialState, action) {
  switch (action.type) {
    // FETCH THREADS
    case MOCK_FETCH_THREADS.LOADING:
    case FETCH_THREADS.LOADING:
      return {
        ...state,
        threadsCount: 0,
        threads: [],
        limit: 0,
        offset: 0,
        isError: false,
        error: null,
        isLoaded: false,
        threadLoading: true,
        threadTheme: null,
        threadMessages: {
          ...state.threadMessages,
          selected: null,
          state: 'none',
          list: [],
        },
      };
    case MOCK_FETCH_THREADS.SUCCESS:
    case FETCH_THREADS.SUCCESS:
      return {
        ...state,
        threadLoading: false,
        isLoaded: true,
        ...action.response,
      };
    case MOCK_FETCH_THREADS.ERROR:
    case FETCH_THREADS.ERROR:
      return {
        ...state,
        threadLoading: false,
        isLoaded: false,
        isError: true,
        error: 'Nastala chyba pri komunikácii so serverom.',
      };

    //   FETCH THREAD MESSAGES
    case MOCK_FETCH_THREAD_MESSAGES.LOADING:
    case FETCH_THREAD_MESSAGES.LOADING:
      return {
        ...state,
        isError: false,
        error: null,
        isLoaded: false,
        isLoading: true,
        threadMessages: {
          ...state.threadMessages,
          selected: action.threadId,
          state: 'fetching',
          list: [],
        },
      };
    case MOCK_FETCH_THREAD_MESSAGES.SUCCESS:
    case FETCH_THREAD_MESSAGES.SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        threadMessages: {
          ...state.threadMessages,
          selected: action.threadId,
          error: action.response.error || null,
          list: action.response.messages,
          state: action.response.result ? 'filled' : 'error',
        },
        threadTheme: action.response.theme,
      };
    case MOCK_FETCH_THREAD_MESSAGES.ERROR:
    case FETCH_THREAD_MESSAGES.ERROR:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        threadMessages: {
          ...state.threadMessages,
          error: 'Nastala chyba pri komunikácii so serverom.',
          list: [],
          state: 'error',
        },
        threadTheme: null,
      };

    //   SEND MESSAGE
    case SEND_MESSAGE.LOADING:
      return {
        ...state,
        messageLoading: true,
        messageError: null,
      };
    case SEND_MESSAGE.SUCCESS:
      return {
        ...state,
        threadsCount: action.response.threadsCount,
        threads: action.response.threads,
        limit: action.response.limit,
        offset: action.response.offset,
        threadMessages: {
          ...state.threadMessages,
          list: action.response.actualThread.messages,
          state: action.response.result ? 'filled' : 'error',
          selected: action.response.threads[0].id,
        },
        threadTheme: action.response.actualThread.topic,
        isError: action.response.result,
        messageLoading: false,
        messageError: !action.response.result
          ? action.response.statusMessage
          : null,
      };
    case SEND_MESSAGE.ERROR:
      return {
        ...state,
        messageLoading: false,
        messageError: 'Nastala chyba pri komunikácii so serverom',
      };

    //   DOWNLOAD ATTACHMENT
    case DOWNLOAD_ATTACHMENT.LOADING:
    case DOWNLOAD_ATTACHMENT.SUCCESS:
    case DOWNLOAD_ATTACHMENT.ERROR:
      return state;
    // return {
    //   ...state,
    //   isLoaded: false,
    //   isLoading: false,
    // };

    //   DELETE THREAD
    case DELETE_THREAD.LOADING:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    case DELETE_THREAD.SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        threadsCount: action.response.threadsCount,
        threads: action.response.threads,
        limit: action.response.limit,
        offset: action.response.offset,
        threadMessages: {
          selected: action.response.threads[0].id,
        },
        isError: !action.response.result,
        error: !action.response.result && action.response.statusMessage,
      };
    case DELETE_THREAD.ERROR:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      };

    //   SELECT THREAD
    case SELECT_THREAD:
      return {
        ...state,
        threadMessages: {
          ...state.threadMessages,
          selected: action.payload,
        },
      };

    //   READ THREAD
    case READ_THREAD.LOADING:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    case READ_THREAD.SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        threadsCount: action.response.threadsCount,
        threads: action.response.threads,
        isError: !action.response.result,
        error: !action.response.result && action.response.statusMessage,
      };
    case READ_THREAD.ERROR:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      };

    case SET_THREAD:
      return state;
    case RESET_THREADS:
      return {
        ...state,
        isLoaded: true,
        threadsCount: action.payload.threadsCount,
        threads: action.payload.threads,
        limit: action.payload.limit,
        offset: action.payload.offset,
        threadMessages: {
          selected: action.payload.threads[0].id,
        },
        isError: action.payload.result,
        error: action.payload.statusMessage,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        messageError: null,
      };
    default:
      return state;
  }
}

export default messengerReducer;

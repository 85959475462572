import { fontWeight } from '@dovera/design-system';

export default {
  '@font-face': [
    {
      fontFamily: 'DoveraSans',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSans-Regular.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSans-Regular.woff) format('woff'),
        url(/fonts/dovera-sans/DoveraSans-Regular.ttf) format('truetype');`,
    },
    {
      fontFamily: 'DoveraSans',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSans-Medium.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSans-Medium.woff) format('woff'),
        url(/fonts/dovera-sans/DoveraSans-Medium.ttf) format('truetype');`,
      fontWeight: fontWeight.semiBold,
    },
    {
      fontFamily: 'DoveraSans',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSans-Bold.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSans-Bold.woff) format('woff'),
        url(/fonts/dovera-sans/DoveraSans-Bold.ttf) format('truetype')`,
      fontWeight: fontWeight.bold,
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-Reg..woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-Reg..woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-Reg..ttf) format('truetype');`,
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-RegIta.woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-RegIta.woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-RegIta.ttf) format('truetype');`,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-Bld.woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-Bld.woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-Bld.ttf) format('truetype');`,
      fontWeight: fontWeight.bold,
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-BldIta.woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-BldIta.woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-BldIta.ttf) format('truetype');`,
      fontWeight: fontWeight.bold,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'SourceSansPro',
      src: `url("${window.env.config.baseURI}/fonts/source-sans-pro/SourceSansPro-Regular.woff2") format("woff2")`,
    },
    {
      fontFamily: 'SourceSansPro',
      src: `url("${window.env.config.baseURI}/fonts/source-sans-pro/SourceSansPro-Semibold.woff2") format("woff2")`,
      fontWeight: fontWeight.bold,
    },
  ],
};

import { Nullable } from '../../types/index.d';

export default {
  title: 'Dohoda o vykonaní práce',
  subTitle:
    'Na tejto stránke máte možnosť vypniť všetky údaje potrebné k uzatvorneniu novej Dohody o vykonaní práce. Po zadaní všetkých údajov viete návrh Dohody priamo digitálne podpísať.',
  scanButton: {
    frontSide: 'Nasnímať prednú stranu',
    backSide: 'Nasnímať zadnú stranu',
    success: 'Nasnímať znovu',
    error: 'Nasnímať znovu',
    status: {
      success: 'Automatické dopĺňanie <br/> prebehlo úspešne',
      error: 'Automatické dopĺňanie zlyhalo. <br/>Skúste to, prosím, znovu.',
    },
  },
  divider: {
    section: {
      contact: 'Adresa trvalého bydliska',
      bank: 'Bankové spojenie',
      insuranceCompay: 'Zdravotná poisťovňa',
    },
  },
  step1: {
    header: 'Identifikácia',
    subHeader:
      'Zadajte Váš identifkačný kód na spracovanie Dohody o vykonaní práce',
    summary: {
      final: (verifyCode: string) =>
        `<strong>Identifikačný kód</strong><br/>
         ${verifyCode}`,
    },
    content: {
      verifyCode: 'Identifikačný kód',
    },
  },
  step2: {
    header: 'Údaje o zmluve',
    subHeader: 'Vyplňte rodné číslo, pre Vašu rýchlejšiu identifikáciu',
    summary: {
      headlines: {
        birthNumber: 'Rodné číslo',
        nationality: 'Štátna príslušnosť',
      },
    },
    content: {
      birthNumber: 'Rodné číslo',
      nationality: 'Štátna príslušnosť',
    },
  },
  step3: {
    header: 'Fotka občianskeho preukazu',
    subHeader:
      'Fotokópia dokladu totožnosti  (občiansky preukaz) je povinná náležitosť prihlášky! Preto potrebujeme jeho fotokópiu. Nižšie máte kompletný návod ako postupovať.',
    summary: {
      headlines: {
        identity: 'Doklad totožnosti',
        front: 'Občiansky preukaz - predná strana',
        back: 'Občiansky preukaz - zadná strana',
        attachment: 'Príloha',
      },
    },
    content: {
      form: {
        frontSide: 'Predná strana občianskeho preukazu',
        backSide: 'Zadná strana občianskeho preukazu',
      },
    },
  },
  step4: {
    header: 'Osobné údaje',
    subHeader:
      'Ak chcete vypĺňanie  Dohody o vykonaní práce urýchliť, môžete nahrať fotografiu svojho  občianskeho preukazu a Vaše údaje automaticky vpíšeme do osobných údajov.',
    summary: {
      headlines: {
        permanent: 'Trvalý pobyt',
        postal: 'Korešpondenčná adresa',
        name: 'Meno, priezvisko, titul',
        birthName: 'Rodné priezvisko',
        birthPlace: 'Miesto narodenia',
      },
    },
    content: {
      form: {
        firstName: 'Meno',
        lastName: 'Priezvisko',
        birthName: 'Rodné priezvisko',
        academicDegreeBeforeName: 'Titul pred menom',
        academicDegreeAfterName: 'Titul za menom',
        birthPlace: 'Miesto narodenia',
        obecNazov: 'Obec',
        ulicaNazov: 'Ulica',
        cislo: 'Súpisné číslo',
        psc: 'PSČ',
      },
    },
  },
  step5: {
    header: 'Doplňujúce údaje',
    summary: {
      headlines: {
        marital: 'Rodinný stav',
        phone: 'Telefónne číslo',
        email: 'E-mailová adresa',
        iban: 'Bankové spojenie',
        insuranceCompany: 'Zdravotná poisťovňa',
        pensioner: 'Poberateľ dôchodku',
        pensionerType: 'Typ dôchodku',
        pensionerDocument: 'Rozhodnutie o priznaní dôchodku',
        severeDisabilityCard: 'Držiteľ ZŤP preukazu',
        severeDisabilityType: 'Typ ZŤP',
        severeDisabilityDocument: 'Preukaz ZŤP',
      },
    },
    content: {
      form: {
        maritalStatus: 'Rodinný stav',
        phoneNumber: 'Telefónne číslo',
        emailAddress: 'E-mailová adresa',
        iban: 'Zadajte váš IBAN',
        insuranceCompay: 'Zdravotná poisťovňa',
        pensioner: 'Poberateľ dôchodku',
        pensionerType: 'Typ dôchodku, ktorý poberáte',
        pensionerDocument: 'Nahrajte rozhodnutie o priznaní dôchodku',
        pensionerFrom: 'Dôchodca od',
        pensionerApprover: 'Schváľovateľ dôchodku',
        severeDisabilityCard: 'Držiteľ ZŤP preukazu',
        severeDisabilityType: 'Typ ZŤP',
        severeDisabilityDocument:
          'Nahrajte preukaz ZŤP alebo doklad o vydaní preukazu ZŤP',
      },
    },
  },
  step6: {
    header: 'Odmeňovanie',
    subHeader:
      'Povinné údaje k prílohe Odmeňovanie, ktorá bude súčasťou Dohody o vykonaní práce.',
    summary: {
      headlines: {
        supervisor: 'Nadriadený',
        entrustedSupervisor: 'Poverený nadriadený',
        rewardType: 'Typ odmeňovania',
        employeeCode: 'Kód zamestnanca',
      },
    },
    content: {
      supervisor: 'Nadriadený',
      entrustedSupervisor: 'Poverený nadriadený',
      rewardType: 'Typ odmeňovania',
      employeeCode: 'Kód zamestnanca',
      employeeCodeHelp: 'Zadajte číselnú časť Vášho kódu zamestnanca',
    },
  },
  step7: {
    header: 'Kontrola údajov a generovanie dohody',
    subHeader:
      'Skontrolujte si osobné aj doplňujúce údaje a následne kliknite na tlačidlo “Vygenerovať súbor a podpísať”.',
    summary: {},
    content: {},
  },
  terminationStep: {
    content: {
      digitalSignature: {
        heading: 'Dohoda bola úspešne odoslaná!',
        list: (email: Nullable<string>) => [
          email &&
            `Informáciu o úspešnom spracovaní Dohody o vykonaní práce Vám doručíme na e-mail <strong>${email}.</strong>`,
          '<strong>Heslo</strong> pre otvorenie dokumentu Dohoda o vykonaní práce je <strong>Vaše rodné číslo.</strong>',
          'V prípade nejasností Vás budeme kontakovať  a pomôžeme Vám opraviť údaje priamo v aplikácii.',
        ],
      },
      error: {
        heading: 'Chyba: Podpisovanie dohody zlyhalo.',
        data: [
          '<strong>Došlo k problému s pripojením na server.</strong>',
          'Pokiaľ sa Vám nepodarí podpísať dohodu znovu, kontaktujte svojho Account Managera.',
        ],
        cancelApplication: 'Zrušiť dohodu',
        sendApplication: 'Znovu podpísať dohodu',
      },
      backToContracts: 'Späť na dohody',
    },
  },
  terminationStepByEmployee: {
    content: {
      digitalSignature: {
        heading: 'Dohoda bola úspešne podpísaná!',
      },
      error: {
        heading: 'Chyba: Podpisovanie dohody zlyhalo.',
        data: ['<strong>Došlo k problému s pripojením na server.</strong>'],
        sendApplication: 'Znovu podpísať dohodu zamestnávateľom',
        cancelApplication: 'Späť na detail dohody',
      },
      backToContracts: 'Späť na detail dohody',
      backToContractsManagement: 'Späť na zoznam dohôd',
    },
  },
  checkFormData:
    'Uistite sa, že všetky údaje, ktoré ste zadali sú vyplnené správne a s diakritikou.',
};

export enum ThreadSource {
  ALL = 'all',
  DELETED = 'deleted',
}
export enum ThreadType {
  MESSAGE = 'sprava',
  NEWS = 'novinka',
}

export const FILE_TYPES =
  '.doc,.docx,.xls,.xlsx,.rtf,.pdf,.jpeg,.jpg,.jpe,.gif,.png,.tiff,.tif,.msg,.txt';
export const allowedFileTypesSet = new Set([
  'doc',
  'docx',
  'xls',
  'xlsx',
  'rtf',
  'pdf',
  'jpeg',
  'jpg',
  'jpe',
  'gif',
  'png',
  'tiff',
  'tif',
  'msg',
  'txt',
]);
export const MAX_FILE_SIZE = 10485760;
export const LIMIT = 20;
export const MAX_MESSAGE_LENGTH = 750;

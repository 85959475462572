import {
  RESET_WITH_ZZ,
  STORE_REGISTRATION,
  RESET_REGISTRATION,
  SET_SIGN_TYPE,
  BACK_TO_WIZARD,
  SIMULATE_SUCCESSFUL_REGISTRATION,
  DOWNLOAD_REGISTRATION,
  RESET_VERIFY_INSUREE,
  RESET_SMS_LINK_MODAL,
  APPEND_OP_DOCUMENTS,
  ADD_OP_DOCUMENTS_TO_REGISTRATION,
} from '../actions/registration-action';
import {
  RegistrationReducer,
  RegistrationObject,
  RegistrationResponse,
  VerifyInsureeRespone,
  RegistrationRecapObject,
  SMSLinkRespone,
} from '../types/registration.d';

import {
  SAVE_REGISTRATION,
  SAVE_MOCK_REGISTRATION,
  VIEW_TO_SIGN_PREPARED,
  VERIFY_INSUREE_REGISTRATION,
  GET_INSUREE_REGISTRATION_FOR_SIGN,
  SAVE_APPROVAL_FOR_SIGN,
  SAVE_APPROVAL_FOR_SIGN_MOCK,
  GET_PERSON_DATA,
  SEND_REFERRAL_CODE,
  POST_REG_DOCUMENTS,
} from '../api/actionSets';
import { Loadable } from '../types/index.d';

const defaultRegistration: RegistrationObject = {
  id: null,
  idProspekt: null,
  poistenec: {
    rodneCislo: '',
    datumNarodenia: '',
    pohlavie: '',
    titul: '',
    meno: '',
    priezvisko: '',
    titulZa: '',
    rodnePriezvisko: '',
    statnaPrislusnostSkratka: '',
    typPobytuVSROd: '',
    poznamkaKPobytuVSR: '',
    datumPobytuVSROd: '',
    datumPobytuVSRDo: '',
    cisloDokladu: '',
    kodTypZakonnehoZastupcu: '',
    adresaTrvaleBydlisko: undefined,
    adresaPrechodneBydlisko: undefined,
    adresaKontaktna: undefined,
    kontaktneUdaje: undefined,
  },
  zamestnavatelUdaje: {},
  podavatelUdaje: {},
  kodTypPrihlasky: {},
  datumCasPodania: null,
  datumCasPrijatiaPrihlasky: null,
  datumVznikuSkutocnostiPreVznikPoistenia: null,
  datumVznikuPoistenia: null,
  kodPlatitelaPoistneho: null,
  prihlaskaDovod: null,
  kodInaZdravotnaPoistovna: null,
  kodPredchadzajucaZP: null,
  kodDruhDochodku: null,
  kodTypNahradyPrijmu: null,
  idMakler: null,
  datumCasZaevidovania: null,
  kodOznaceniePoistenca: null,
  kodUrceniePlatitelaPoistneho: null,
  kodTypPribuzenskehoVztahu: null,
  kodTypKategorie: null,
  matkaPoistenaVZP: false,
  matkaPoistovna: null,
  sposobPrevzatiaNarPreukazu: null,
  sposobDoruceniaNaPodpis: null,
  kodPobockaPrevz: null,
  kodTypPodnikania: null,
  kodSkupinaKategorie: null,
  icoSZCO: null,
  nazovSZCO: null,
  suhlasSoSpracovanimOsobnychUdajov: false,
  suhlasSoSpristupnenimOsobnychUdajov: false,
  suhlasSPoskytnutimUdajovExterne: false,
  dorucenieKurierom: {},
  dataVytazeneOCR: false,
  mobilneCisloPreSMS: null,
  prilohy: [],
  identifikacnyDoklad: null,
  identificationDocument: null,
};

const defaultRegistrationRecap: RegistrationRecapObject & Loadable = {
  appendingRegDocuments: false,
  newInsureeDataForSign: null,
  errorMessage: null,
  isLoading: false,
  approval: {
    isLoading: false,
    result: null,
  },
  employeerInternalNumber: null,
  registrationType: null,
  personBirthNumber: null,
  uploadedDocuments: [],
};

const defaultRegistrationResponse: RegistrationResponse = {
  insureeRegistrationID: null,
  pdfName: null,
  pdfContent: null,
  token: null,
  toSign: false,
  documentSignatusId: null,
};

const initialVerifyResponse: VerifyInsureeRespone = {
  statusCode: null,
  result: null,
  isLoading: false,
};

const initialSMSResponse: SMSLinkRespone = {
  sended: null,
  message: null,
  isLoading: false,
};

const defaultState: RegistrationReducer = {
  isLoading: true,
  isSaved: false,
  error: null,
  signType: null,
  registration: defaultRegistration,
  registrationRecap: defaultRegistrationRecap,
  wizardInProgress: true,
  isFinished: false,
  isDownloaded: false,
  content: defaultRegistrationResponse,
  verification: initialVerifyResponse,
  smsLink: initialSMSResponse,
};

export default (state: RegistrationReducer = defaultState, action) => {
  switch (action.type) {
    case SAVE_MOCK_REGISTRATION.LOADING:
    case SAVE_REGISTRATION.LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
        isSaved: true,
        isFinished: false,
        isDownloaded: false,
        wizardInProgress: false,
        content: defaultRegistrationResponse,
      };
    case SIMULATE_SUCCESSFUL_REGISTRATION:
    case SAVE_MOCK_REGISTRATION.SUCCESS:
    case SAVE_REGISTRATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: action.response.Result ? null : action.response.StatusMessage,
        isSaved: true,
        isFinished: true,
        isDownloaded: false,
        wizardInProgress: false,
        content: action.response,
      };
    case SAVE_MOCK_REGISTRATION.ERROR:
    case SAVE_REGISTRATION.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action,
        isSaved: true,
        isFinished: false,
        isDownloaded: false,
        wizardInProgress: false,
        content: defaultRegistrationResponse,
      };

    // insuree registration for sms sign
    case GET_INSUREE_REGISTRATION_FOR_SIGN.LOADING:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          isLoading: true,
          errorMessage: null,
        },
      };
    case GET_INSUREE_REGISTRATION_FOR_SIGN.SUCCESS:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          newInsureeDataForSign:
            typeof action.response.newInsureeDataForSign === 'string'
              ? JSON.parse(action.response.newInsureeDataForSign)
              : action.response.newInsureeDataForSign,
          uploadedDocuments: action.response.uploadedDocuments,
          errorMessage: action.response.errorMessage,
          isLoading: false,
        },
      };
    case GET_INSUREE_REGISTRATION_FOR_SIGN.ERROR:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          isLoading: false,
          errorMessage: 'Zadali ste nesprávne rodné číslo',
        },
      };
    // save approval for sms sign
    case SAVE_APPROVAL_FOR_SIGN_MOCK.LOADING:
    case SAVE_APPROVAL_FOR_SIGN.LOADING:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          approval: {
            ...state.registrationRecap.approval,
            isLoading: true,
            result: null,
          },
        },
      };
    case SAVE_APPROVAL_FOR_SIGN_MOCK.SUCCESS:
    case SAVE_APPROVAL_FOR_SIGN.SUCCESS:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          approval: {
            ...state.registrationRecap.approval,
            isLoading: false,
            result: action.response.result,
          },
        },
      };
    case SAVE_APPROVAL_FOR_SIGN_MOCK.ERROR:
    case SAVE_APPROVAL_FOR_SIGN.ERROR:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          approval: {
            ...state.registrationRecap.approval,
            isLoading: false,
            result: false,
          },
        },
      };
    // verify insuree registration
    case VERIFY_INSUREE_REGISTRATION.LOADING:
      return {
        ...state,
        verification: {
          ...state.verification,
          isLoading: true,
        },
      };
    case VERIFY_INSUREE_REGISTRATION.SUCCESS:
      return {
        ...state,
        verification: {
          ...state.verification,
          ...action.response,
          isLoading: false,
        },
      };
    case VERIFY_INSUREE_REGISTRATION.ERROR:
      return {
        ...state,
        verification: {
          ...state.verification,
          isLoading: false,
        },
      };
    case RESET_VERIFY_INSUREE:
      return {
        ...state,
        verification: initialVerifyResponse,
      };
    case VIEW_TO_SIGN_PREPARED.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case VIEW_TO_SIGN_PREPARED.SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: {
          ...state.content,
          documentSignatusId: action.response.success,
        },
      };
    case VIEW_TO_SIGN_PREPARED.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action || 'Server error',
        isSaved: true,
        isFinished: false,
        isDownloaded: false,
        wizardInProgress: false,
      };
    case DOWNLOAD_REGISTRATION:
      return {
        ...state,
        isDownloaded: true,
      };
    case STORE_REGISTRATION:
      return {
        ...state,
        isLoading: false,
        error: null,
        isSaved: true,
        isFinished: false,
        wizardInProgress: false,
        registration: action.data,
      };
    case RESET_WITH_ZZ:
    case RESET_REGISTRATION:
      return {
        ...state,
        isLoading: false,
        error: null,
        isSaved: false,
        isFinished: false,
        wizardInProgress: true,
        registration: defaultRegistration,
        signType: null,
      };
    case SET_SIGN_TYPE:
      return {
        ...state,
        error: null,
        isSaved: true,
        isFinished: false,
        wizardInProgress: false,
        signType: action.signType,
      };
    case BACK_TO_WIZARD:
      return {
        ...state,
        isLoading: false,
        error: null,
        isSaved: false,
        isFinished: false,
        wizardInProgress: true,
        signType: null,
      };
    case GET_PERSON_DATA.SUCCESS:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          employeerInternalNumber: action.response.employeerInternalNumber,
          registrationType: action.response.registration.registrationType,
          personBirthNumber: action.response.person.birthNumber,
        },
      };
    // send referral code action set
    case SEND_REFERRAL_CODE.LOADING:
      return {
        ...state,
        smsLink: {
          ...state.smsLink,
          isLoading: true,
          sended: null,
          message: null,
        },
      };
    case SEND_REFERRAL_CODE.SUCCESS:
      return {
        ...state,
        smsLink: {
          ...state.smsLink,
          isLoading: false,
          ...action.response,
        },
      };
    case SEND_REFERRAL_CODE.ERROR:
      return {
        ...state,
        smsLink: {
          ...state.smsLink,
          isLoading: false,
          sended: false,
          message: 'Nastala chyba pri komunikácií so serverom.',
        },
      };
    case RESET_SMS_LINK_MODAL:
      return {
        ...state,
        smsLink: {
          ...state.smsLink,
          isLoading: false,
          sended: null,
          message: null,
        },
      };
    case APPEND_OP_DOCUMENTS:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          appendingRegDocuments: false,
          uploadedDocuments: action.documents,
        },
      };
    case POST_REG_DOCUMENTS.LOADING:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          appendingRegDocuments: true,
        },
      };
    case POST_REG_DOCUMENTS.SUCCESS:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          appendingRegDocuments: false,
          uploadedDocuments: action.response.result ? action.documents : [],
        },
      };
    case POST_REG_DOCUMENTS.ERROR:
      return {
        ...state,
        registrationRecap: {
          ...state.registrationRecap,
          appendingRegDocuments: false,
        },
      };
    case ADD_OP_DOCUMENTS_TO_REGISTRATION:
      return {
        ...state,
        registration: {
          ...state.registration,
          // prilohy: [...(state.registration.prilohy ?? []), ...action.documents],
          prilohy: [...action.documents],
        },
      };
    default:
      return state;
  }
};

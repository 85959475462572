import { ContractWizardReducer } from '../types/contractWizard.d';
import {
  SET_STEP_CONTRACT,
  INCOMPLETE_STEP_CONTRACT,
  COMPLETE_STEP_CONTRACT,
  SET_VERIFYCODE_DATA,
  SET_CONTRACTDATA_DATA,
  SET_IDENTITY_DATA,
  SET_PERSONAL_DATA,
  SET_ADDITIONAL_DATA,
  SET_REWARD_DATA,
} from '../actions/contractWizard-action';

import mockDefault from './mocks/contractWizard/default';
import mockReal from './mocks/contractWizard/real';
import { SET_PERSONAL_DATA_FROM_OCR } from '../actions/ocr-actions';

const defaultState: ContractWizardReducer = mockDefault;
const realState: ContractWizardReducer = mockReal;
const isDebug = false;

export default (
  state: ContractWizardReducer = !isDebug ? defaultState : realState,
  action,
) => {
  switch (action.type) {
    // case RESET_REGISTRATION:
    //   return defaultState;
    case SET_STEP_CONTRACT:
      return {
        ...state,
        [`step${action.step}`]: {
          ...state[`step${action.step}`],
          ...action.data,
          isCompleted: true,
        },
      };
    case INCOMPLETE_STEP_CONTRACT:
      return {
        ...state,
        [`step${action.step}`]: {
          ...state[`step${action.step}`],
          isCompleted: false,
        },
      };
    case COMPLETE_STEP_CONTRACT:
      return {
        ...state,
        [`step${action.step}`]: {
          ...state[`step${action.step}`],
          isCompleted: true,
        },
      };
    case SET_VERIFYCODE_DATA:
      return {
        ...state,
        step1: {
          ...state.step1,
          verifyCode: action.verifyCode,
        },
      };
    case SET_CONTRACTDATA_DATA:
      return {
        ...state,
        step2: {
          ...state.step2,
          birthNumber: action.birthNumber,
          nationality: action.nationality,
        },
      };
    case SET_IDENTITY_DATA:
      return {
        ...state,
        step3: {
          ...state.step3,
          frontSideOP: action.frontSideOP,
          backSideOP: action.backSideOP,
        },
      };
    case SET_PERSONAL_DATA:
      return {
        ...state,
        step4: {
          ...state.step4,
          ...action.personalData,
        },
      };
    case SET_PERSONAL_DATA_FROM_OCR:
      return {
        ...state,
        step4: {
          ...state.step4,
          ...action.personalDataFromOcr,
        },
      };
    case SET_ADDITIONAL_DATA:
      return {
        ...state,
        step5: {
          ...state.step5,
          ...action.additionalData,
        },
      };
    case SET_REWARD_DATA:
      return {
        ...state,
        step6: {
          ...state.step6,
          supervisor: action.supervisor,
          entrustedSupervisor: action.entrustedSupervisor,
          rewardType: action.rewardType,
          employeeCode: action.employeeCode,
        },
      };
    default:
      return state;
  }
};

import { ReactNode } from 'react';
import { Action } from 'redux';

export type ObjectMap<K extends keyof any, T> = { [P in K]: T };

export interface StringMap<T> {
  [key: string]: T;
}

export type DDLHref = JSX.Element;

export interface GenericAction extends Action {
  response?: any;
  error?: any;
}

export type Nullable<T> = T | null | undefined;

export interface SafeHtml {
  __html: string;
}
export type Texts = StringMap<Text>;

export interface Text {
  id: string;
  pozicia: string;
  html: string;
  parametre: StringMap<TextParameter>;
  odkaz: string;
  typ: string;
  nazov: string;
}

export interface TextParameter {
  nazov: string;
  id: string;
}

export interface Loadable {
  error?: any;
  isError?: boolean;
  isLoaded?: boolean;
  isLoading?: boolean;
  isSending?: boolean;
  isSent?: boolean;
  loadingError?: any;
}

export enum LoadingState {
  error = 'error',
  fetching = 'fetching',
  filled = 'filled',
  none = 'none',
  reset = 'reset',
  sending = 'sending',
  sent = 'sent',
}

export interface BaseComponentProps {
  classes?: any;
  children?: ReactNode;
}

export interface ProcessLogRequestType {
  createdBy?: string;
  threadID?: number;
  ip?: string;
  controller: string;
  method: string;
  section: string;
  request?: string;
  response?: string;
  exception?: string;
  durarion?: number;
}

export interface LogType {
  userID: number;
  brokerID: number;
  threadID: number;
  ip: string;
  userAgent: string;
}

export interface FileDownload {
  content: Nullable<string>;
  type: Nullable<string>;
  name: Nullable<string>;
}

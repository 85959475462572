export enum UserMainRoles {
  limited = 'LIMITEDACCESS',
  full = 'FULLACCESS',
}

export enum UserAdditionalRoles {
  paska = 'VYPPASKA',
  maklerGroup = 'MAKS',
  maklerGroupNM = 'MAKSNM',
  maklerAdmin = 'MAKADMIN',
  olzAdmin = 'OLZADMIN',
  zmena = 'ZMENA_PRIHL',
}

export enum UserCampaignRoles {
  ovb = 'KAMPANOVB',
  extPob = 'EXTPOBSUTAZ',
  zac = 'KAMPANZAC',
  star1 = 'KAMPANSTAR1',
  star2 = 'KAMPANSTAR2',
}

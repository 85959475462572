import * as actionSets from './actionSets';
// eslint-disable-next-line no-unused-vars
import { createBodyLog, createUrl } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';

const controller = 'Benefits';
const benefits = {
  getBenefits: () => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetBenefits`, {}),
      body: createBodyLog({}),
      method: 'POST',
      actions: actionSets.GET_BENEFITS_DATA,
    },
  }),
};

export default benefits;

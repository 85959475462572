export default {
  title: 'Prihláška',
  devicesApprovement:
    'Potvrdili ste, že máte pri sebe <strong>tablet s perom</strong> alebo <strong> tlačiareň</strong>, bez ktorých nie je možné dokončiť elektronickú prihlášku.',
  suggestion:
    'Pre urýchlenie procesu prepoistenia si nezabudnite pripraviť svoj <strong>doklad totožnosti s čipom</strong>.',
  prihlaska: 'Prihláška',
  benefity: 'Čo ponúka Dôvera',
  dohody: 'Dohoda o vykonaní práce',
  vyplatnePasky: 'Výplatné pásky',
  demo: 'Vyskúšať si demo aplikácie',
  leaf:
    ' Za každú digitálne podpísanú prihlášku <br /> darujeme 50 centov na ekoprojekty.',
};

import { Nullable } from '../types/index.d';
import moment from 'moment';
import formats from '../constants/formats';
import { SexType, MaintanaceDates } from '../types/common.d';

export const getDateFromBirthNumber = (birthNumber: string): Date => {
  const dateBirth = birthNumber.substr(0, 6);
  const bornYearShort = parseInt(dateBirth.substr(0, 2), 10);
  const bornMonthOrigin = parseInt(dateBirth.substr(2, 2), 10);
  const bornMonth =
    bornMonthOrigin > 50 ? bornMonthOrigin - 50 : bornMonthOrigin;
  const bornDay = parseInt(dateBirth.substr(4, 2), 10);
  let bornYear =
    bornYearShort >= 54
      ? parseInt('1900', 10) + bornYearShort
      : parseInt('2000', 10) + bornYearShort;
  if (birthNumber.length < 10) bornYear = parseInt('1900', 10) + bornYearShort;
  return moment(
    `${bornYear}-${bornMonth}-${bornDay}`,
    formats.ISO_TIMESTAMP_NO_OFFSET,
  ).toDate();
};

export const getDateFromBirthNumberToString = (
  birthNumber: string,
): Nullable<string> => {
  const date = getDateFromBirthNumber(birthNumber);
  if (moment(date).isValid()) return date.toISOString();
  return null;
};

export function timestampToDate(timestamp: string) {
  return moment(timestamp, formats.DATE_STANDART_ONLY).toDate();
}

export const personIsAdultByBirthNumber = (birthNumber: string): boolean => {
  const diffDates = moment().diff(getDateFromBirthNumber(birthNumber), 'years');
  return diffDates >= 18;
};

export const personIsNewBornByBirthNumber = (birthNumber: string): boolean => {
  const diffDates = moment().diff(getDateFromBirthNumber(birthNumber), 'days');
  return diffDates <= 60;
};

export const personBirthDateAndBirthNumberIsSame = (
  dateBirth: string,
  dateNumber: string,
): boolean =>
  moment(dateBirth.toString()).isSame(
    getDateFromBirthNumber(dateNumber).toString(),
  );

export const getPersonAgeByBirthNumber = (birthNumber: string): number =>
  moment().diff(getDateFromBirthNumber(birthNumber), 'years');

export const getPersonSexByBirthNumber = (birthNumber: string): SexType => {
  const dateBirth = birthNumber.substr(0, 6);
  const bornMonthOrigin = parseInt(dateBirth.substr(2, 2), 10);
  return bornMonthOrigin > 50 ? SexType.female : SexType.male;
};

export const isCampaignActive = () => {
  const now = moment();
  const from = moment(`${now.year()}-01-01`, formats.DATE_STANDART_ONLY);
  const to = moment(`${now.year()}-10-01`, formats.DATE_STANDART_ONLY);
  return now.isBetween(from, to);
};

export const isMaintananceSession = ({ from, to }: MaintanaceDates) =>
  moment().isBetween(moment(from), moment(to));

export const getCorrectYearForActiveSession = () => {
  const now = moment();
  if (!isCampaignActive()) {
    return now.year() + 1;
  }
  return now.year();
};

export const isValidDate = date => {
  const year = moment(date).year();
  if (year && year > 1800 && year < 9999) return true;
  return false;
};

// const dt = new Date();
export const dateFilterInit = {
  to: moment()
    .set({ hour: 12, minute: 0, second: 0 })
    .utcOffset(1)
    .format(),
  from: moment()
    .startOf('month')
    .set({ hour: 12, minute: 0, second: 0 })
    .utcOffset(1)
    .format(),
};

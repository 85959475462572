import * as actionSets from './actionSets';
import { createUrl, createBodyLog, callApiRequest } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import { MOCK_API_CALL } from '../middleware/mockApiMiddleware';
import {
  ApprovalDataInput,
  ReferralDataInput,
  RegDocumentsDataInput,
  VerificationDataInput,
} from '../types/registration';

const controller = 'Registration';
const registration = {
  saveRegistration: regData => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SaveRegistration`),
      body: createBodyLog(regData),
      method: 'POST',
      actions: actionSets.SAVE_REGISTRATION,
      authenticated: true,
    },
  }),
  saveMock: regData => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/SaveRegistration`),
      body: createBodyLog(regData),
      method: 'POST',
      actions: actionSets.SAVE_MOCK_REGISTRATION,
      responseTime: 2000,
      mockResponse: {
        insureeRegistrationID: 'insureeID123',
        pdfName: 'Test.pdf',
        pdfContent: 'xxx',
        token: 'token',
        toSign: true,
        documentSignatusId: null,
      },
    },
  }),
  resendToSign: resignData => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ReSignedDocument`),
      body: createBodyLog(resignData),
      method: 'POST',
      actions: actionSets.VIEW_TO_SIGN_PREPARED,
      authenticated: true,
    },
  }),
  resendToSignMock: resignData => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/ReSignedDocument`),
      body: createBodyLog(resignData),
      method: 'POST',
      actions: actionSets.VIEW_TO_SIGN_PREPARED,
      authenticated: true,
      responseTime: 2000,
      mockResponse: {
        success: true,
      },
    },
  }),
  confirmSignedDocument: token => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ConfirmSignedDocument`),
      body: createBodyLog(token),
      method: 'POST',
      actions: actionSets.EMPTY_ACTION,
      authenticated: false,
    },
  }),
  getPersonData: id => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetAdditionalDataRegistration`),
      body: createBodyLog({ token: id }),
      method: 'POST',
      actions: actionSets.GET_PERSON_DATA,
      authenticated: false,
    },
  }),
  cancelApplication: token => ({
    [API_CALL]: {
      url: createUrl(`${controller}/CancelSignedDocument`),
      body: createBodyLog(token),
      method: 'POST',
      actions: actionSets.EMPTY_ACTION,
      authenticated: false,
    },
  }),
  verifyInsureeRegistration: birthNumber => ({
    [API_CALL]: {
      url: createUrl(`${controller}/VerifyInsureeRegistration`),
      body: createBodyLog({ birthNumber }),
      method: 'POST',
      actions: actionSets.VERIFY_INSUREE_REGISTRATION,
      authenticated: true,
    },
  }),
  getInsureeRegistrationForSign: (verificationData: VerificationDataInput) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetInsureeRegistrationForSign`),
      body: createBodyLog(verificationData),
      method: 'POST',
      actions: actionSets.GET_INSUREE_REGISTRATION_FOR_SIGN,
      authenticated: false,
    },
  }),
  saveApprovalForSign: (approvalData: ApprovalDataInput) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SaveApprovalForSign`),
      body: createBodyLog(approvalData),
      method: 'POST',
      actions: actionSets.SAVE_APPROVAL_FOR_SIGN,
      authenticated: false,
    },
  }),
  saveApprovalForSignMock: (approvalData: ApprovalDataInput) => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/SaveApprovalForSign`),
      body: createBodyLog(approvalData),
      method: 'POST',
      actions: actionSets.SAVE_APPROVAL_FOR_SIGN_MOCK,
      authenticated: false,
      responseTime: 2000,
      mockResponse: {
        result: true,
      },
    },
  }),
  sendMyReferralCode: (referralData: ReferralDataInput) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SendMyReferralCode`),
      body: createBodyLog(referralData),
      method: 'POST',
      actions: actionSets.SEND_REFERRAL_CODE,
      authenticated: true,
    },
  }),
  saveRegDocuments: (documentsData: RegDocumentsDataInput) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SaveRegistrationDocument`),
      body: createBodyLog(documentsData),
      method: 'POST',
      actions: actionSets.POST_REG_DOCUMENTS,
      authenticated: true,
      meta: { documents: documentsData.documents },
    },
  }),
  SignWebRegistration: (token: string): Promise<any> =>
    callApiRequest(
      `${controller}/SignWebRegistration`,
      createBodyLog({ token }),
    )
      .then((response: any) => response)
      .catch(error => {
        console.log(error);
        return { documentBioSignKey: null, success: false };
      }),
};

export default registration;

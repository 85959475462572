import { ContractWizardReducer } from '../../../types/contractWizard.d';

const mock: ContractWizardReducer = {
  step1: {
    verifyCode: null,
    isCompleted: false,
  },
  step2: {
    birthNumber: null,
    nationality: null,
    isCompleted: false,
  },
  step3: {
    frontSideOP: null,
    backSideOP: null,
    isCompleted: false,
  },
  step4: {
    firstName: null,
    lastName: null,
    birthName: null,
    academicDegreeBeforeName: null,
    academicDegreeAfterName: null,
    birthPlace: null,
    birthPlaceCode: null,
    permanentAddress: null,
    postalAddress: null,
    havePostalAddress: false,
    isCompleted: false,
  },
  step5: {
    maritalStatus: null,
    phoneNumber: null,
    emailAddress: null,
    iban: null,
    insuranceCompany: null,
    pensioner: null,
    pensionerType: null,
    pensionerDocument: null,
    severeDisabilityCard: null,
    severeDisabilityType: null,
    severeDisabilityDocument: null,
    isCompleted: false,
  },
  step6: {
    supervisor: null,
    entrustedSupervisor: null,
    rewardType: null,
    employeeCode: null,
    levelRemuneration: null,
    isCompleted: false,
  },
  step7: {
    signType: null,
    isCompleted: false,
  },
};

export default mock;

import links from './links';

export default [
  {
    IconAward: '<strong>29 rokov</strong> na slovenskom trhu',
  },
  {
    IconUsers: 'Vyše <strong>1,7 milióna poistencov</strong>',
  },
  {
    IconMap: `Kvalitný servis v <strong>73 pobočkách</strong> na <a href=${links.doveraPobocky} target="_blank">celom Slovensku</a>`,
  },
  {
    IconUsers: `<strong>21 655</strong> (90 %) <strong>lekárov</strong> <a href=${links.doveraOveritLekara} target="_blank">Overiť zazmluvnenosť</a>`,
  },
  {
    IconMapPin: 'Viac ako <strong>22 tisíc zmluvných ambulancií</strong>',
  },
  {
    IconAward:
      'Prestížne ocenenia – Smart poisťovňa, IT projekt roka, FB Awards, ITAPA',
  },
  {
    IconEuroSign:
      '<strong>takmer 20 miliónov eur</strong> v doplatkoch za lieky 300+300',
  },
  {
    IconUsers:
      '<strong>takmer 2,4 milióna eur</strong> v programe Bojovníci za zdravie',
  },
  {
    IconPercent: 'Zľavy obchodných partnerov <strong>až do 50&nbsp;%</strong>',
  },
  {
    IconSmartphone: 'Najmodernejšia mobilná aplikácia',
  },
  {
    IconClock: 'Vyplnenie prihlášky do <strong>40 sekúnd</strong>',
  },
  {
    IconEditInSquare: 'Zmenu ZP stačí nahlásiť zamestnávateľovi',
  },
];

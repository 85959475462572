import { AppSectionType } from '../types/common.d';

export const RESET_GA_EVENT = 'RESET_GA_EVENT';
export const SET_GA_EVENT = 'SET_GA_EVENT';

export const storeGaEvent = id => ({
  type: RESET_GA_EVENT,
  id,
});

export const setGaEvent = (
  category: AppSectionType,
  action,
  label,
  value?,
) => ({
  type: SET_GA_EVENT,
  category,
  action,
  label,
  value,
});

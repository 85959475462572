import illustrations from '../illustrations';

export default {
  heading: 'Ponuka na e-mail',
  inputs: {
    email: {
      label: 'E-mail',
      placeholder: 'Zadajte e-mailovú adresu prijímateľa',
    },
    name: {
      label: 'Meno',
      placeholder: 'Zadajte krstné meno',
    },
    surname: {
      label: 'Priezvisko',
      placeholder: 'Zadajte priezvisko',
    },
    telephone: {
      label: 'Telefón',
      placeholder: '+421...',
    },
    message: {
      label: 'Text e-mailu',
    },
  },
  notificationBox:
    'Meno a priezvisko nie sú povinné údaje. Vďaka týmto informáciám však môžeme e-mail lepšie prispôsobiť (napríklad oslovením) a pomáhajú vytvárať plnohodnotnú databázu záujemcov o poistenie.',
  subheading: 'Aké informácie poslať?',
  benefitsHeading: 'Benefity zo skupiny',
  emailPreview: 'Náhľad vzorového e-mailu',
  consent:
    ' Súhlasím so spracovaním osobných údajov za účelom komunikačných a marketingových aktivít zdravotnej poisťovne Dôvera.',
  closeButton: 'Zavrieť bez odoslania',
  sendButton: 'Odoslať e-mail',
  resultState: {
    success: {
      message: 'Váš e-mail bol úspešne odoslaný!',
      illustration: illustrations.emailOfferResultSuccess,
    },
    error: {
      message: 'Chyba: E-mail sa nepodarilo odoslať.',
      illustration: illustrations.emailOfferResultError,
    },
  },
  backToBenefits: 'Späť na benefity',
  tryAgain: 'Odoslať znovu',
};

import common from './_common';
import validation from './validation';
import { FILE_TYPES, MAX_FILE_SIZE } from '../messages';

export default {
  header: 'Správy',
  odoslat: 'Odoslať',
  odpovedat: 'Odpovedať',
  novaSprava: 'Nová správa',
  hladanyVyrazPlaceholder: 'Vyhľadať v správach',
  tabVsetky: (neprecitane: number) => {
    if (!neprecitane) {
      return 'Všetky';
    }
    return `Všetky (${neprecitane})`;
  },
  tabVymazane: 'Vymazané',
  spatNaZoznam: 'Späť na zoznam',
  technickaChyba: common.technickaChyba,
  ziadneSpravy: 'Nemáte žiadne správy',
  ziadneVymazaneSpravy: 'Nemáte žiadne vymazané správy',
  loadingErrorDetailTitle: 'Nepodarilo sa načítať správu',
  loadingErrorDetailText:
    'Prepáčte, nastala technická chyba pri otváraní správy, skúste to prosím znovu.',
  nacitatZnovu: 'Načítať znovu',
  potvrditVymazanieVlakna: {
    header: 'Naozaj chcete zmazať toto vlákno správ?',
    description: 'Vymazané správy najdete v záložke Vymazané',
    yes: 'Zmazať',
    no: 'Nie',
  },
  errorMessages: (type: string) => {
    switch (type) {
      case 'file-invalid-type':
        return validation.wrongAttachmentFormat(FILE_TYPES);
      case 'file-too-large':
        return validation.wrongAttachmentSize(`${MAX_FILE_SIZE / 1000} Kb`);
      default:
        return validation.unknown;
    }
  },
  loadingErrorVlakna: {
    header: 'Nepodarilo sa načítať správy',
    description:
      'Prepáčte, nastala technická chyba pri načítavaní správy, skúste to prosím znovu.',
  },
  loadingErrorVlakno: {
    description:
      'Prepáčte, nastala technická chyba pri otváraní správy, skúste to prosím znovu.',
    header: 'Nepodarilo sa načítať správu',
  },
};

import { color } from '@dovera/design-system';

const palette = {
  primary: {
    100: '#ddedd2',
    400: '#73b353',
    500: '#50a028',
    600: '#408020',
  },
  secondary: {
    100: '#d2dded',
    400: '#4c79b7',
    500: '#1f57a5',
    600: '#194684',
  },
  grey: {
    50: '#f7f7f7',
    100: '#e1e2e3',
    200: '#c5c6c8',
    300: '#a7a9ac',
    500: '#6d7175',
  },
};

const colors = {
  textPrimary: palette.primary[500],
  textSecondary: palette.secondary[500],
  textGrey: palette.grey[500],
  addressNodifGrey: palette.grey[50],
  textDisabled: '#A7A9AC',
  textError: '#FF0000',
  tableAlt: '#F2F5FA',
  ocrTextField: '#E8EEF6',
  primary: palette.primary[500],
  secondary: palette.secondary[500],
  stepper: {
    outline: 'rgba(31, 87, 165, 0.3)',
  },
  divider: '#E5E5E5',
  warning: '#FF9800',
  palette,
  white: '#fff',
};

export { colors, color };

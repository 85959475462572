import { SAVE_USER_CONTACT_CLEAR } from '../api/actionSets';

export const STORE_CONTRACT = 'STORE_CONTRACT';
export const RESET_CONTRACT = 'RESET_CONTRACT';
export const SIMULATE_SUCCESSFUL_CONTRACT = 'SIMULATE_SUCCESSFUL_CONTRACT';
export const SIMULATE_UNSUCCESSFUL_CONTRACT = 'SIMULATE_UNSUCCESSFUL_CONTRACT';

export const storeContractData = data => ({
  type: STORE_CONTRACT,
  data,
});

export const resetContractData = {
  type: RESET_CONTRACT,
};

export const simulateSuccessfulContract = {
  type: SIMULATE_SUCCESSFUL_CONTRACT,
  response: {
    contractToken: 'token',
    documentSignatusId: 'signatusid123',
    pdfContent: 'testtest',
    xfdf: 'test',
  },
};

export const simulateUnsuccessfulContract = {
  type: SIMULATE_UNSUCCESSFUL_CONTRACT,
  response: {
    error: true,
  },
};

export const clearDataFromUserContactUpdate = () => ({
  type: SAVE_USER_CONTACT_CLEAR,
});

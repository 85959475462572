import { WizardReducer } from '../../../types/wizard.d';

const mock: WizardReducer = {
  step0: {
    undefined: {
      ocr: null,
    },
    insuree: {
      // @ts-ignore
      // ocr: 'success',
      ocr: null,
    },
    legalRepresentative: {
      ocr: null,
    },
    isCompleted: true,
  },
  step1: {
    // @ts-ignore
    person: 'adult',
    // @ts-ignore
    insuranceCompany: 'vszp',
    isCompleted: true,
  },
  step2: {
    // @ts-ignore
    legalRepresentative: {},
    insuree: {
      birthNumber: '0057219712',
      dateOfBirth: new Date('2000-07-20').toISOString(),
      firstName: 'ado1',
      lastName: 'ado2',
      birthName: 'ado3',
      idNumber: 'sl123sl',
      nationality: 'SK',
      sex: null,
      academicDegreeBeforeName: null,
      academicDegreeAfterName: null,
      permanentAddress: {
        obecNazov: 'KOŠICE-DARGOVSKÝCH HRDINOV',
        cislo: '3',
        psc: '04022',
        ulicaNazov: 'BENADOVA',
        statNazov: 'SLOVENSKÁ REPUBLIKA',
        statKod: 'SK',
        obecKod: '598682',
        ulicaKod: '246625',
      },
      temporaryAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      postalAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      ocr: {
        isOCR: true,
        birthNumber: true,
        dateOfBirth: true,
        firstName: true,
        lastName: true,
        birthName: true,
        idNumber: true,
        nationality: true,
        academicDegreeBeforeName: true,
        academicDegreeAfterName: true,
        statNazov: true,
        obecNazov: true,
        cislo: true,
        ulicaNazov: true,
        psc: true,
      },
      havePostalAddress: false,
      haveTemporaryAddress: false,
      stayFrom: null,
      stayTo: null,
    },
    isCompleted: true,
  },
  step3: {
    // @ts-ignore
    insurancePayerType: 'selfPayer',
    employer: null,
    selfEmployed: null,
    state: null,
    stateOther: null,
    retiree: null,
    student: null,
    homeWithChild: null,
    icoSZCO: null,
    nazovSZCO: null,
    isCompleted: false,
  },
  step4: {
    email: 'ado@ado.sk',
    phone: '0911223344',
    // @ts-ignore
    relation: 'other',
    motherInsuranceCompany: null,
    motherBirthNumber: null,
    // @ts-ignore
    deliveryAddressType: 'branchOffice',
    deliveryAddress: 7802,
    courierAddress: null,
    strongConsent: false,
    weakConsent: false,
    enableToLogin: true,
    isCompleted: false,
  },
  step5: {
    signType: null,
    phoneNumberForSMS: null,
    isCompleted: false,
  },
};

export default mock;

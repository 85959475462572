import { createBodyLog, createUrl } from '../utils/apiUtils';
import { Employer } from '../types/common.d';

const controller = 'AutoComplete';
const address = {
  getEmployers: (
    query: string,
    searchICO?: boolean,
  ): Promise<
    Array<{
      title: string;
      value: string;
      employer: Employer;
    }>
  > =>
    fetch(createUrl(`${controller}/GetEmployers`, {}), {
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      method: 'POST',
      body: JSON.stringify(createBodyLog({ search: query })),
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(myJson =>
        myJson.result.map(c => ({
          value: c.id,
          title: searchICO ? c.ico : c.nazov,
          employer: c,
        })),
      )
      .catch(error => []),
  getOffices: (
    query: string,
  ): Promise<
    Array<{
      title: string;
      value: string;
    }>
  > =>
    fetch(createUrl(`${controller}/GetOffices`, {}), {
      headers: {
        'Content-Type': 'application/json-patch+json',
        accept: 'text/plain',
      },
      method: 'POST',
      body: JSON.stringify(
        createBodyLog({
          search: query,
        }),
      ),
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(myJson =>
        myJson.result.map(c => ({
          value: c.alfaKod,
          title: c.name,
        })),
      )
      .catch(error => []),
  getEmployee: (
    query: string,
    relationID: number,
  ): Promise<
    Array<{
      title: string;
      value: string;
    }>
  > =>
    fetch(createUrl(`${controller}/GetInternalEmployees`, {}), {
      headers: {
        'Content-Type': 'application/json-patch+json',
        accept: 'text/plain',
      },
      method: 'POST',
      body: JSON.stringify(
        createBodyLog({
          search: query,
          relationID,
        }),
      ),
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(myJson =>
        myJson.internalEmployees.map(c => ({
          value: `${c.evidKod} - ${c.fullName}`,
          title: `${c.evidKod} - ${c.fullName}`,
        })),
      )
      .catch(error => []),
  getAdminEmployee: (
    query: string,
  ): Promise<
    Array<{
      title: string;
      value: string;
    }>
  > =>
    fetch(createUrl(`${controller}/GetAdminInternalEmployees`, {}), {
      headers: {
        'Content-Type': 'application/json-patch+json',
        accept: 'text/plain',
      },
      method: 'POST',
      body: JSON.stringify(
        createBodyLog({
          search: query,
        }),
      ),
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(myJson =>
        myJson.internalEmployees.map(c => ({
          value: {
            idMaklera: c.idMaklera,
            evidKod: `${c.evidKod}`,
            fullName: `${c.fullName}`,
          },
          title: `${c.fullName} - ${c.evidKod}${c.status && ` (${c.status})`}`,
        })),
      )
      .catch(error => []),
};

export default address;

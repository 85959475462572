// @ts-ignore
import { createApiActionSet } from '@mediworx/mdxutils';

export interface ApiActionSet {
  SUCCESS: string;
  ERROR: string;
  LOADING: string;
}

export const TEST_CALL: ApiActionSet = createApiActionSet('TEST_CALL');

export const LOGIN: ApiActionSet = createApiActionSet('LOGIN');

export const CHANGE_LOGIN: ApiActionSet = createApiActionSet('CHANGE_LOGIN');

export const CHANGE_PASSWORD: ApiActionSet = createApiActionSet(
  'CHANGE_PASSWORD',
);

export const CHANGE_IBAN: ApiActionSet = createApiActionSet('CHANGE_IBAN');

export const VERIFY: ApiActionSet = createApiActionSet('VERIFY');

export const DAJ_UDAJE_POUZIVATELA: ApiActionSet = createApiActionSet(
  'DAJ_UDAJE_POUZIVATELA',
);

export const OVER_GRID_KARTU: ApiActionSet = createApiActionSet(
  'OVER_GRID_KARTU',
);

export const GET_SW_VERSION: ApiActionSet = createApiActionSet(
  'GET_SW_VERSION',
);

export const GET_SYSTEM_STATUS: ApiActionSet = createApiActionSet(
  'GET_SYSTEM_STATUS',
);

export const VERIFY_INSUREE_REGISTRATION: ApiActionSet = createApiActionSet(
  'VERIFY_INSUREE_REGISTRATION',
);
export const SAVE_REGISTRATION: ApiActionSet = createApiActionSet(
  'SAVE_REGISTRATION',
);
export const SAVE_MOCK_REGISTRATION: ApiActionSet = createApiActionSet(
  'SAVE_MOCK_REGISTRATION',
);
export const GET_CITIES: ApiActionSet = createApiActionSet('GET_CITIES');
export const GET_STREETS: ApiActionSet = createApiActionSet('GET_STREETS');
export const GET_POST_CODES: ApiActionSet = createApiActionSet(
  'GET_POST_CODES',
);
export const VALIDATE_BIRTH_NUMBER: ApiActionSet = createApiActionSet(
  'VALIDATE_BIRTH_NUMBER',
);
export const VALIDATE_CONTRACT_BIRTH_NUMBER: ApiActionSet = createApiActionSet(
  'VALIDATE_CONTRACT_BIRTH_NUMBER',
);
export const VALIDATE_EMPLOYEE_NUMBER: ApiActionSet = createApiActionSet(
  'VALIDATE_EMPLOYEE_NUMBER',
);
export const VALIDATE_INSUREE_RELATION: ApiActionSet = createApiActionSet(
  'VALIDATE_INSUREE_RELATION',
);
export const GET_NEW_THREAD: ApiActionSet = createApiActionSet(
  'GET_NEW_THREAD',
);
export const SET_PROCESS_LOG: ApiActionSet = createApiActionSet(
  'SET_PROCESS_LOG',
);
export const GET_PERSON_DATA: ApiActionSet = createApiActionSet(
  'GET_PERSON_DATA',
);
export const OCR_CAPTURED_IMAGE: ApiActionSet = createApiActionSet(
  'OCR_CAPTURED_IMAGE',
);
export const NFINITY_OCR_CREATE_TRANSACTION: ApiActionSet = createApiActionSet(
  'NFINITY_OCR_CREATE_TRANSACTION',
);
export const NFINITY_OCR_GET_DATA: ApiActionSet = createApiActionSet(
  'NFINITY_OCR_GET_DATA',
);
export const EMPTY_ACTION: ApiActionSet = createApiActionSet('EMPTY_ACTION');
export const VIEW_TO_SIGN_PREPARED: ApiActionSet = createApiActionSet(
  'VIEW_TO_SIGN_PREPARED',
);
export const GET_APPLICATION_LIST: ApiActionSet = createApiActionSet(
  'GET_APPLICATION_LIST',
);
export const GET_EMPLOYEE_LIST: ApiActionSet = createApiActionSet(
  'GET_EMPLOYEE_LIST',
);
export const GET_PAYOUTS_LIST: ApiActionSet = createApiActionSet(
  'GET_PAYOUTS_LIST',
);
export const GET_PAYOUT_DOCUMENT: ApiActionSet = createApiActionSet(
  'GET_PAYOUT_DOCUMENT',
);
export const GET_CONTRACTS_LIST: ApiActionSet = createApiActionSet(
  'GET_CONTRACTS_LIST',
);
export const GET_RVM_CONTACTS: ApiActionSet = createApiActionSet(
  'GET_RVM_CONTACTS',
);
export const SAVE_CONTRACT: ApiActionSet = createApiActionSet('SAVE_CONTRACT');
export const VALIDATE_VERIFY_CODE: ApiActionSet = createApiActionSet(
  'VALIDATE_VERIFY_CODE',
);
export const CHECK_MOBILE: ApiActionSet = createApiActionSet('CHECK_MOBILE');
export const CHECK_EMAIL: ApiActionSet = createApiActionSet('CHECK_EMAIL');
export const CONFIRM_SIGNED_CONTRACT: ApiActionSet = createApiActionSet(
  'CONFIRM_SIGNED_CONTRACT',
);
export const GET_CONTRACTS_MANAGEMENT_LIST: ApiActionSet = createApiActionSet(
  'GET_CONTRACTS_MANAGEMENT_LIST',
);
export const GET_CONTRACTS_MANAGEMENT_DETAIL: ApiActionSet = createApiActionSet(
  'GET_CONTRACTS_MANAGEMENT_DETAIL',
);
export const GET_CONTRACTS_MANAGEMENT_DETAIL_DOCUMENTS: ApiActionSet = createApiActionSet(
  'GET_CONTRACTS_MANAGEMENT_DETAIL_DOCUMENTS',
);
export const GET_CONTRACTS_MANAGEMENT_DETAIL_BUTTONS: ApiActionSet = createApiActionSet(
  'GET_CONTRACTS_MANAGEMENT_DETAIL_BUTTONS',
);
export const RESIGN_CONTRACT: ApiActionSet = createApiActionSet(
  'RESIGN_CONTRACT',
);
export const SIGN_CONTRACT: ApiActionSet = createApiActionSet('SIGN_CONTRACT');
export const CANCEL_CONTRACT: ApiActionSet = createApiActionSet(
  'CANCEL_CONTRACT',
);
export const SEND_CONTRACT_TO_TXS: ApiActionSet = createApiActionSet(
  'SEND_CONTRACT_TO_TXS',
);
export const GET_LIST_TERMINATED_CONTRACT: ApiActionSet = createApiActionSet(
  'GET_LIST_TERMINATED_CONTRACT',
);
export const DELETE_CONTRACT_DOCUMENT: ApiActionSet = createApiActionSet(
  'DELETE_CONTRACT_DOCUMENT',
);
export const DOWNLOAD_CONTRACT_DOCUMENT: ApiActionSet = createApiActionSet(
  'DOWNLOAD_CONTRACT_DOCUMENT',
);

export const DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT: ApiActionSet = createApiActionSet(
  'DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT',
);
export const UPLOAD_CONTRACT_DOCUMENT: ApiActionSet = createApiActionSet(
  'UPLOAD_CONTRACT_DOCUMENT',
);
export const UPDATE_RETIREMENT: ApiActionSet = createApiActionSet(
  'UPDATE_RETIREMENT',
);
export const UPDATE_SUPERIORS: ApiActionSet = createApiActionSet(
  'UPDATE_SUPERIORS',
);

export const SAVE_USER_CONTACT: ApiActionSet = createApiActionSet(
  'SAVE_USER_CONTACT',
);

export const SAVE_USER_CONTACT_CLEAR: string = 'SAVE_USER_CONTACT_CLEAR';

// messenger api action sets
export const FETCH_THREADS: ApiActionSet = createApiActionSet('FETCH_THREADS');
export const MOCK_FETCH_THREADS: ApiActionSet = createApiActionSet(
  'MOCK_FETCH_THREADS',
);
export const FETCH_THREAD_MESSAGES: ApiActionSet = createApiActionSet(
  'FETCH_THREAD_MESSAGES',
);
export const MOCK_FETCH_THREAD_MESSAGES: ApiActionSet = createApiActionSet(
  'MOCK_FETCH_THREAD_MESSAGES',
);
export const DOWNLOAD_ATTACHMENT: ApiActionSet = createApiActionSet(
  'DOWNLOAD_ATTACHMENT',
);
export const SEND_MESSAGE: ApiActionSet = createApiActionSet('SEND_MESSAGE');
export const READ_THREAD: ApiActionSet = createApiActionSet('READ_THREAD');
export const DELETE_THREAD: ApiActionSet = createApiActionSet('DELETE_THREAD');

// app action sets
export const SET_PARAMETRIC_MODE: ApiActionSet = createApiActionSet(
  'SET_PARAMETRIC_MODE',
);

// login control action sets
export const RESET_LOGIN: ApiActionSet = createApiActionSet('RESET_LOGIN');
export const RESET_LOGIN_MOCK: ApiActionSet = createApiActionSet(
  'RESET_LOGIN_MOCK',
);

// get insuree for registration sign sets
export const GET_INSUREE_REGISTRATION_FOR_SIGN: ApiActionSet = createApiActionSet(
  'GET_INSUREE_REGISTRATION_FOR_SIGN',
);

export const SAVE_APPROVAL_FOR_SIGN: ApiActionSet = createApiActionSet(
  'SAVE_APPROVAL_FOR_SIGN',
);

export const SAVE_APPROVAL_FOR_SIGN_MOCK: ApiActionSet = createApiActionSet(
  'SAVE_APPROVAL_FOR_SIGN_MOCK',
);

export const SEND_REFERRAL_CODE: ApiActionSet = createApiActionSet(
  'SEND_REFERRAL_CODE',
);
export const POST_REG_DOCUMENTS: ApiActionSet = createApiActionSet(
  'POST_REG_DOCUMENTS',
);
export const GET_BENEFITS_DATA: ApiActionSet = createApiActionSet(
  'GET_BENEFITS_DATA',
);

import { LoginControlReducer } from '../types/loginControl.d';
import { RESET_LOGIN, RESET_LOGIN_MOCK } from '../api/actionSets';

const defaultState: LoginControlReducer = {
  isLoading: false,
  isLoaded: false,
  statusCode: null,
  message: null,
};

export default (state: LoginControlReducer = defaultState, action) => {
  switch (action.type) {
    case RESET_LOGIN_MOCK.LOADING:
    case RESET_LOGIN.LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        statusCode: null,
        message: null,
      };
    case RESET_LOGIN_MOCK.ERROR:
    case RESET_LOGIN.ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        statusCode: null,
        message: action.response.message,
      };
    case RESET_LOGIN_MOCK.SUCCESS:
    case RESET_LOGIN.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        ...action.response,
      };
    default:
      return state;
  }
};

import { GetThemesRequest, ReadThreadRequest } from '../types/messenger.d';

// probably api action sets, or some middleware
export const GET_THEMES = 'GET_THEMES';
export const SELECT_THREAD = 'SELECT_THREAD';
export const DELETE_THREAD = 'DELETE_THREAD';
export const SET_THREAD_AS_READ = 'SET_THREAD_AS_READ';

// reducer types
export const RESET_THREADS = 'RESET_THREADS';
export const READ_THREADS = 'READ_THREADS';
export const SET_THREAD = 'SET_THREAD';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

export const readThread = (data: ReadThreadRequest) => ({
  type: SET_THREAD_AS_READ,
  data,
});

export const resetThreads = (payload: ReadThreadRequest) => ({
  type: RESET_THREADS,
  payload,
});

export const getThemes = (data: GetThemesRequest) => ({
  type: GET_THEMES,
  data,
});

export const selectThread = payload => ({
  type: SELECT_THREAD,
  payload,
  //   dispatch(setThread(threadId));
});

export const resetErrorMessage = () => ({
  type: RESET_ERROR_MESSAGE,
});

export const readThreads = () => ({
  type: READ_THREADS,
});

export const setThread = () => ({
  type: SET_THREAD,
});

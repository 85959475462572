export default {
  notification: {
    blockDelivery: {
      prepare: [
        'V aplikácii Via nebude možné zaslať prihlášku na podpis - <strong>Doručiť kuriérom</strong> od 30.9. od 6:00 hod.',
      ],
      actual: [
        'Prihlášky podpísané  digitálnym podpisom je možné podať dnes 30.9. do 24:00 hod.',
        'Možnosť zaslať prihlášky na podpis - Doručiť kuriérom je  od 30.9. od 6:00 nedostupná.',
      ],
    },
    thanksForCampaign: {
      prepare: [],
      actual: [
        'Ďakujeme Vám za aktívnu účasť v kampani, prajeme veľa zdravia a tešíme sa na ďaľšiu úspešnú spoluprácu.',
      ],
    },
  },
};

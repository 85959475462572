export default {
  test: 'TEST',
  loading: 'Načítava sa',
  upravit: 'Upraviť',
  uploadOP: 'Nahrať občiansky preukaz',
  pokracovat: 'Pokračovať',
  potvrditUdaje: 'Potvrdiť údaje',
  ulozit: 'Uložiť',
  ulozitUpravy: 'Uložiť úpravy',
  nove: 'Nové',
  spat: 'Späť',
  close: 'Zavrieť',
  zrusit: 'Zrušiť',
  odoslat: 'Odoslať',
  vyplnitPrihlasku: 'Vyplniť prihlášku',
  zobrazitBenefity: 'Zobraziť benefity',
  zobrazitDohody: 'Zobraziť dohody',
  zobrazitPasky: 'Zobraziť výplatné pásky',
  zahoditKoncept: 'Zahodiť koncept',
  menuList: {
    odhlasit: 'Odhlásiť',
    employeeNumber: 'ID zamestnanca',
    production: 'Produkcia',
    application: 'Prihlášky',
    contacts: 'Kontakty',
    contracts: 'Dohody',
    contractsManagement: 'Správa dohôd',
    payouts: 'Výplatná páska',
    profile: 'Profil',
    myTeam: 'Podriadení',
    mailing: 'Kampane',
    education: 'Vzdelávanie',
    changeData: 'Zmena osobných údajov',
    messages: 'Správy',
  },
  inProgressModalHeader: 'Stránka vo vývoji',
  inProgressModalText:
    'Ľutujeme, stránka je momentálne nedostupná, pracujeme na jej vývoji.',
  selectPlaceholder: 'Zvoľte jednu z možností',
  redirecting: 'Presmerovanie...',
  stiahnut: 'Stiahnuť',
  vymazat: 'Vymazať',
  nahrat: 'Nahrať',
  priloha: {
    prilozitSubor: 'Priložiť súbor',
    text: 'Presuňte súbor sem alebo kliknite na',
  },
  technickaChyba:
    'Nastala technická chyba. Skúste, prosím, opakovať akciu neskôr.',
  labels: {
    icp: {
      default: 'Identifikačné číslo poistenca',
      add: '',
    },
    email: {
      default: 'E-mail',
      add: '',
      warning: 'Neevidujeme E-mail',
    },
    adresaTrvalehoPobytu: {
      default: 'Adresa trvalého pobytu',
      add: '',
    },
    korespondencnaAdresa: {
      default: 'Kontaktná adresa',
      add: 'Pridať kontaktnú adresu',
    },
    prechodnyPobyt: {
      default: 'Prechodný pobyt',
      add: 'Pridať prechodný pobyt',
    },
    mobil: {
      default: 'Mobilné číslo',
      add: 'Pridať mobilné číslo',
      warning: 'Neevidujeme čislo',
    },
    rc: {
      default: 'Rodné číslo',
    },
    name: {
      default: 'Meno a priezvisko',
    },
    birthDate: {
      default: 'Dátum narodenia',
    },
    tema: {
      default: 'Téma',
      placeholder: 'Prosím, vyberte si tému',
      warning: 'Vyberte tému správy',
    },
    subtema: {
      default: 'Detail témy',
      placeholder: 'Prosím, vyberte si detail témy',
    },
    sprava: {
      default: 'Správa',
      subject: 'Predmet',
    },
    pozadovanyTermin: {
      default: 'Požadovaný termín',
    },
    nahradnyTermin: {
      default: 'Náhradný termín',
    },
    iban: {
      default: 'Číslo účtu',
      placeholder: 'Vložte IBAN v tvare SK8975000000...',
    },
    bankovyUcet: {
      default: 'Zvoľte účet',
      placeholder: 'doplňte IBAN',
    },
  },
  offline: {
    header: 'Prepáčte, máme výpadok',
    text:
      'Pracujeme na tom, aby sme problém čo najskôr odstránili. Skúste to neskôr, prosím.',
    cta: 'Načítať znova',
  },
  abbyyWelcomeBox: {
    steps: {
      step1:
        'Začnite prvou stranou s fotkou. Cudzinec nasníma prvú stranu pobytového preukazu alebo doklad Dočasné útočisko.',
      step2:
        'Nahrajte druhú stranu. Cudzinec nasníma zadnú stranu pobytového preukazu alebo pas (ak v 1. kroku nahrával doklad Dočasné útočisko)',
    },
  },
  inkognitoRezim: {
    header: 'Prepáčte, pracujete v nepodporovanom režime prehliadača',
    text:
      'Aplikáciu používate v súkromnom režime, ktorý blokuje niektoré funkcionality. Skúste otvoriť aplikáciu v štandardnom režime pre ďalšie používanie, prosím. Ďakujeme.',
  },
};

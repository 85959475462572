export default {
  campaigns: 'Kampane',
  boxSupportContent: 'Detail prebiehajúcej kampane',
  boxSupportButton: 'Stiahnúť detail',
  ovb: {
    title: 'Motivácia pre Vás',
    description: '',
    banner: 'banner-motivacna-podpora',
    document: 'motivacna-podpora-2021.pdf',
  },
  extPob: {
    title: 'Motivácia pre Vás',
    description: '',
    banner: 'banner-externa-sutaz',
    document: 'ext-motivacna-podpora-2021.pdf',
  },
  zac: {
    title: 'Motivácia pre Vás',
    description: '',
    banner: 'banner-motivacia-zac',
    document: 'kampan_zac.pdf',
  },
  star1: {
    title: 'Motivácia pre Vás',
    description: '',
    banner: 'banner-motivacia-star1',
    document: 'kampan_star1.pdf',
  },
  star2: {
    title: 'Motivácia pre Vás',
    description: '',
    banner: 'banner-motivacia-star2',
    document: 'kampan_star2.pdf',
  },
};

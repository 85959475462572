const formats = {
  DEFAULT_DATE_FORMAT: 'DD.MM.YYYY, HH:mm',
  TIMESTAMP_WITH_SECONDS: 'DD.MM.YYYY, HH:mm:ss',
  DATE_ONLY: 'DD.MM.YYYY',
  OBDOBIE: 'YYYYMM',
  DATE_STANDART_ONLY: 'YYYY-MM-DD',
  NO_UTC_OFFSET: 'YYYY-MM-DD HH:mm',
  ISO_TIMESTAMP_NO_OFFSET: 'YYYY-MM-DDTHH:mm:ss',
  DAY_MONTH_ONLY_SHORT: 'D.M.',
  DATE_ONLY_SHORT: 'D.M.YYYY',
};

export default formats;

/* eslint-disable camelcase */
import { Nullable } from './index.d';

export interface AuthResponseReduxType {
  pouzivatelId: string;
  role: string;
  token: string;
  vztahId: string;
}

export interface BaseResponse {
  statusMessage?: string;
  result: boolean;
}

export interface Questions {
  cely_text?: string;
  kod: string;
  odpovede: Answers[];
  text: string;
  typ: string;
}

export interface BankoveSpojenie {
  banka_kod?: Nullable<string>;
  banka_nazov: Nullable<string>;
  bic?: Nullable<string>;
  datumDo: Nullable<string>;
  datumOd: Nullable<string>;
  iban: Nullable<string>;
  id?: Nullable<number>;
  jednorazove: boolean;
  mena: Nullable<string>;
  poznamka: Nullable<string>;
  priznak_rz?: Nullable<string>;
  stav_overenia: Nullable<string>;
  upresnenie_kod: Nullable<string>;
}

export interface TableObject {
  id: string;
  isBlocked?: boolean;
  isEditable?: boolean;
  isEditing?: boolean;
  moreInfo?: string;
  raw?: any;
  title?: string;
  type?: string;
  value?: Nullable<string>;
}

export interface Doklad {
  datum_vystavenia_dokladu: string;
  id?: string;
  kod_pokladnice?: string;
  overovaci_kod_podnikatela?: string;
  poradove_cislo: string;
  suma: number;
}

export interface Dokument {
  ciarovy_kod?: string;
  id: number;
  kluc?: string;
  komprimovany: boolean;
  nazov_dokumentu: string;
  obsah: string;
  poznamka?: string;
  size?: number;
  typ: string;
}

export interface Preukaz {
  datum_do: Nullable<string>;
  datum_od: Nullable<string>;
  kod_preukaz: Nullable<string>;
  pocet_kopii: number;
  typ_nazov: Nullable<string>;
}

export interface Employer {
  id: string;
  nazov: string;
  typ: string;
  ico: string;
  varSymbol: string;
  specSymbol: Nullable<string>;
  adresaSidla: Address;
}

export interface Address {
  id?: Nullable<string | number>;
  typ?: Nullable<string>;
  ulicaKod?: Nullable<string>;
  ulicaNazov?: Nullable<string>;
  cislo?: Nullable<string>;
  obecKod?: Nullable<string>;
  obecNazov?: Nullable<string>;
  psc?: Nullable<string>;
  lokalitaId?: Nullable<string>;
  lokalitaNazov?: Nullable<string>;
  statKod?: Nullable<string>;
  statNazov?: Nullable<string>;
}

export enum RelationType {
  mother = 'mother',
  father = 'father',
  other = 'other',
}

export enum PersonType {
  child = 'child',
  newBorn = 'newBorn',
  adult = 'adult',
}

export enum InsureeType {
  insuree = 'insuree',
  legalRepresentative = 'legalRepresentative',
}

export enum PurposeType {
  insurance = 'insurance',
  abroad = 'abroad',
  foreigner = 'foreigner',
}

export enum SignType {
  digital = 'digital',
  print = 'print',
  courier = 'courier',
  sms = 'sms',
}

export enum InsuranceCompany {
  union = 'union',
  vszp = 'vszp',
  dovera = 'dovera',
}

export enum InsurancePayer {
  employer = 'employer',
  selfEmployed = 'selfEmployed',
  selfPayer = 'selfPayer',
  state = 'state',
}

export enum SelfEmployed {
  zivnostnik = 'zivnostnik',
  samHospRoln = 'samHospRoln',
  sportovec = 'sportovec',
  umelec = 'umelec',
  lekar = 'lekar',
  pravnik = 'pravnik',
  obchodnyZastupca = 'obchodnyZastupca',
  financnyPoradca = 'financnyPoradca',
  szcoIne = 'szcoIne',
}

export enum SelfEmployedCode {
  zivnostnik = '01',
  samHospRoln = '02',
  sportovec = '05',
  umelec = '06',
  lekar = '11',
  pravnik = '12',
  obchodnyZastupca = '07',
  financnyPoradca = '13',
  szcoIne = '09',
}

export enum SelfEmployedCodeToType {
  '01' = 'zivnostnik',
  '02' = 'samHospRoln',
  '05' = 'sportovec',
  '06' = 'umelec',
  '11' = 'lekar',
  '12' = 'pravnik',
  '07' = 'obchodnyZastupca',
  '13' = 'financnyPoradca',
  '09' = 'szcoIne',
}

export enum StateAsPayer {
  student = 'student',
  homeWithChild = 'homeWithChild', // doma s dieťaťom'
  retiree = 'retiree', // dôchodca'
  unemployed = 'unemployed', // nezamestnaný
  helping = 'helping', // poberateľ dávky v hmotnej núdzi'
  other = 'other', // iné',
}

export enum StateAsPayerForStudent {
  studentDenny = 'studentDenny',
  studentDoktorant = 'studentDoktorant',
  studentExterny = 'studentExterny',
  studentZahranicny = 'studentZahranicny',
}

export enum StateAsPayerForhomeWithChild {
  domaDietaMaterska = 'domaDietaMaterska',
  domaDietaRodicovska = 'domaDietaRodicovska',
  domaDieta6rokov = 'domaDieta6rokov',
}

export enum StateAsPayerForretiree {
  dochodokStarobny = 'dochodokStarobny',
  dochodokPredcasny = 'dochodokPredcasny',
  dochodokInvalidny = 'dochodokInvalidny',
  dochodokVysluhovy = 'dochodokVysluhovy',
  dochodokVysluhovyInvalidny = 'dochodokVysluhovyInvalidny',
  dochodokZCudziny = 'dochodokZCudziny',
}

export enum StateOtherAsPayer {
  'B21' = 'B21', // sa starám o nevládnu alebo viac ako 80 ročnú osobu
  'B23' = 'B23', // som vo väzení
  'B24' = 'B24', // na dôchodku bez nároku na dôchodok
  'B25' = 'B25', // som azylant
  'B26' = 'B26', // vykonávam činnosť pre charitatívne spoločenstvo
  'B27' = 'B27', // som v zariadení sociálnych služieb celoročne
  'B28' = 'B28', // invalid bez nároku na invalidný dôchodok
  'B29' = 'B29', // poberám peňažný príspevok za opatrovanie
  'B30' = 'B30', // som manželka alebo manžel štátneho zamestnanca v cudzine
  'B31' = 'B31', // som osobný asistent zdravotne postihnutého občana
  'B32' = 'B32', // som cudzinec zaistený na území Slovenska
  'B38' = 'B38', // mám náhradu príjmu služobného platu policajta alebo vojaka
  'B39' = 'B39', // sa starám o blízku osobu, nemám nárok na dôchodok a nezarábam
}

export enum WhereToDelivery {
  permanentAddress = 'permanentAddress',
  temporaryAddress = 'temporaryAddress',
  postalAddress = 'postalAddress',
  branchOffice = 'branchOffice',
  legalAddress = 'legalAddress',
}

// export enum WhereToDelivery {
//   P = 0,
//   K1 = 3,
//   K2 = 1,
//   O = 4,
//   R = 2, // alebo 1
// }

// export enum WhereToDelivery {
//   P = 'P',
//   R = 'R',
//   K1 = 'K1',
//   k2 = 'k2',
//   O = 'O',
// }

export enum ScreenType {
  personal = 'personal',
  contact = 'contact',
}

export enum AppSectionType {
  homepage = 'homepage',
  benefits = 'benefits',
  registration = 'registration',
  contract = 'contract',
  extranet = 'extranet',
  comparison = 'comparison',
  info = 'info',
  doctors = 'doctors',
  branches = 'branches',
  education = 'education',
  application = 'application',
  myGroup = 'myGroup',
  messenger = 'messenger',
  production = 'production',
  payouts = 'payouts',
}

export enum SexType {
  female = 'Z',
  male = 'M',
}

export enum OcrStatus {
  success = 'success',
  failure = 'failure',
  skip = 'skip',
  inProcess = 'inProcess',
  copied = 'copied',
  photoTaken = 'photoTaken',
}

export enum CaptureMode {
  BaseCapture = 'BaseCapture',
  SingleCapture = 'SingleCapture',
  SingleView = 'SingleView',
  SingleCrop = 'SingleCrop',
}
export interface OcrConfig {
  startPage: number;
  images: any[];
  maxImagesCount: number;
  enableCamera: boolean;
  enableAutoCapture: boolean;
  enableAutoCrop: boolean;
  enableQualityAssessmentForOCR: boolean;
  localizedStrings: any;
  documentSize: string;
  aspectRatioMin: number;
  aspectRatioMax: number;
  licenseFilePath?: string;
  mode?: CaptureMode;
  viewConfiguration?: any;
}

export interface OcrResponse {
  name: string;
  giveNames: string;
  nationality: string;
  sex: string;
  number: string;
  issuedBy: string;
  dateOfBirth: string;
  personalNumber: string;
  dateOfExpiry: string;
  dateOfIssue: string;
  address: string[];
  surnameAtBirth: string;
  placeOfBirth: string;
  specialRemarks: string[];
  machineReadableZone: string[];
}

export interface OcrError {
  code: OcrErrorTypes;
  aux: {
    front: Nullable<string>;
    back: Nullable<string>;
  };
}

export enum OcrErrorTypes {
  MissingPicture = 'MissingPictureError',
  IncorrectRatio = 'IncorrectRatioError',
  OcrQuality = 'OcrQualityError',
}

export interface MetrikaGA {
  id: string;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: string;
}

export interface EventGA {
  category: AppSectionType;
  action: string;
  label?: string;
  value?: string;
}

export enum WizardOcrStep {
  step0 = 'step0',
  step1 = 'step1',
  contract = 'contract',
}

export interface MaintanaceDates {
  from: Date | string;
  to: Date | string;
}

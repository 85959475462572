import * as React from 'react';
import { AuthService } from '../services/AuthService';
import { UserManager, User } from 'oidc-client-dzp';

interface AuthContextType {
  getUserManager: () => UserManager;
  logOut: (onTimeout?: boolean) => Promise<void>;
  renewToken: () => Promise<User>;
}

const defaultAuthContext: AuthContextType = {
  // @ts-ignore
  getUserManager: () => null,
  logOut: async () => undefined,
  // @ts-ignore
  renewToken: async () => new User({}),
};

export const AuthContext = React.createContext(defaultAuthContext);

export function AuthProvider({ children }) {
  const authService = React.useMemo(() => new AuthService(), []);

  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
}

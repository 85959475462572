import moment from 'moment';

const priloha2Pdf = 'prilohac2_zoznam_obci.pdf';
const priloha3Pdf = 'prilohac3_zoznam_podriadenych_zamestnancov.pdf';
const priloha4Pdf = 'prilohac4_manual_pre_zamestnancov.pdf';
const year = moment().format('YYYY');

export default {
  title: 'Vzdelávanie',
  contract: {
    title: `Dohoda o vykonaní práce ${year} - prílohy`,
    list: [
      {
        title: `Príloha č. 2 k dohode o vykonaní práce ${year} – Zoznam obcí`,
        href: `${process.env.PUBLIC_URL}/assets/${priloha2Pdf}`,
      },
      {
        title:
          'Príloha č. 3 k dohode o vykonaní práce – Zoznam podriadených zamestnancov',
        href: `${process.env.PUBLIC_URL}/assets/${priloha3Pdf}`,
      },
      {
        title:
          'Príloha č.4 k dohode o vykonaní práce – Manuál pre zamestnancov vykonávajúcich získavanie poistencov',
        href: `${process.env.PUBLIC_URL}/assets/${priloha4Pdf}`,
      },
    ],
  },
  demo: {
    title: 'Demo',
    linkTitle: 'Demo aplikácie',
  },
  viaVideo: {
    title: 'Via video',
    href: 'https://youtu.be/giV93sQ5iYs',
  },
  magazine: {
    title: 'Časopis',
    linkTitle: 'Časopis Dôvera',
  },
  articles: {
    title: 'Aktuality z Dôvery',
    list: [
      {
        title: 'Zelené prihlášky meníme na stromy',
        href:
          'https://www.dovera.sk/aktuality/4663-zelene-prihlasky-menime-na-stromy',
      },
      {
        title: 'Ak preferujete počítač, je tu pre vás digitálna Dôvera',
        href:
          'https://www.dovera.sk/aktuality/4573-ak-preferujete-pocitac-je-tu-pre-vas-digitalna-dovera',
      },
      {
        title: 'Bojovníkom za zdravie darujeme 162-tisíc eur',
        href:
          'https://www.dovera.sk/aktuality/4667-bojovnikom-za-zdravie-darujeme-162-tisic-eur',
      },
    ],
  },
};

import { WizardOcrStep, InsureeType } from '../types/common.d';

export const STORE_OCR_DATA = 'STORE_OCR_DATA';
export const EMPTY_OCR_DATA = 'EMPTY_OCR_DATA';
export const SET_PERSON_FROM_OCR_DATA = 'SET_PERSON_FROM_OCR_DATA';
export const SET_PERSONAL_DATA_FROM_OCR = 'SET_PERSONAL_DATA_FROM_OCR';

export const RESET_NFINITY_OCR_TRANSACTION = 'RESET_NFINITY_OCR_TRANSACTION';

export const temporaryStoreOcrData = (
  step: WizardOcrStep,
  data,
  insureeType: InsureeType | undefined,
) => ({
  type: STORE_OCR_DATA,
  step,
  data,
  insureeType,
});

export const emptyOcrData = (
  step: WizardOcrStep,
  insureeType: InsureeType | undefined,
) => ({
  type: EMPTY_OCR_DATA,
  step,
  insureeType,
});

export const setOcrDataForPerson = (
  data,
  insureeType: InsureeType,
  step: WizardOcrStep,
) => ({
  type: SET_PERSON_FROM_OCR_DATA,
  step,
  insureeType,
  data,
});

export const setContractOcrDataForPerson = personalDataFromOcr => ({
  type: SET_PERSONAL_DATA_FROM_OCR,
  personalDataFromOcr,
});

export const resetNfinityOCR = () => ({
  type: RESET_NFINITY_OCR_TRANSACTION,
});

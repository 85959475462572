import routes from '../../routers/routes';

export const limitedAccessRoutes = [routes.contacts];

export const fullAccessRoutes = [
  routes.contacts,
  routes.viralnyMarketing,
  routes.extranet.prihlasky,
  routes.extranet.prehladProdukcie,
  routes.vzdelavanie,
];

export const makGroupAccessRoutes = [routes.extranet.podriadeni];

export const adminAccessRoutes = [
  routes.extranet.spravaDohod,
  routes.extranet.spravaDohodDetail,
];

export const vypPaskyAccessRoutes = [
  routes.extranet.vyplatnePasky,
  routes.extranet.dohody,
  routes.dohoda.formular,
  routes.campaing.extPob,
  routes.spravy.root,
  routes.spravy.detail,
];

export const defaultAccessRoutes = [
  routes.loggedOut,
  routes.offline,
  routes.benefity,
  routes.zlavoveBenefity,
  routes.callback,
  routes.cookies,
  routes.demo,
  routes.figma,
  routes.home,
  routes.infoVerzie,
  routes.pobocky,
  routes.prihlasenie,
  routes.profil,
];

// export const blockedAccessRoutes = [routes.dohoda.newEmployee];

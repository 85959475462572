import * as actionSets from './actionSets';
import { createUrl, createBodyLog } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';

const controller = 'CMS';

const contract = {
  getRVMContacts: relationID => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetRVMContacts`),
      body: createBodyLog({ relationID }),
      method: 'POST',
      actions: actionSets.GET_RVM_CONTACTS,
      authenticated: true,
    },
  }),
};

export default contract;

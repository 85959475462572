import { slovakStateCode } from '../constants/index';
import {
  StateAsPayerForretiree,
  StateAsPayerForStudent,
  InsurancePayer,
  StateAsPayer,
  StateAsPayerForhomeWithChild,
  StateOtherAsPayer,
  PersonType,
  InsuranceCompany,
} from '../types/common.d';

import defaultStore from '../store';

/**
 * A1 - PersonAdultChangingInsuranceCompanyFromVSZP
 */
export const AdultChangingInsuranceCompanyFromVSZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality === slovakStateCode &&
    person === PersonType.adult &&
    insuranceCompany === InsuranceCompany.vszp
  );
};

/**
 * A2 - PersonAdultChangingInsuranceCompanyFromUZP
 */
export const AdultChangingInsuranceCompanyFromUZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality === slovakStateCode &&
    person === PersonType.adult &&
    insuranceCompany === InsuranceCompany.union
  );
};

/**
 * A3 - AdultReturnWithinEU
 */
// TODO: nebudeme robit navrat z cudziny - nechceme typ prihlasky O
export const AdultReturnWithinEU = (): boolean => false;

/**
 * A4 - AdultReturnOutsideOfEU
 */
// TODO: nebudeme robit navrat z cudziny - nechceme typ prihlasky O
export const AdultReturnOutsideOfEU = (): boolean => false;

/**
 * A51 - NewBornChildsMomIsInDovera
 */
export const NewBornChildsMomIsInDovera = (): boolean => {
  const { person } = defaultStore.getState().wizard.step1;
  if (person !== PersonType.newBorn) return false;
  const { motherInsuranceCompany } = defaultStore.getState().wizard.step4;
  if (!motherInsuranceCompany) return false;
  return motherInsuranceCompany === InsuranceCompany.dovera;
};

/**
 * A52 - NewBornChildsMomIsInVSZP
 */
export const NewBornChildsMomIsInVSZP = (): boolean => {
  const { person } = defaultStore.getState().wizard.step1;
  if (person !== PersonType.newBorn) return false;
  const { motherInsuranceCompany } = defaultStore.getState().wizard.step4;
  if (!motherInsuranceCompany) return false;
  return motherInsuranceCompany === InsuranceCompany.vszp;
};

/**
 * A53 - NewBornChildsMomIsInUnion
 */
export const NewBornChildsMomIsInUnion = (): boolean => {
  const { person } = defaultStore.getState().wizard.step1;
  if (person !== PersonType.newBorn) return false;
  const { motherInsuranceCompany } = defaultStore.getState().wizard.step4;
  if (!motherInsuranceCompany) return false;
  return motherInsuranceCompany === InsuranceCompany.union;
};

/**
 * A6 - PersonChildChangingInsuranceCompanyFromVSZP
 */
export const PersonChildChangingInsuranceCompanyFromVSZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality === slovakStateCode &&
    person === PersonType.child &&
    insuranceCompany === InsuranceCompany.vszp
  );
};

/**
 * A7 - PersonChildChangingInsuranceCompanyFromUZP
 */
export const PersonChildChangingInsuranceCompanyFromUZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality === slovakStateCode &&
    person === PersonType.child &&
    insuranceCompany === InsuranceCompany.union
  );
};

/**
 * A8 - ChildReturnWithinEU
 */
// TODO: nebudeme robit navrat z cudziny - nechceme typ prihlasky O
export const ChildReturnWithinEU = (): boolean => false;

/**
 * A9 - ChildReturnOutsideOfEU
 */
// TODO: nebudeme robit navrat z cudziny - nechceme typ prihlasky O
export const ChildReturnOutsideOfEU = (): boolean => false;

/**
 * A10 - PersonAdultIsForeignerHasNoInsurance
 */
// TODO: nebudeme robit cudzinca bez poistenia - nechceme typ prihlasky N pre cudzinca
export const PersonAdultIsForeignerHasNoInsurance = (): boolean => false;

/**
 * A11 - PersonAdultIsForeignerChangingInsuranceCompanyFromVSZP
 */
export const PersonAdultIsForeignerChangingInsuranceCompanyFromVSZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality !== slovakStateCode &&
    person === PersonType.adult &&
    insuranceCompany === InsuranceCompany.vszp
  );
};
/**
 * A12 - PersonAdultIsForeignerChangingInsuranceCompanyFromUZP
 */
export const PersonAdultIsForeignerChangingInsuranceCompanyFromUZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality !== slovakStateCode &&
    person === PersonType.adult &&
    insuranceCompany === InsuranceCompany.union
  );
};
/**
 * A13/A17 - PersonNewBornIsForeigner
 */
export const PersonNewBornIsForeigner = (): boolean => {
  const { person } = defaultStore.getState().wizard.step1;
  // TODO: foreigner
  // return person === PersonType.newBorn && purpose === PurposeType.foreigner;
  return person === PersonType.newBorn;
};

export const isPersonNewBorn = (): boolean => {
  const { person } = defaultStore.getState().wizard.step1;
  // TODO: foreigner
  // return person === PersonType.newBorn && purpose === PurposeType.foreigner;
  return person === PersonType.newBorn;
};

export const isPersonChild = (): boolean => {
  const { person } = defaultStore.getState().wizard.step1;
  // TODO: foreigner
  // return person === PersonType.newBorn && purpose === PurposeType.foreigner;
  return person === PersonType.child;
};
/**
 * A14/A18 - PersonNewBornIsForeignerHasNoInsurance
 */
// TODO: nebudeme robit cudzinca bez poistenia - nechceme typ prihlasky N pre cudzinca
export const PersonNewBornIsForeignerHasNoInsurance = (): boolean => false;

/**
 * A15/A19 - PersonChildIsForeignerChangingInsuranceCompanyFromVSZP
 */
export const PersonChildIsForeignerChangingInsuranceCompanyFromVSZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality !== slovakStateCode &&
    person !== PersonType.adult &&
    insuranceCompany === InsuranceCompany.vszp
  );
};
/**
 * A16/A20 - PersonChildIsForeignerChangingInsuranceCompanyFromUZP
 */
export const PersonChildIsForeignerChangingInsuranceCompanyFromUZP = (): boolean => {
  const { person, insuranceCompany } = defaultStore.getState().wizard.step1;
  const {
    insuree: { nationality },
  } = defaultStore.getState().wizard.step2;

  return (
    nationality !== slovakStateCode &&
    person !== PersonType.adult &&
    insuranceCompany === InsuranceCompany.union
  );
};

/**
 * B1 - payer of insurance is employer
 */
export const PayerIsEmployer = (): boolean =>
  defaultStore.getState().wizard.step3.insurancePayerType ===
  InsurancePayer.employer;

/**
 * B2 - payer of insurance is self employed
 */
export const PayerIsSelfEmployed = (): boolean =>
  defaultStore.getState().wizard.step3.insurancePayerType ===
  InsurancePayer.selfEmployed;

/**
 * B3 - payer of insurance is state because client is home with child on maternity leave
 */
export const PayerIsStateDueHomeWithChildMaternityLeave = (): boolean => {
  const {
    insurancePayerType,
    state,
    homeWithChild,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.homeWithChild &&
    homeWithChild === StateAsPayerForhomeWithChild.domaDietaMaterska
  );
};

/**
 * B4 - payer of insurance is state because client is student of daily studying
 */
export const PayerIsStateDueStudenDaily = (): boolean => {
  const {
    insurancePayerType,
    state,
    student,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.student &&
    student === StateAsPayerForStudent.studentDenny
  );
};

/**
 * B5 - payer of insurance is state because client is student of doctorand studying
 */
export const PayerIsStateDueStudenDoktorand = (): boolean => {
  const {
    insurancePayerType,
    state,
    student,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.student &&
    student === StateAsPayerForStudent.studentDoktorant
  );
};

/**
 * B6 - payer of insurance is self-payer
 */
export const PayerIsSelfPayer = (): boolean =>
  defaultStore.getState().wizard.step3.insurancePayerType ===
  InsurancePayer.selfPayer;

/**
 * B7 - payer of insurance is state because client is unemployed
 */
export const PayerIsStateDueUnemploying = (): boolean => {
  const { insurancePayerType, state } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.unemployed
  );
};

/**
 * B8 - payer of insurance is state because client is retiree with old-age pension
 */
export const PayerIsStateDueRetireeOnOldAgePension = (): boolean => {
  const {
    insurancePayerType,
    state,
    retiree,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.retiree &&
    retiree === StateAsPayerForretiree.dochodokStarobny
  );
};

/**
 * B9 - payer of insurance is state because client is retiree with early pension
 */
export const PayerIsStateDueRetireeEarlyPension = (): boolean => {
  const {
    insurancePayerType,
    state,
    retiree,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.retiree &&
    retiree === StateAsPayerForretiree.dochodokPredcasny
  );
};

/**
 * B10 - payer of insurance is state because client is retiree with disability pension
 */
export const PayerIsStateDueRetireeDisabilityPension = (): boolean => {
  const {
    insurancePayerType,
    state,
    retiree,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.retiree &&
    retiree === StateAsPayerForretiree.dochodokInvalidny
  );
};

/**
 * B11 - payer of insurance is state because client is retiree with extended pension
 */
export const PayerIsStateDueRetireeExtendedPension = (): boolean => {
  const {
    insurancePayerType,
    state,
    retiree,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.retiree &&
    retiree === StateAsPayerForretiree.dochodokVysluhovy
  );
};

/**
 * B12 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOther = (): boolean => {
  const { insurancePayerType, state } = defaultStore.getState().wizard.step3;
  return (
    insurancePayerType === InsurancePayer.state && state === StateAsPayer.other
  );
};

/**
 * B14 - payer of insurance is state because client is student of foreign studying
 */
export const PayerIsStateDueStudenForeign = (): boolean => {
  const {
    insurancePayerType,
    state,
    student,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.student &&
    student === StateAsPayerForStudent.studentZahranicny
  );
};

/**
 * B15 - payer of insurance is state because client is retiree with invalidity service pension
 */
export const PayerIsStateDueRetireeInvalidityServicePension = (): boolean => {
  const {
    insurancePayerType,
    state,
    retiree,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.retiree &&
    retiree === StateAsPayerForretiree.dochodokVysluhovyInvalidny
  );
};

/**
 * B17 - payer of insurance is state because client is retiree with foreign pension
 */
export const PayerIsStateDueRetireeForeignPension = (): boolean => {
  const {
    insurancePayerType,
    state,
    retiree,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.retiree &&
    retiree === StateAsPayerForretiree.dochodokZCudziny
  );
};

/**
 * B19 - payer of insurance is state because client is home with child on parental leave
 */
export const PayerIsStateDueHomeWithChildParentalLeave = (): boolean => {
  const {
    insurancePayerType,
    state,
    homeWithChild,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.homeWithChild &&
    homeWithChild === StateAsPayerForhomeWithChild.domaDietaRodicovska
  );
};

/**
 * B20 - payer of insurance is state because client is home with child up to 6 years
 */
export const PayerIsStateDueHomeWithChildUpTo6 = (): boolean => {
  const {
    insurancePayerType,
    state,
    homeWithChild,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.homeWithChild &&
    homeWithChild === StateAsPayerForhomeWithChild.domaDieta6rokov
  );
};

/**
 * B21 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB21 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B21
  );
};

/**
 * B22 - payer of insurance is state because material need
 */
export const PayerIsStateDueMateriaNeed = (): boolean => {
  const { insurancePayerType, state } = defaultStore.getState().wizard.step3;
  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.helping
  );
};

/**
 * B23 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB23 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B23
  );
};

/**
 * B24 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB24 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B24
  );
};

/**
 * B25 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB25 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B25
  );
};

/**
 * B26 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB26 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B26
  );
};

/**
 * B27 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB27 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B27
  );
};

/**
 * B28 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB28 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B28
  );
};

/**
 * B29 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB29 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B29
  );
};

/**
 * B30 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB30 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B30
  );
};

/**
 * B31 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB31 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B31
  );
};

/**
 * B32 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB32 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B32
  );
};

/**
 * B34 - payer of insurance is state because client is student of external studying
 */
export const PayerIsStateDueStudenExternal = (): boolean => {
  const {
    insurancePayerType,
    state,
    student,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.student &&
    student === StateAsPayerForStudent.studentExterny
  );
};

/**
 * B38 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB38 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B38
  );
};

/**
 * B39 - payer of insurance is state because other purpose
 */
export const PayerIsStateDueOtherB39 = (): boolean => {
  const {
    insurancePayerType,
    state,
    stateOther,
  } = defaultStore.getState().wizard.step3;

  return (
    insurancePayerType === InsurancePayer.state &&
    state === StateAsPayer.other &&
    stateOther === StateOtherAsPayer.B39
  );
};

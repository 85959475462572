import common from './_common';
import cookies from './cookies';
import applicationForm from './applicationForm';
import benefitDetail from './benefitDetail';
import benefits from './benefits';
import campaign from './campaign';
import drawer from './drawer';
import education from './education';
import emailOffer from './emailOffer';
import extranet from './extranet';
import figma from './figma';
import homepage from './homepage';
import infoDrawer from './infoDrawer';
import insuranceComparison from './insuranceComparison';
import links from './links';
import metric from './metric';
import ocr from './ocr';
import pobocky from './pobocky';
import validation from './validation';
import vzdelavanie from './vzdelavanie';
import zmluvniLekari from './zmluvniLekari';
import maintanance from './maintanance';
import contractForm from './contractForm';
import profile from './profile';
import spravy from './spravy';
import forgotPassword from './forgotPassword';
import loginPage from './loginPage';

export default {
  ...common,
  cookies,
  applicationForm,
  benefitDetail,
  benefits,
  campaign,
  drawer,
  education,
  emailOffer,
  extranet,
  figma,
  homepage,
  infoDrawer,
  insuranceComparison,
  links,
  metric,
  ocr,
  pobocky,
  profile,
  spravy,
  validation,
  vzdelavanie,
  zmluvniLekari,
  maintanance,
  contractForm,
  loginPage,
  forgotPassword,
};

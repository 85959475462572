export const RESET_GRID_CARD_CREDENTIALS = 'RESET_GRID_CARD_CREDENTIALS';

export const INIT_AUTH_STATE = 'INIT_AUTH_STATE';

export const LOG_OUT = 'LOG_OUT';

export const SIGN_IN_REDIRECT = 'SIGN_IN_REDIRECT';

export const SET_DEFAULT_LOGIN_STATE = 'SET_DEFAULT_LOGIN_STATE';

export const SET_DEFAULT_PASSWORD_STATE = 'SET_DEFAULT_PASSWORD_STATE';

export const SET_DEFAULT_IBAN_STATE = 'SET_DEFAULT_IBAN_STATE';

export const SIMULATE_SUCCESSFUL_LOGIN = 'SIMULATE_SUCCESSFUL_LOGIN';

export const SIMULATE_SUCCESSFUL_PASSWORD = 'SIMULATE_SUCCESSFUL_PASSWORD';

export const SIMULATE_SUCCESSFUL_IBAN = 'SIMULATE_SUCCESSFUL_IBAN';

interface LoginResponse {
  success: boolean;
  message: string;
  username: string;
}

export const resetGridCardCredentials = () => ({
  type: RESET_GRID_CARD_CREDENTIALS,
});

export const initAuthState = () => ({
  type: INIT_AUTH_STATE,
});

export const simulateSuccessfulLogin = (username): Promise<LoginResponse> =>
  new Promise((resolve: any) => {
    const response = {
      success: false,
      message: 'Pouzivatelske meno uspesne zmenene',
      username,
    };

    resolve(response);
  });

export const simulateSuccessfulPassword = (oldPassword, newPassword) => ({
  type: SIMULATE_SUCCESSFUL_PASSWORD,
  response: {
    success: true,
    message: 'Pouzivatelske heslo uspesne zmenene',
  },
});

export const simulateSuccessfulIban = iban => ({
  type: SIMULATE_SUCCESSFUL_IBAN,
  response: {
    success: true,
    message: 'IBAN uspesne zmeneny',
  },
});

export const resetLoginState = () => ({
  type: SET_DEFAULT_LOGIN_STATE,
});

export const resetPasswordState = () => ({
  type: SET_DEFAULT_PASSWORD_STATE,
});

export const resetIbanState = () => ({
  type: SET_DEFAULT_IBAN_STATE,
});

export const resetToInitialState = () => ({
  type: LOG_OUT,
});

export function signinRedirect(response) {
  return {
    type: SIGN_IN_REDIRECT,
    token: response.access_token,
    pouzivatelId: response.profile.dzp_user_id,
    vztahId: response.profile.dzp_broker_id,
    role: response.profile.dzp_initial_role,
  };
}

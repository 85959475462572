import auth from './auth';
import cms from './cms';
import version from './version';
import registration from './registration';
import contract from './contract';
import contractsManagement from './contractsManagement';
import validation from './validation';
import log from './log';
import address from './address';
import autocomplete from './autocomplete';
import ocr from './ocr';
import extranet from './extranet';
import messenger from './messenger';
import app from './app';
import benefits from './benefits';

const api = {
  auth,
  app,
  cms,
  version,
  registration,
  contract,
  contractsManagement,
  validation,
  log,
  address,
  autocomplete,
  ocr,
  extranet,
  messenger,
  benefits,
};

export default api;

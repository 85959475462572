import { GET_NEW_THREAD } from '../api/actionSets';
import {
  LOG_SET_SECTION,
  LOG_SET_IP,
  LOG_USED_TREAD,
  STORE_THREAD,
} from '../actions/log-actions';
import { LogReducer } from '../types/reducers.d';

const defaultState: LogReducer = {
  thread: -1,
  threadLoading: false,
  threadError: null,
  threadUsed: false,
  section: null,
  ip: null,
};

export default (state: LogReducer = defaultState, action) => {
  switch (action.type) {
    case LOG_SET_SECTION:
      return {
        ...state,
        ...action,
      };
    case LOG_SET_IP:
      return {
        ...state,
        ip: action.ip,
      };
    case LOG_USED_TREAD:
      return {
        ...state,
        threadUsed: true,
      };
    case GET_NEW_THREAD.LOADING:
      return {
        ...state,
        threadLoading: true,
        threadUsed: false,
        thread: -1,
        threadError: null,
      };
    case GET_NEW_THREAD.SUCCESS:
      return {
        ...state,
        threadLoading: false,
        threadUsed: false,
        thread: action.response.threadID,
        threadError: null,
      };
    case GET_NEW_THREAD.ERROR:
      return {
        ...state,
        threadLoading: false,
        thread: -1,
        threadUsed: false,
        threadError: action.error,
      };
    case STORE_THREAD:
      return {
        ...state,
        threadLoading: false,
        threadUsed: false,
        thread: action.threadId,
        threadError: null,
      };
    default:
      return state;
  }
};

import illustrations from '../illustrations';

/**
 *
 * DEPRECATED
 *
 */
export default {
  tableColumns: {
    description: 'Benefity',
    dovera: 'DÔVERA',
    union: 'UNION',
    vszp: 'VŠZP',
  },
  tableData: [
    {
      order: 0,
      illustration: illustrations.zdraveZuby,
      title: 'Príspevok 100 € ročne na zuby',
      description: 'preplatenie doplatkov za ošetrenie u zmluvného zubára',
      dovera: 'áno',
      union: 'iné podmienky',
      vszp: 'iné podmienky',
    },
    {
      order: 0,
      illustration: illustrations.vratenieDoplatkovZaLieky,
      title: 'Vrátime vám doplatky za lieky až 300 €',
      description:
        'preplatenie doplatkov za lieky a dietetické potraviny na predpis do výšky 300€',
      dovera: 'áno',
      union: 'iné podmienky',
      vszp: 'iné podmienky',
    },
    {
      order: 0,
      illustration: illustrations.zmluvySLekarmi,
      title: 'Lekári všade na Slovensku',
      description: 'preplatíme ošetrenie aj u nezmluvných lekárov',
      dovera: 'áno',
      union: 'áno',
      vszp: 'áno',
    },
    {
      order: 0,
      illustration: illustrations.elektronickaPobocka,
      title: 'Elektronická pobočka',
      description:
        'online komunikácia so zdravotnou poisťovňou bez potreby navštíviť kamennú pobočku',
      dovera: 'áno',
      union: 'menej funkcionalit',
      vszp: 'menej funkcionalit',
    },
    {
      order: 0,
      illustration: illustrations.interakcieLiekov,
      title: 'Bezpečné lieky',
      description: 'online informácie o nežiaducich kombináciách liekov',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.sikovnaAplikacia,
      title: 'Mobilná aplikácia',
      description: 'užitočné funkcie a informácie v mobile',
      dovera: 'áno',
      union: 'áno',
      vszp: 'áno',
    },
    {
      order: 0,
      illustration: illustrations.nadstandardneIzby,
      title: 'Nadštandardné izby za polovicu',
      description: 'zľava vo výške 50 %',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.doveraPomahaDiabetikom,
      title: 'DôveraPomáha diabetikom',
      description: 'poradenstvo a edukácia pre diabetikov',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.dialinka,
      title: 'Linka poradenstva pre zdravie',
      description: 'telefonické poradenstvo pre diabetikov',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.doveraGenio,
      title: 'Samodiagnostika od Infermedica',
      description: 'nástroj na samodiagnostiku',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.objednajteSaTelefonicky,
      title: 'Objednávkový systém',
      description: 'bezplatné objednanie na vyšetrenie k lekárovi',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.bojovniciZaZdravie,
      title: 'Bojovníci za zdravie',
      description: 'projekt Bojovníci za zdravie',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.sprievodcaRodica,
      title: 'Sprievodca rodiča',
      description: 'praktická služba pre rodičov',
      dovera: 'áno',
      union: 'nie elektronicky',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.priplacanieZaOckovanie,
      title: 'OČKOVANIE PROTI HEPATITÍDE A',
      description:
        '100 % úhrada vakcíny – pre poistencov do dovŕšenia 18. roku veku',
      dovera: 'áno',
      union: '50 %',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.priplacanieZaOckovanie,
      title: 'OČKOVANIE PROTI HEPATITÍDE A a B',
      description:
        '50 % úhrada z ceny všetkých 3dávok vakcíny – pre poistencov po dovŕšení 16. roku veku',
      dovera: 'áno',
      union: 'áno',
      vszp: 'áno',
    },
    {
      order: 0,
      illustration: illustrations.priplacanieZaOckovanie,
      title: 'OČKOVANIE PROTI CHRÍPKE',
      description:
        '100 % úhrada vakcíny – zdarma pre všetkých našich poistencov bez ohľadu na vek',
      dovera: 'áno',
      union: 'áno',
      vszp: 'áno',
    },
    {
      order: 0,
      illustration: illustrations.priplacanieZaOckovanie,
      title: 'OČKOVANIE PROTI PNEUMOKOKOM',
      description: 'preplatenie doplatkov za očkovaciu látku proti pneumokokom',
      dovera: 'áno',
      union: 'áno',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.priplacanieZaOckovanie,
      title: 'OČKOVANIE PROTI KLIEŠŤOVEJ ENCEFALITÍDE',
      description:
        '50 % úhrada z ceny všetkých 3dávok vakcíny – pre poistencov po dovŕšení 1. roku veku',
      dovera: 'áno',
      union: 'áno',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.priplacanieZaOckovanie,
      title: 'OČKOVANIE PROTI VÍRUSU HPV',
      description:
        'príspevok vo výške 30, 50, 70 % na vakcíny proti vírusu HPV – pre poistencov po dovŕšeni 13. roku veku do dovŕšenia 18. roku veku',
      dovera: 'áno',
      union: '50 %',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.mamografickeVysetrenie,
      title: 'Mamografia do 10 dní',
      description:
        'mamografické vyšetrenie prsníkov už do 10 dní od objednania pre poistenky od 40 do 69 rokov',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.dodatocnaZlavaNaPortaloch,
      title: 'Extra zľavy na zľavových portáloch',
      description: 'zľava ďalších 5 % na všetky ponuky',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.obdarujmeNovorodenca,
      title: 'BabyBox v hodnote 50 €',
      description: 'pre rodičov a deti do 1.roku veku',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.medzinarodnePoistenie,
      title: 'Medzinárodné zdravotné poistenie so zľavou',
      description: 'až do výšky 30 %',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.zlavaVKupeloch,
      title: 'Zľava na kúpele',
      description: 'až do výšky 13 %',
      dovera: 'áno',
      union: 'áno',
      vszp: 'áno',
    },
    {
      order: 0,
      illustration: illustrations.benefityPreDarcovKrvi,
      title: 'Výhody pre darcov krvi',
      description: 'zľava do kúpeľov Piešťany a Smrdáky až do výšky 25 %',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
    {
      order: 0,
      illustration: illustrations.benefityPreDarcovKrvi,
      title: 'Výhody pre darcov krvi',
      description: 'vitamíny na obnovu krvi zdarma pre darcov krvi',
      dovera: 'áno',
      union: '—',
      vszp: '—',
    },
  ],
};
